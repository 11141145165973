import React from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BACKEND_URL} from "../../config/const.js";
const RichTextEditor = ({ initialData, onChange }) => {
  return (
    <div className="App">
      <CKEditor
        editor={ClassicEditor}
        data={initialData}
        config={{
          ckfinder: {
            uploadUrl: `${BACKEND_URL}upload`, 
          },
          toolbar: {
            items: [
              'heading',
              '|',
              'bold',
              'italic',
              'underline',
              'strikethrough',
              '|',
              'alignment', // Ajout de l'alignement
              'alignLeft',
              'alignCenter',
              'alignRight',
              'alignJustify',
              '|',
              'fontSize',
              'fontFamily',
              'fontColor',
              'fontBackgroundColor',
              '|',
              'bulletedList',
              'numberedList',
              'indent',
              'outdent',
              '|',
              'link',
              'imageUpload',
              'blockQuote',
              'insertTable',
              '|',
              'undo',
              'redo',
              '|',
              'mathType',
              'chemType',
            ],
            shouldNotGroupWhenFull: true
          },
          alignment: {
            options: ['left', 'center', 'right', 'justify']
          },
      
          fontSize: {
            options: [
              9,
              11,
              13,
              'default',
              17,
              19,
              21
            ]
          },
          fontFamily: {
            options: [
              'default',
              'Arial, Helvetica, sans-serif',
              'Courier New, Courier, monospace',
              'Georgia, serif',
              'Times New Roman, Times, serif'
            ]
          }
        }}
        onChange={(event, editor) => {
          const data = editor.getData();
          if (onChange) {
            onChange(data);
          }
        }}
      />
    </div>
  );
};

export default RichTextEditor;
