import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { BasicTable } from './datatableCom';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import { BACKEND_URL } from "../../../config/const.js";
import axios from 'axios';

const ListPreparation = () => {
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState('');
    const [clientId, setClientId] = useState(null);
    const [sterileData, setSterileData] = useState([]);
    const [nonSterileData, setNonSterileData] = useState([]);
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };
    // Récupération des informations de l'utilisateur connecté
    useEffect(() => {
          const client = JSON.parse(localStorage.getItem("currentOrg"));
        if (client) {
            setClientId(client);
        }
    }, []);
    // Récupération des templates lorsque clientId est défini
    useEffect(() => {
        if (clientId) {
            fetchSterileData();
            fetchNonSterileData();
        }
    }, [clientId]);
    const fetchNonSterileData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}api/formulas/${clientId}/2`, config);
            console.log('template:', response);
       
        
                if (response && response.length > 0) {  // Assurez-vous de vérifier response.data si la réponse est dans cette structure.
                    const nonsterileData = response.map(item => ({
                        id:item.id,
                        srNo: item.lotNumber,
                        title: item.productName,
                        createdBy: item.approvedBy && Array.isArray(item.approvedBy) && item.approvedBy.length > 0
                        ? item.approvedBy[0].username: "Unknown",
                        checkedBy:item.checkedBy && Array.isArray(item.checkedBy) && item.checkedBy.length > 0
                        ? item.checkedBy[0].username : "Unknown", 
                        preparedBy:item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                        ? item.preparedBy[0].username : "Unknown", 
                        createDate: item.effectiveDate,
                        priority: item.status
                    }));
                    setNonSterileData(nonsterileData);
                }
            }  catch (error) {
            console.error('Error fetching template:', error);
        }
    };
    const fetchSterileData = async () => {
        try {
            const response = await axios.get(`${BACKEND_URL}api/formulas/${clientId}/1`, config);
            console.log('template:', response);
    
            if (response && response.length > 0) {  // Assurez-vous de vérifier response.data si la réponse est dans cette structure.
                const sterileData = response.map(item => ({
                    id: item.id,
                    srNo: item.lotNumber,
                    title: item.productName,
                    createdBy: item.approvedBy && Array.isArray(item.approvedBy) && item.approvedBy.length > 0
                    ? item.approvedBy[0].username: "Unknown",
                    checkedBy:item.checkedBy && Array.isArray(item.checkedBy) && item.checkedBy.length > 0
                    ? item.checkedBy[0].username : "Unknown", 
                    preparedBy:item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                    ? item.preparedBy[0].username : "Unknown", 
                    createDate: item.effectiveDate,
                    priority: item.status
                }));
                setSterileData(sterileData);
            }
        } catch (error) {
            console.error('Error fetching template:', error);
        }
    };
    

  
    
    console.log("sterile:",sterileData );
    console.log("sterile:",nonSterileData );
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Preparation sheet" breadcrumbItem="List of Preparation" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button
                                                        color="primary"
                                                        className="add-btn"
                                                        tag={Link}
                                                        to="/create-sheet"
                                                        id="create-btn"
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Create New Preparation sheet
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Col lg={12}>
                                            <Nav tabs className="nav-tabs-custom nav-justified">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({  active: customActiveTab === "1",  })}
                                                        onClick={() => {
                                                            toggleCustom("1");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">
                                                            Preparation Sterile
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: customActiveTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleCustom("2");
                                                        }}
                                                    >
                                                        <span className="d-sm-block">
                                                            Preparation Non-Sterile
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>

                                            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    <Row>
                                                        <Col className="col-sm">
                                                            <div className="d-flex justify-content-sm-end">
                                                                <div className="search-box">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control search"
                                                                        placeholder="Search..."
                                                                        value={searchTerm}
                                                                        onChange={e => setSearchTerm(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                            <BasicTable searchTerm={searchTerm} initialData={sterileData} />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Row>
                                                        <Col className="col-sm">
                                                            <div className="d-flex justify-content-sm-end">
                                                                <div className="search-box">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control search"
                                                                        placeholder="Search..."
                                                                        value={searchTerm}
                                                                        onChange={e => setSearchTerm(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                            <BasicTable searchTerm={searchTerm} initialData={nonSterileData} />
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListPreparation;
