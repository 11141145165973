const SidebarData = [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Master Formula",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "List of Master Formula", link: "/List-formula-template" },
            { sublabel: "New Master Formula", link: "/create-template" },
        ],
    },
    {
        label: "Preparation Sheet",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            { sublabel: "List of Preparation ", link: "/List-formula-sheet" },
            { sublabel: "Select New Preparation", link: "/create-sheet" },
        ],
    },
    {
        label: "Verification & Traceability",
        icon: "mdi mdi-format-page-break",
        url: "/dashboard",
        issubMenubadge: true,
      
       
  
    },
    // {
    //     label: "Pages",
    //     isMainMenu: true,
    // },
    {
        label: " Administration",
        icon: "mdi mdi-account-group-outline",
        subItem: [
            { sublabel: " Group Management", link: "/groups-management" },
            { sublabel: "User Management", link: "/users-management" },
            // { sublabel: "Role Management", link: "/dashboard" },
           
        ],
    },
    {
        label: " Reference Management ",
        icon: "mdi mdi-arrange-send-to-back",
        subItem: [
            { sublabel: " Ingredient Managementt", link: "/ingredient-management" },
            { sublabel: "Unit Management", link: "/unit-management" },
            { sublabel: "Supplier Management", link: "/supplier-management" },
            { sublabel: "Equipment/materials", link: "/equipement-management" },
            { sublabel: "Compound Product", link: "/product-management" },
            { sublabel: "Pharmaceutical Management", link: "/pharmaceutical-management" },
           
           
        ],
    },

  

   
]
export default SidebarData;