import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../table.css'
 // Assurez-vous de bien inclure votre fichier CSS
 import { useTranslation } from 'react-i18next';
 import { useNavigate } from 'react-router-dom';
import DeleteDialog from './DeleteDialog'; // Import the dialog component
import RestoreUserDialog from './RestoreUser';

const BasicTable = ({ searchTerm,initialData,fetchUserProfile,source }) => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isDialogRestoreOpen, setIsDialogRestoreOpen] = useState(false);

    const [userId, setUserId] = useState(null);
    const {t} = useTranslation();
    const paginationOptions = {
        rowsPerPageText: t('rowsPerPage'),
        rangeSeparatorText: t('of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: t('all'),
    };
    const goToUpdateUser = (userId) => {
        console.log(userId)
        localStorage.setItem('source',source)
          navigate(`/update-user/${userId}`);
        
      };

      const handleOpenDialog = (id) => {
        setUserId(id); // Pass user ID to dialog
        setIsDialogOpen(true);
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setUserId(null); // Reset userId after closing dialog
      };
      const handleOpenRestore = (id) => {
        setUserId(id); // Pass user ID to dialog
        setIsDialogRestoreOpen(true);
      };
    
      const handleCloseRestore = () => {
        setIsDialogRestoreOpen(false);
        setUserId(null); // Reset userId after closing dialog
      };
    const columns = [
    
        {
            name: <span className='font-weight-bold fs-15'>ID #</span>,
            selector: row => row.idUser,
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.idUser - b.idUser, // Example for numeric sorting

        },
        {
            name: <span className='font-weight-bold fs-14'>{t('username')}</span>,
            selector: row => (<span className='fontTable' >{row.name}</span>),
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.name.localeCompare(b.name), 

        },
        {
            name: <span className='font-weight-bold fs-14'>{t('firstName')}</span>,
            selector: row => (<span className='fontTable'>{row.firstName}</span>),
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.firstName.localeCompare(b.firstName), 

        },
        {
            name: <span className='font-weight-bold fs-15'>{t('lastName')}</span>,
            selector: row => (<span className='fontTable'>{row.lastName}</span>),
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.lastName.localeCompare(b.lastName),
        },
        {
            name: <span className='font-weight-bold fs-15'>{t('status')}</span>,
            sortable: true,
            selector: (cell) => {
                switch (cell.status) {
                    case "disabled":
                        return <span className="badge" style={{ backgroundColor: 'orange' }}> {t(cell.status)} </span>;
                    case "created":
                        return <span className="badge bg-info"> {t(cell.status)} </span>;
                    case "enabled":
                        return <span className="badge bg-success"> {t(cell.status)} </span>;
                    default:
                        return <span className="badge bg-danger"> {t(cell.status)} </span>;
                }
            },
            className: 'table-header',
            sortFunction: (a, b) => a.status.localeCompare(b.status),

        },
        {
            name: <span className='font-weight-bold fs-13'>{t('action')}</span>,
            sortable: true,
            cell: row => (
                <div className="d-flex gap-2">
                    <div className="edit">
                        <button className="btn btn-outline-info waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#showModal" onClick={()=>goToUpdateUser(row.idUser)} title={t('update')}>
                            <i className="ri-pencil-fill align-bottom  "></i>
                        </button>
                    </div>
                    {
                        row.status==="deleted"? (
                            <div className="remove">
                            <button className="btn btn-outline-success waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#deleteModal"  onClick={() => handleOpenRestore(row.idUser)} title={t('restore')}>
                                <i className="fas fa-undo align-bottom  "></i>
                            </button>
                        </div>
                        ):(  <div className="remove">
                            <button className="btn btn-outline-danger waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#deleteRecordModal"  onClick={() => handleOpenDialog(row.idUser)}title={t('delete')}>
                                <i className="ri-delete-bin-fill align-bottom  "></i>
                            </button>
                        </div>)
                    }
                  
                      {/* Delete Dialog - controlled via state */}
                    {isDialogOpen && (
                        <DeleteDialog
                        isOpen={isDialogOpen}
                        fetchUserProfile={fetchUserProfile}
                        handleClose={handleCloseDialog}
                        userId={userId} // Pass userId to the dialog
                        />
                    )}
                        {isDialogRestoreOpen && (
                        <RestoreUserDialog
                        isOpen={isDialogRestoreOpen}
                        fetchUserProfile={fetchUserProfile}
                        handleClose={handleCloseRestore}
                        userId={userId} // Pass userId to the dialog
                        />
                    )}
                </div>
            ),
            className: 'table-header'
        }
    ];

 

    const [filteredData, setFilteredData] = useState([]);
  
    useEffect(() => {
       
            setFilteredData(
                initialData.filter(item =>
                    item.idUser.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.status.toLowerCase().includes(searchTerm.toLowerCase())
                )
            );
       
      
    }, [searchTerm]);
console.log(filteredData)
    return (
        <DataTable
            columns={columns}
            data={filteredData.length>1?filteredData:initialData}
            pagination
            paginationPerPage={50} // Set default rows per page to 50
            paginationRowsPerPageOptions={[10, 25, 50, 100, 300]} // Provide options for rows per page
            paginationComponentOptions={paginationOptions} // Custom pagination options
            noDataComponent={t('noDataMessage')} // Custom translation key

        />
    );
};

export { BasicTable };
