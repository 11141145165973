import React from 'react';
import './print-styles.css';

const PrintComponent = ({ templateDetail, ingredients, equipmentRows, formData, logo, logobce,user, others }) => {
  return (
    <div className="print-container">
      {/* En-tête avec logos */}
      <div className="div-container">
        <div className="div-left">
          <img src={logobce} alt="BCE Logo" style={{ maxWidth: '100px' }} />
        </div>
        <div className="div-center">
          <h4 style={{ textAlign: 'center' }}>Compounded Sterile Preparation Protocol</h4>
          <p style={{ textAlign: 'center' }}>Number: {templateDetail?.protocolNumber || ''}  <span style={{marginLeft:"12px"}} >Version: {templateDetail?.version || ''}</span></p>
          <h4 style={{ textAlign: 'center', margin: '7px 0' }}>{templateDetail?.productName|| '------------------'}</h4>
        </div>
        <div className="div-right">
          <img src={logo} alt="Organization Logo" style={{ maxWidth: '100px' }} />
        </div>
      </div>

      {/* Informations du produit */}
      <div className="product-info">
     
    
        <div className='div-container'>
            <p className='div-sec'><strong>Route of administration:</strong> {templateDetail?.routeOfAdministration|| '------------------'}</p>
            <p  className='div-sec'><strong>Pharmaceutical form:</strong> {templateDetail?.pharmaForm|| '------------------'}</p>
        </div>
        <div className='div-container'>
        <p className='div-sec'><strong>Effective Date:</strong> {formData?.effectiveDate ? 
    new Date(formData.effectiveDate).toLocaleDateString('en-GB', {   day: '2-digit',   month: '2-digit',   year: 'numeric',   }) : ''}</p>
        <p className='div-sec'><strong>Created by:</strong> {user?.firstname + " " + user.lastname || '-------------'}</p>
        </div>
        <div className='div-container'>
        <p className='div-sec'><strong>Compounded by:</strong> ---------------------</p>
        <p className='div-sec'><strong>Checked by:</strong> ----------------------</p>
       
        </div>

      </div>

      {/* Table des ingrédients */}
      <div className="ingredients-section additional-section">
      <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Formula</h3>
      <div className='div-container text-center'>
            <p className='div-sec ml-10' ><strong>Formula Batch Size:</strong> {templateDetail?.formulaBatchSize} {templateDetail?.formulaBtachSizeUnit}</p>
            <p  className='div-sec'><strong>Desired Batch Size:</strong> {templateDetail?.desiredBatchSize} {templateDetail?.desiredBtachSizeUnit}</p>
        </div>
        <table className="print-table">
          <thead>
            <tr>
              <th>Ingredients</th>
              <th>Manufacturer</th>
              <th>Lot Number</th>
              <th>Exp Date</th>
              <th>QS</th>
              <th>Quantity</th>
              <th>Units</th>
              <th>Quantity Required</th>
            </tr>
          </thead>
          <tbody>
            {ingredients?.map((ing, index) => (
              <tr key={index}>
                <td>{ing.name}</td>
                <td>{ing.manufacture}</td>
                <td>{ing.lotNumber}</td>
                <td>{ing.expDate}</td>
                <td>{ing.qs ? '✓' : ''}</td>
                <td>{ing.quantity}</td>
                <td>{ing.unit}</td>
                <td>{ing.qteRequired}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Table des équipements */}
      <div className="equipment-section additional-section">
      <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Required Equipment/Materials</h3>
        <table className="print-table">
          <thead>
            <tr>
              <th>Equipment/Materials</th>
              <th>Quantity</th>
            </tr>
          </thead>
          <tbody>
            {equipmentRows?.map((equip, index) => (
              <tr key={index}>
                <td>{equip.name}</td>
                <td>{equip.quantity}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      {/* Sections additionnelles */}
      {templateDetail?.ingredientsInfo && (
        <div className="additional-section" >
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Additional information on ingredients</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.ingredientsInfo }} />
          </div>
        </div>
      )}

      {/* Calculs et Mesures */}
      {templateDetail?.calculations && (
        <div className="additional-section">
          <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Calculations and Measurements</h3>
          <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.calculations }} />
          </div>
        </div>
      )}

      {/* Méthode de préparation */}
      {templateDetail?.compoundingMethod && (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Compounding Method</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.compoundingMethod }} />
          </div>
        </div>
      )}

      {/* Contrôle de qualité */}
      {templateDetail?.qualityControl && (
        <div className="additional-section">
          <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4'>Quality Control</h3>
          <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.qualityControl }} />
          </div>
        </div>
      )}

      {/* Emballage */}
      {templateDetail?.packaging && (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Packaging</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.packaging }} />
          </div>
        </div>
      )}

      {/* Stabilité et stockage */}
      {templateDetail?.stabilityAndStorage && (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Stability and Storage</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.stabilityAndStorage }} />
          </div>
        </div>
      )}
          {/* Stabilité et stockage */}
          {templateDetail?.labelling && (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Labelling</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.labelling }} />
          </div>
        </div>
      )}


      {/* Formation */}
      {templateDetail?.training && (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >Training</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.training }} />
          </div>
        </div>
      )}

      {/* Références consultées */}
      {templateDetail?.refs && (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >References Consulted</h3>
           <div className='p-4'>
          <div dangerouslySetInnerHTML={{ __html: templateDetail.refs }} />
          </div>
        </div>
      )}
        {/*Others*/}
        {others&&others.map((oth, index) => (
        <div className="additional-section">
           <h3 className='bg-sec text-lg font-semibold bg-blue-600 text-white p-2 mb-4' >{oth.title}</h3>
           <div className='p-4' style={{ color: 'black' }}>
          <div dangerouslySetInnerHTML={{ __html: oth.description }} />
          </div>
        </div>
     ) )}
    </div>
  );
};

export default PrintComponent;

