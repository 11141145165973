import React, { useState,useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row,} from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import { BasicTable } from './datatableCom.js';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import axios from "axios";
import { BACKEND_URL , BACKEND_Externe_URL} from "../../../config/const.js";
import { useTranslation } from 'react-i18next';

const ListGroup = () => {
   
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState('');
    const [groups,setGroups] = useState([]);
    const [admin,setAdmin] = useState(false);
    const[BceAdmin,setBceAdmin]= useState(false)
    const {t} = useTranslation();
    document.title=  t('quessCompounding') +" | " +t('groupsManagement')
    const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const fetchGroups = async () => {
        const data={
            currentOrg:localStorage.getItem("currentOrg")
        }
      try {
        const response = await axios.post(`${BACKEND_URL}api/user/isAdmin`,data,  config);
       switch (response) {
        case "BCEAdmin":
        setBceAdmin(true)
        break;
        case "Admin": 
          setAdmin(true)
        break;
       }
       const groupsData = await axios.get(`${BACKEND_URL}api/clients`,  config);
       setGroups(groupsData)
      } catch (error) {
        console.error("Error fetching profile data:", error.response ? error.response : error.message);
      
      }
    };
    useEffect(() => {
     
    
        fetchGroups();
      }, []); 
 
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title={t('groupsManagement')} breadcrumbItem={t('listOfGroups')} />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                               {BceAdmin &&(<div className="d-flex gap-1">
                                                    <Button
                                                        color="primary"
                                                        className="add-btn"
                                                        tag={Link}
                                                        to="/create-group"
                                                        id="create-btn" 
                                                    >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                       {t('createGroup')}
                                                    </Button>
                                                  
                                                </div>)} 
                                            </Col>
                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box">
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder={t('search')}
                                                            value={searchTerm}
                                                            onChange={e => setSearchTerm(e.target.value)}
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Col lg={12}>
                                          

                                           
                                              
                                                    <Row>
                                                  
                                                        <Col sm="12">
                                                        <div >
                                                            <BasicTable searchTerm={searchTerm} initialData={groups} isBce={BceAdmin} fetchGroups={fetchGroups} />
                                                            </div>
                                                        </Col>
                                                    </Row>
                                              
                                                  
                                       
                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListGroup;
