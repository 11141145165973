//const role = localStorage.getItem("roleUser");
const SidebarData = (role) => [
    {
        label: "Menu",
        isMainMenu: true,
    },
    {
        label: "Dashboard",
        icon: "mdi mdi-home-variant-outline",
        url: "/dashboard",
        issubMenubadge: true,
        bgcolor: "bg-primary",
        badgeValue: "3"
    },
    {
        label: "Master Formula",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            ...(role === "admin" || role === "BCEAdmin" ? [
                { sublabel: "New Master Formula", link: "/create-template" },
                { sublabel: "Approval Queue", link: "/List-approval-queue" },
                { sublabel: "Rejection Queue", link: "/List-rejection-queue" },
                { sublabel: "List of Master Formula", link: "/List-formula-template" },
            ] : [
                { sublabel: "List of Master Formula", link: "/List-formula-template" },
            ] )
        ],
    },
    {
        label: "Preparation Sheet",
        icon: "mdi mdi-calendar-outline",
        subItem: [
            ...(role === "admin" || role === "BCEAdmin" ? [
          
            { sublabel: "Start/Compound New Preparation", link: "/create-sheet" },
            { sublabel: "Compounding Queue", link: "/List-compounding-queue" },
            { sublabel: "Verification Queue", link: "/list-verification-queue" },
            { sublabel: "Rejection Queue", link: "/" },
            { sublabel: " Compounded Preparation Log", link: "/List-formula-sheet" },
        ] : [
            { sublabel: " Compounded Preparation Log", link: "/List-formula-sheet" },
        ] )
        ],
    },
    {
        label: "Verification & Traceability",
        icon: "mdi mdi-format-page-break",
        url: "/dashboard",
        issubMenubadge: true,
      
       
  
    },
    // {
    //     label: "Pages",
    //     isMainMenu: true,
    // },
    {
        label: " Administration",
        icon: "mdi mdi-account-group-outline",
        subItem: [
            { sublabel: " Group Management", link: "/groups-management" },
            { sublabel: "User Management", link: "/users-management" },
            // { sublabel: "Role Management", link: "/dashboard" },
           
        ],
    },
    {
        label: " Reference Management ",
        icon: "mdi mdi-arrange-send-to-back",
        subItem: [
            { sublabel: " Ingredient Management", link: "/ingredient-management" },
            { sublabel: "Unit Management", link: "/unit-management" },
            { sublabel: "Manufacturer Management", link: "/supplier-management" },
            { sublabel: "Equipment/materials", link: "/equipement-management" },
            { sublabel: "Route of administration", link: "/route-of-administration" },
            { sublabel: "Pharmaceutical ", link: "/pharmaceutical-management" },
           
           
        ],
    },
    {
        label: "Settings",
        icon: "ri-settings-2-line align-middle me-2",
        subItem: [
            { sublabel: "User Profile", link: "/userprofile" },
            
           
        ],
    },
  

   
]
export default SidebarData;