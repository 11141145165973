import React, { useState, useEffect } from "react";
import { Card, CardBody, Form, Alert, Col, Row, Container, CardText, CardTitle, Input, Label, Button, Modal } from "reactstrap";
import logobce from "../../../assets/images/logo_bce.png";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import logoorganization from "../../../assets/images/organization_logo.png";
import '../style.css';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const Loader = () => {
  return (
    <div className="loader-container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const ReviewedStatus = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  document.title = "Review | Quess Magistrale";

  const [loading, setLoading] = useState(false);
  const [sheetDetail, setSheetDetail] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [logo, setLogo] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [actionType, setActionType] = useState(null);
  const [codepin, setCodepin] = useState(['', '', '', '']);
  const [comment, setComment] = useState('');
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);
  const [isPinValid, setIsPinValid] = useState(false);
  const [dataToSend, setDataToSend] = useState({ compoundingId: id,   userId: '', clientId: '', comment: '',  status: ''  });

  const user= JSON.parse(localStorage.getItem("user"));
  const client = JSON.parse(localStorage.getItem("currentOrg"));
  useEffect(() => {
    setDataToSend(prevState => ({
      ...prevState,
      userId: user.id,
      clientId:client,
   
    }));
    }, [user,client]);

  const pinUser = localStorage.getItem("pinUser");
  console.log('pinUser:', pinUser);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    const logoOrg = localStorage.getItem("logoOrg");
    if (logoOrg) {
      setLogo(`${BACKEND_URL}${logoOrg}`);
    }
  }, [logo]);

  useEffect(() => {
    const pinString = codepin.join('');
    setIsPinValid(pinString === pinUser);
  }, [codepin, pinUser]);
  const resetCodePin = () => {
    setCodepin(['', '', '', '']); // Réinitialiser le tableau de code PIN
  };

  
  const fetchSheetDetail = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}api/template/${id}`, config);
      const data = response;
      setSheetDetail(data);
      setIngredients(data.ingredients || []);
      console.log('Sheet Detail:', data);
    } catch (error) {
      console.error('Error fetching sheet detail:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSheetDetail();
    }
  }, [id]);

  if (loading || !sheetDetail) {
    return <Loader />;
  }

  const goBack = () => {
    navigate("/List-approval-queue");
  };

  const openModal = (type) => {
    resetCodePin(); 
    setActionType(type);
    setModalOpen(true);
  };

  const handleApprove = async () => {
    try {
      // Créer une copie de dataToSend et mettre à jour le statut
      const updatedData = {
        ...dataToSend,
        status: 'Compleated',
      };

      console.log('dataToSend:', updatedData); // Afficher les données mises à jour

      const response = await axios.put(`${BACKEND_URL}api/templates/status/${updatedData.compoundingId}`, updatedData, config);
      console.log('response:', response.data);
      localStorage.setItem("Isnotif",true)
      if (response.data.error === false) {
        setMessage('Master Formula Reviewed successfully!');
        setAlert('success');
        setLoading(true);

        navigate("/List-formula-template");
      }
    } catch (error) {
      console.error('Error Reviewed  template:', error);
      setMessage('Failed to Review the Master Formula.');
      setAlert('danger');
      setLoading(true);
      navigate("/List-formula-template");
    } finally {
      setModalOpen(false);
    }
  };



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Master Formula" breadcrumbItem="Review Master Formula" />
          <div style={{ margin: '1%', display: 'flex', justifyContent: 'flex-end' }}>
            <button className="btn btn-primary" onClick={goBack}>
              <i className="fas fa-arrow-left"></i>
            </button>
          </div>
          <div className="row-form ">
            <Row className="mb-3 mt-2">
              <Col md={2}>
                <img src={logobce} alt="BCE Logo" height="24" className="auth-logo logo-dark mx-auto" />
              </Col>
              <Col md={8} className="text-center">
                <Label className="col-form-label">Compounded Sterile Preparation Protocol</Label>
                <p>
                  <span>Number: {sheetDetail.protocolNumber}</span>{" "}
                  <span style={{ marginLeft: "4%" }}>Version: {sheetDetail.version}</span>
                </p>
                <div className="col-md-12 text-center mb-2">
                  <p className="p-card">
                    <h5>{sheetDetail.productName}</h5>
                  </p>
                  <br />
                  <span className="bg-success p-3 text-white">STATUS: {sheetDetail.status}</span>
                </div>
              </Col>
              <Col md={2}>
                <img src={logo ? logo : logoorganization} alt="Logo Organization" height="40" className="logo-organization" />
              </Col>
            </Row>
            <Row>
              <Col lg={12}>
                <Card className="card-bordred">
                  <h5 className="card-header" style={{ background: "#3fb3d6 !important" }}>Header</h5>
                  <CardBody>
                    <Row>
                      <Col lg={3}>
                        <Label htmlFor="route_of-administration " className=" col-form-label ms-3"> Route of administration: </Label>
                      </Col>
                      <Col lg={3}>
                        <p className="p-show">{sheetDetail.routeOfAdministration}</p>
                      </Col>
                      <Col lg={3}>
                        <div>
                          <Label htmlFor="effective_date " className="col-form-label ms-3"> Effective Date: </Label>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div>
                          <p className="p-show">
                            {new Date(sheetDetail.effectiveDate).toLocaleDateString('en-GB', {
                              day: '2-digit', month: '2-digit', year: 'numeric',
                            })}
                          </p>
                        </div>
                      </Col>
                    </Row>
                    <Row className="mt-2">
                      <Col lg={3}>
                        <div>
                          <Label htmlFor="pharmaceutical_form" className=" col-form-label ms-3"> Pharmaceutical form: </Label>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div>
                          <p className="p-show">{sheetDetail.pharmaForm}</p>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div>
                          <Label htmlFor="authorized_by" className="col-form-label ms-3"> Created by: </Label>
                        </div>
                      </Col>
                      <Col lg={3}>
                        <div>
                          <p className="p-show">  {sheetDetail.preparedBy && sheetDetail.preparedBy.map((item) => (item.username))}</p>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="card-bordred">
                  <h5 className="card-header">Formula </h5>
                  <CardBody>
                    <Row className="mt-3">
                      <Col lg={3}>
                        <div className="row">
                          <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                          <div className="col-md-4">
                            <p className="p-show"> {sheetDetail.formulaBatchSize} </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div>
                          <p className="p-show"> {sheetDetail.formulaBtachSizeUnit} </p>
                        </div>
                      </Col>
                      <Col lg={2}></Col>
                      <Col lg={3}>
                        <div className="row">
                          <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size:</label>
                          <div className="col-md-4">
                            <p className="p-show"> {sheetDetail.desiredBatchSize} </p>
                          </div>
                        </div>
                      </Col>
                      <Col lg={2}>
                        <div>   <p className="p-show"> {sheetDetail.desiredBtachSizeUnit} </p>  </div>
                      </Col>
                    </Row>
                    <Row className="mt-3">
                      <div className="table-responsive">
                        <table className="table table-bordered mb-0">
                          <thead style={{ background: "black" }}>
                            <tr id="formula">
                              <td className="th-blue">Ingredients</td>
                              <td className="th-blue">Manufacturer</td>
                              <td className="th-blue">Lot Number</td>
                              <td className="th-blue">Exp Date</td>
                              <td className="th-blue">Quantity</td>
                              <td className="th-blue">Units</td>
                              <td className="th-blue">Quantity Required</td>
                            </tr>
                          </thead>
                          <tbody>
                            {ingredients && ingredients.map((ing, index) => (
                              <tr key={index}>
                                <td>  {ing.name} </td>
                                <td>  {ing.manufacture}  </td>
                                <td className="td-disabled">   {ing.lotNumber}    </td>
                                <td className="td-disabled">   {ing.expDate}  </td>
                                <td>  {ing.quantity}  </td>
                                <td> {ing.unit}  </td>
                                <td>  {ing.qteRequired}   </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card className="card-bordred">
                  <h5 className="card-header">Required equipment/materiels </h5>
                  <CardBody>
                    <Row className="mt-3">
                      <p>Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <div className="table-responsive">
                        <table className="table table-bordered mb-0">
                          <thead style={{ background: "black" }}>
                            <tr>
                              {Object.keys(sheetDetail.equipements[0])
                                .filter(key => key !== "id" && key !== "check")
                                .map((key, index) => (
                                  <td key={index} className="th-blue">
                                    {key === "name" ? "Equipment/Materials" : key.charAt(0).toUpperCase() + key.slice(1)}
                                  </td>
                                ))}
                            </tr>
                          </thead>
                          <tbody>
                            {sheetDetail.equipements.map((equip, index) => (
                              <tr key={index}>
                                {Object.keys(equip)
                                  .filter(key => key !== "id" && key !== "check")
                                  .map((key, i) => (
                                    <td key={i}>{String(equip[key])}</td>
                                  ))}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Row>
                <Col lg={12}>
                  {sheetDetail.ingredientsInfo ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Additional information on ingredients</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.ingredientsInfo }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.calculations ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Calculations and Measurements</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.calculations }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.compoundingMethod ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Compounding Method</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.compoundingMethod }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.qualityControl ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Quality Control</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.qualityControl }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.packaging ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Packaging</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.packaging }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.stabilityAndStorage ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Stability and Storage</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.stabilityAndStorage }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.labelling ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Labelling</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.labelling }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.training ?
                    <Card className="card-bordred">
                      <h5 className="card-header">Training</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.training }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.refs ?
                    <Card className="card-bordred">
                      <h5 className="card-header">References Consulted</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: sheetDetail.refs }}  />
                        </CardText>
                      </CardBody>
                    </Card> : <></>}
                  {sheetDetail.others && sheetDetail.others.map((oth, index) => (
                    <Card className="card-bordred">
                      <h5 className="card-header">{oth.title}</h5>
                      <CardBody>
                        <CardText>
                          <div  dangerouslySetInnerHTML={{ __html: oth.description }}  />
                        </CardText>
                      </CardBody>
                    </Card>
                  ))}
                </Col>
              </Row>
            </Row>
            <Col lg={12} className="fixed-bottom-buttons">
              <Row>
                <Col md={3}></Col>
                <Col md={4}>
                  <div className="text-center">     {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}</div>
                </Col>
                <Col md={5}>
                  <div className="float-end">
                    <button type="button" className="ms-1 btn btn-success me-3" onClick={() => openModal('approve')}>
                    Compleated 
                    </button>
             
                    <button type="reset" className="btn btn-info" onClick={goBack}>
                      Cancel
                    </button>
                  </div>
                </Col>
              </Row>
            </Col>
          </div>
        </Container>
      </div>

      <Modal isOpen={modalOpen} toggle={() => {
        setModalOpen(false);
        resetCodePin();
      }} className="modal-dialog-centered">
            <CardBody>
              <div className="text-center">
                <div className="mb-4">
            
                    <i className="mdi mdi-check-circle-outline display-1 text-success fs-30"></i>
                 
                </div>
                <h4 className="alert-heading">Are you sure?</h4>
                <p className="mb-0">
                  You want to reviewed this formula?
                </p>
                <div className="p-4">
               

                  <br />
                    <h4 className="text-center mb-4">Verify your PIN code</h4>
                    <div className="d-flex justify-content-between mb-3" style={{padding:"0% 5%"}}>
                      {[...Array(4)].map((_, index) => (
                        <Input
                          key={index}
                          type="text"
                          id={`pin-${index}`}
                          maxLength={1}
                          value={codepin[index]}
                          onChange={(e) => {
                            const value = e.target.value;
                            const newCodePin = [...codepin];
                            newCodePin[index] = value;
                            setCodepin(newCodePin);

                            if (value) {
                              const nextInput = document.getElementById(`pin-${index + 1}`);
                              if (nextInput) nextInput.focus();
                            }
                          }}
                          className="pin-input"
                          placeholder="•"
                          style={{
                            width: "50px", height: "50px",
                            textAlign: "center", fontSize: "20px",
                            border: isPinValid ? "2px solid #0ac074" : "2px solid #b6cbd5",
                            backgroundColor: isPinValid ?"#f9f9f9":"#f9f9f9", transition: "border-color 0.7s",
                          }}
                        
                          onFocus={(e) => e.target.style.borderColor = "#0056b3"}
                          onBlur={(e) => e.target.style.borderColor = "#007bff"}
                        />
                      ))}
                    
                    </div>
                </div>
              </div>
            </CardBody>
            <div className="modal-footer text-center" style={{ justifyContent: "center" }}>
              <Button
                color="success"
                onClick={handleApprove}
                className="me-2"
                disabled={ !isPinValid}
              >
                Yes, review it!
              </Button>
              <Button color="secondary" onClick={() => {
                setModalOpen(false); // Fermer le modal
                resetCodePin(); // Réinitialiser le code PIN
              }}>Cancel</Button>
            </div>
          </Modal>
    </React.Fragment>
  );
};

export default ReviewedStatus;
