// DeleteDialog.jsx
import React, { useState } from 'react';
import axios from 'axios';
import { BACKEND_URL } from "../../../config/const.js";
import { useTranslation } from 'react-i18next';
import './Styles.css'
const DeleteDialog = ({ isOpen, handleClose, userId,fetchUserProfile }) => {

    const {t} = useTranslation();

    const [message, setMessage] = useState(null);
  // Function to handle user deletion
  const handleDeleteUser = async () => {
    const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
      };
      const data={
        idUser:userId,
        idGroup: localStorage.getItem('currentOrg')
      }
    try {
      // Replace with your delete API endpoint
      const response = await axios.delete(`${BACKEND_URL}api/user/delete`, {
        data: data, // Attach payload here
        headers: config.headers, // Or any other config like headers
      });
      console.log('User deleted:', response.data);
      fetchUserProfile()
      setMessage({ type: 'success', text: t('successDeleteUser')  });

      setTimeout(() => {
        setMessage(null); // Clear the message
        handleClose(); // Close the dialog
      }, 2000);
    } catch (error) {
      console.error('Error deleting user:', error);
      setMessage({ type: 'error', text: t('failureDeleteUser')  });

      // Hide message after 2 seconds
      setTimeout(() => {
        setMessage(null); // Clear the message
      }, 2000);
    }
  };

  if (!isOpen) return null; // Don't render if the dialog is not open

  return (
    <>
    <div className="modal fade show" style={{ display: 'block' }} tabIndex="-1">
      <div className="modal-dialog modal-dialog-centered">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{t('deleteUser')}</h5>
            <button type="button" className="btn-close" onClick={handleClose}></button>
          </div>
          <div className="modal-body">
            <p>{t('messageDeleteUser')}</p>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              onClick={handleClose}
            >
              {t('cancel')}
            </button>
            <button
              type="button"
              className="btn btn-danger"
              onClick={handleDeleteUser}
            >
              {t('delete')}
            </button>
          </div>
        </div>
      </div>
    </div>
        {/* Success/Failure message */}
        {message && (
            <div
              style={{
                position: 'fixed',
                bottom: '10px',
                left: '50%',
                transform: 'translateX(-50%)',
                padding: '10px 20px',
                backgroundColor: message.type === 'success' ? 'green' : '#ff3d60',
                color: 'white',
                borderRadius: '5px',
                zIndex: 1000,
                minWidth:"25%",
                textAlign:'center',
                transition: 'opacity 0.5s ease-in-out',
              }}
            >
              {message.text}
            </div>
          )}
          </>
  );
};

export default DeleteDialog;
