// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.avatar {
    width: 60px; /* Largeur de l'avatar */
    height: 60px; /* Hauteur de l'avatar */
    border-radius: 50%; /* Rendre l'avatar circulaire */
    background-color: #007bff; /* Couleur de fond de l'avatar */
    color: white; /* Couleur du texte */
    display: flex; /* Utiliser flexbox pour centrer le texte */
    align-items: center; /* Centrer verticalement */
    justify-content: center; /* Centrer horizontalement */
    font-size: 24px; /* Taille de la police */
    margin: 0 auto; /* Centrer l'avatar dans le conteneur */
  }
  .pin-input {
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
    transition: border-color 0.3s;
  }
  .pad-5{
    padding: 1% 5%;
  }
  .pin-input:focus {
    border-color: #007bff; /* Couleur de focus */
    outline: none;
  }`, "",{"version":3,"sources":["webpack://./src/Pages/Dashboard/style.css"],"names":[],"mappings":"AAAA;IACI,WAAW,EAAE,wBAAwB;IACrC,YAAY,EAAE,wBAAwB;IACtC,kBAAkB,EAAE,+BAA+B;IACnD,yBAAyB,EAAE,gCAAgC;IAC3D,YAAY,EAAE,qBAAqB;IACnC,aAAa,EAAE,2CAA2C;IAC1D,mBAAmB,EAAE,0BAA0B;IAC/C,uBAAuB,EAAE,4BAA4B;IACrD,eAAe,EAAE,wBAAwB;IACzC,cAAc,EAAE,uCAAuC;EACzD;EACA;IACE,sBAAsB;IACtB,kBAAkB;IAClB,yBAAyB;IACzB,6BAA6B;EAC/B;EACA;IACE,cAAc;EAChB;EACA;IACE,qBAAqB,EAAE,qBAAqB;IAC5C,aAAa;EACf","sourcesContent":[".avatar {\n    width: 60px; /* Largeur de l'avatar */\n    height: 60px; /* Hauteur de l'avatar */\n    border-radius: 50%; /* Rendre l'avatar circulaire */\n    background-color: #007bff; /* Couleur de fond de l'avatar */\n    color: white; /* Couleur du texte */\n    display: flex; /* Utiliser flexbox pour centrer le texte */\n    align-items: center; /* Centrer verticalement */\n    justify-content: center; /* Centrer horizontalement */\n    font-size: 24px; /* Taille de la police */\n    margin: 0 auto; /* Centrer l'avatar dans le conteneur */\n  }\n  .pin-input {\n    border: 1px solid #ccc;\n    border-radius: 8px;\n    background-color: #f9f9f9;\n    transition: border-color 0.3s;\n  }\n  .pad-5{\n    padding: 1% 5%;\n  }\n  .pin-input:focus {\n    border-color: #007bff; /* Couleur de focus */\n    outline: none;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
