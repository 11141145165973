import React, { useState,useEffect, useRef } from "react";
import { Card, CardBody, Form, Col, Row, Container,Alert, CardText, Input, Label } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import logoorganization from "../../assets/images/organization_logo.png";
import './style.css';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BACKEND_URL } from "../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import PrintComponent from './printComponent';

const Loader = () => {
  return (
    <div className="loader-container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
const TextWithInputFields = ({ htmlContent, onInputChange }) => {
  const [inputs, setInputs] = useState({});

  // Fonction pour insérer des champs input à la place des soulignés
  const convertHtmlWithInputs = (html) => {
    const regex = /_{2,}/g;
    let inputIndex = 0;

    // Remplacer les espaces soulignés par des champs de saisie
    const updatedHtml = html.replace(regex, (match) => {
      const id = `input_${inputIndex}`;
      inputIndex++;
      return `<input type="text" id="${id}" style="border-bottom:1px solid black; width: 100px;" />`;
    });

    return updatedHtml;
  };

  // Fonction pour gérer la modification des champs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs({ ...inputs, [id]: value });
    onInputChange(id, value);
  };

  // Utiliser useEffect pour lier les événements de changement d'entrée après le rendu
  React.useEffect(() => {
    Object.keys(inputs).forEach((id) => {
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.value = inputs[id];
        inputElement.oninput = handleInputChange;
      }
    });
  }, [htmlContent, inputs]);

  return (
    <div dangerouslySetInnerHTML={{ __html: convertHtmlWithInputs(htmlContent) }} />
  );
};
const CreateNewSheet = () => {
  const navigate = useNavigate();
  document.title = "Create New Sheet | Quess Magistrale";
  const [displayNone, setDisplayNone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);
  const [desiredBatch, setDesiredBatch] = useState('');
  const [formulaBatch, setFormulaBatch] = useState('');
  const [unitDesired, setUnitDesired] = useState('');
  const [clientId, setClientId] = useState(null);
  const [templatesData, setTemplatesData] = useState([]);
  const [templateDetail, setTemplateDetail] = useState(null);
  const [users, setUsers] = useState([]);
 const [ingredients, setIngredients] = useState([]);
 const [approvedBy, setApprovedBy] = useState("");
 const [logo, setLogo] = useState(null);
 const [equipmentRows, setEquipmentRows] = useState([]);
 const [otherRows, setOtherRows] = useState([]);
 const [equipmentSend, setEquipmentSend] = useState([]);
 const [isChecked, setIsChecked] = useState(true);
 const [expdateInvalid, setExpdateInvalid] = useState(true);
 const [scal, setScal] = useState(1);
 const [units, setUnits] = useState([]);
 const [ref, setRef] = useState(null);
 const [operationStatus, setOperationStatus] = useState("Staging");
  const config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};
useEffect(() => {

  fetchUsers();
}, [clientId]);
const handleInputChange = (inputId, value) => {
  console.log(`Changed input ${inputId}: ${value}`);
  // Effectuez des actions avec la valeur changée
};
const user= JSON.parse(localStorage.getItem("user"));

const handleIngredientChange = (index, field, value) => {
  const updatedIngredients = [...ingredients];
  updatedIngredients[index] = { 
    ...updatedIngredients[index], 
    [field]: value 
  };

  // Vérification de la date d'expiration
  if (field === 'expDate' && value !== '') {
    const expDate = new Date(value);
    const effectiveDate = new Date(formData.effectiveDate);
setExpdateInvalid(true)
    // Alerte rouge par défaut
    setAlert('danger');
    setMessage('Expiration date cannot be earlier than the effective date.');

    // Vérifier si les dates sont différentes mais valides
    if ( expDate >= effectiveDate) {
      setExpdateInvalid(false)
    
    } else if (expDate === effectiveDate) {
      setAlert(null); // Réinitialiser si les dates sont égales et valides
    }
  }

  setIngredients(updatedIngredients); // Mettre à jour l'état avec les ingrédients modifiés
};

useEffect(() => {
  fetchUnits();

}, [clientId]);
useEffect(() => {
  handleIngredientChange();
}, []);
// Récupération des informations de l'utilisateur connecté
useEffect(() => {
  const client = JSON.parse(localStorage.getItem("currentOrg"));
  const logoOrg = localStorage.getItem("logoOrg"); // Assuming logoOrg is just a URL string

  if (logoOrg) {
    setLogo(`${BACKEND_URL}${logoOrg}`);// Ensure this constructs a valid URL
  }
if (client) {
    setClientId(client);
}
}, [clientId, logo]);
//fetch users
const fetchUsers = async () => {
  try {
    if (clientId) {
      console.log('start users sheet');
      
      const response = await axios.get(`${BACKEND_URL}api/getGroupUsers/1`, config);
      console.log('users:', response);
      setUsers(response);
    }

  } catch (error) {
    console.error('Error fetching user:', error);
  }
};

const fetchUnits = async () => {
  try { if (clientId) {
      const response = await axios.get(`${BACKEND_URL}api/unitsbyclient/${clientId}`, config);
      console.log('units:', response);
      setUnits(response.data);
    }

  } catch (error) {
    console.error('Error fetching unit:', error);
  }
};

  const handleDisplay = () => {
    const now = new Date();
    const formattedDate = `${String(now.getDate()).padStart(2, '0')}${String(now.getMonth() + 1).padStart(2, '0')}${String(now.getFullYear()).slice(-2)}-${String(now.getHours()).padStart(2, '0')}${String(now.getMinutes()).padStart(2, '0')}${String(now.getSeconds()).padStart(2, '0')}`;
    
    setRef(formattedDate);
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setDisplayNone(true);
      setIsPrintable(true);
    }, 3000);
  };

  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
// Update formulaBatch in real-time
  };

  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);
// Update unitFormula in real-time
  };
const userId = user.id;
const fetchTemplatesData = async (category) => {
  try {
    const reviewedBy= userId
    const response = await axios.get(`${BACKEND_URL}api/templates/${clientId}/${category}/${reviewedBy}`, config);
    console.log('template:', response);
   
      const data = response.map(item => ({
        id: item.id,
        title: item.productName, // Assuming you have a productName field
      }));
      setTemplatesData(data); // Set the templates data based on the API response
    
    console.log('template data:', templatesData);
  } catch (error) {
    console.error('Error fetching template:', error);
  }
};
const fetchTemplateDetail = async (product) => {
  try {
    const response = await axios.get(`${BACKEND_URL}api/template/${product}`, config);
    console.log('template:', response);
   
      setTemplateDetail(response); // Set the templates data based on the API response
      setIngredients(response.ingredients)
      setEquipmentRows(response.equipements)
      setOtherRows(response.others)
      setFormulaBatch(response.formulaBatchSize)
      setUnitDesired(response.desiredBtachSizeUnit)
      setIsChecked(response.scal==1?true:false)
  if (response){
    response.approvedBy&&response.approvedBy.map((item) => (
       setApprovedBy(item.id)))
   

  }
    console.log('template detail:', templateDetail);
    console.log('ingredient:', ingredients);
  } catch (error) {
    console.error('Error fetching template:', error);
  }
};

const [formData, setFormData] = useState({
  effectiveDate: new Date().toISOString().split('T')[0], 
  checkedBy:'',
  preparedBy:'',
  approvedBy:''
});

const handleFormChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value, // Dynamically update form data
  });
};
useEffect(() => {
  if (formData.category) {
    fetchTemplatesData(formData.category);
  }
}, [formData.category]); // Re-fetch templates when category changes

useEffect(() => {
  if (formData.product) {
    fetchTemplateDetail(formData.product);
  }
}, [formData.product]); 


const handleCheckboxChange = (e) => {
  setIsChecked(e.target.checked);
  if (e.target.checked) {
    const scal = formulaBatch / desiredBatch; // Calculate the scale once checkbox is checked

    const updatedFormulaRows = ingredients.map((formula) => ({
      ...formula,
      qteRequired: formula.quantity * scal, // Update the Quantity Required for each row
    }));

    setIngredients(updatedFormulaRows); // Update state with the new values
  }
};

useEffect(() => {
  if (isChecked && formulaBatch > 0 && desiredBatch > 0) {
    const scale =  desiredBatch/ formulaBatch;
    setScal(scale);
    const updatedFormulaRows = ingredients.map((formula) => ({
      ...formula,
      qteRequired: formula.check? formula.quantity * scale : formula.qteRequired // Mise à jour de Quantity Required avec le scale
    }));

    setIngredients(updatedFormulaRows);

  }
}, [isChecked, desiredBatch,ingredients]);

useEffect(() => {
  
  const updatedEquipmentRows = equipmentRows.map(item => ({
    ...item ,
    quantity: item.check? item.quantity * scal : item.quantity// Multiply by scal
  }));
  //setEquipmentRows(updatedEquipmentRows);
  setEquipmentSend(updatedEquipmentRows);
}, [scal]);

const handleSubmit = (e) => {
  e.preventDefault();
  if (expdateInvalid === true) {
    setAlert('danger');
    setMessage('Warning: Expiration date is earlier than the effective date. Please check your dates.');
} else {
 
    const templateData = {
      category:templateDetail.category,
      pharmaForm:templateDetail.pharmaForm ,
      routeOfAdministration:templateDetail.routeOfAdministration,
      effectiveDate:formData.effectiveDate,
      formulaBatchSize: parseInt(templateDetail.formulaBatchSize, 10), // 10 pour base décimale
      desiredBatchSize: parseInt(desiredBatch, 10),
      formulaBtachSizeUnit: templateDetail.formulaBtachSizeUnit,
      desiredBtachSizeUnit: unitDesired,
      productName: templateDetail.productName,
      preparedBy:user.id,
      ingredients: ingredients,
      equipements: equipmentSend.length > 0 ? equipmentSend : equipmentRows,
      clientId:clientId,
      approvedBy:formData.approvedBy,
      status: operationStatus,
      //lotNumber: templateDetail.lotNumber,
      protocolNumber: templateDetail.protocolNumber,
      parentId:templateDetail.id,
      checkedBy:formData.checkedBy,
      calculations:templateDetail.calculations,
      compoundingMethod:templateDetail.compoundingMethod,
      ingredientsInfo:templateDetail.ingredientsInfo,
      labelling:templateDetail.labelling,
      packaging:templateDetail.packaging,
      qualityControl:templateDetail.qualityControl,refs:templateDetail.refs,
      training:templateDetail.training,stabilityAndStorage:templateDetail.stabilityAndStorage,
      version: templateDetail.version,
      scal:templateDetail.scal,
      others:otherRows,
    };
  

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  // axios.get(`${BACKEND_URL}api/equipements/${clientId}`, config);
  axios.post(`${BACKEND_URL}api/formulas/new`, templateData, config)
    .then((response) => {
      console.log(response);
      setMessage('Compounded Preparation Added successfully...');
      setAlert('success');
      setLoading(true);
  
      // Afficher le message pendant 3 secondes avant de naviguer
      setTimeout(() => {
        setLoading(false);
        navigate('/List-formula-sheet'); // Naviguer après 3 secondes
      }, 3000); 

    })
    .catch((error) => {
      if (error === "Request failed with status code 400")  {
      
        // Handle other types of errors
        setLoading(true);
        setAlert('danger');
        setMessage('Error added Compounded Preparation.');
    }            
    // Log the error for debugging
    console.error('Error  added Compounded Preparation:', error);
    setMessage('Error added Compounded Preparation.');
    setTimeout(() => {
        setLoading(false);
    }, 2000);
    });
  }
    
};

// Fonction pour gérer l'action de sauvegarde
const handleSave = (e) => {
  e.preventDefault();
  setOperationStatus("Staging"); // Définir le statut sur "Staging"
   handleSubmit(e); // Appeler la fonction de soumission
};

// Fonction pour gérer l'action de composition
const handleCompound = (e) => {
  e.preventDefault();
  setOperationStatus("Compounding"); 
    console.log('statusssss:',operationStatus);
    // Définir le statut sur "Compounding"
   handleSubmit(e); // Appeler la fonction de soumission
};

const printRef = useRef(null);

const [isPrintable, setIsPrintable] = useState(false);



const Print = useReactToPrint({
  content: () => printRef.current,
  documentTitle: `Formula-${templateDetail?.productName || 'Sheet'}`,
  // pageStyle: printStyles,
  onBeforeGetContent: () => {
    if (!isPrintable) {
      alert("Please generate the content first before printing");
      return false;
    }
    return true;
  },
  onAfterPrint: () => {
    console.log('Printed successfully');
  },
});

const goBack=()=>{
  navigate("/List-formula-sheet"); // Redirect after successful creation
}

const CameraComponent = ({ onCapture, onClose }) => {
  const videoRef = useRef(null);
  const [stream, setStream] = useState(null);

  useEffect(() => {
    // Demander l'accès à la caméra
    navigator.mediaDevices.getUserMedia({ video: true })
      .then(stream => {
        setStream(stream);
        if (videoRef.current) {
          videoRef.current.srcObject = stream;
        }
      })
      .catch(err => console.error("Erreur d'accès à la caméra:", err));

    // Cleanup
    return () => {
      if (stream) {
        stream.getTracks().forEach(track => track.stop());
      }
    };
  }, []);

  const takePhoto = () => {
    const video = videoRef.current;
    const canvas = document.createElement('canvas');
    canvas.width = video.videoWidth;
    canvas.height = video.videoHeight;
    canvas.getContext('2d').drawImage(video, 0, 0);
    const photo = canvas.toDataURL('image/jpeg');
    onCapture(photo);
  };

  return (
    <div className="camera-modal">
      <video ref={videoRef} autoPlay />
      <div className="camera-controls">
        <button onClick={takePhoto} className="btn btn-primary">
          <i className="fas fa-camera"></i> Prendre la photo
        </button>
        <button onClick={onClose} className="btn btn-danger">
          <i className="fas fa-times"></i> Fermer
        </button>
      </div>
    </div>
  );
};

const PhotoPlaceholder = ({ onPhotoTaken }) => {
  const [showCamera, setShowCamera] = useState(false);
  const [photo, setPhoto] = useState(null);

  const handleCapture = (photoData) => {
    setPhoto(photoData);
    setShowCamera(false);
    onPhotoTaken(photoData);
  };

  return (
    <div className="photo-placeholder">
      {photo ? (
        <div className="photo-container">
          <img src={photo} alt="Captured" className="captured-photo" />
          <button
            className="btn btn-sm btn-warning"
            onClick={() => setShowCamera(true)}
          >
            <i className="fas fa-redo"></i> Reprendre
          </button>
        </div>
      ) : (
        <button
          className="btn btn-primary"
          onClick={() => setShowCamera(true)}
        >
          <i className="fas fa-camera"></i> Prendre une photo
        </button>
      )}

      {showCamera && (
        <div className="camera-overlay">
          <CameraComponent
            onCapture={handleCapture}
            onClose={() => setShowCamera(false)}
          />
        </div>
      )}
    </div>
  );
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Preparation Sheet" breadcrumbItem="Start/Compound New Preparation" />

          <div className="row-form mb-4">
            <Row className="mb-3 mt-2">
              <Col md={4} className="pe-4">
              <select className="form-select" name="category" onChange={handleFormChange} required>
                <option>Select category</option>
                <option value={1}>Template Sterile</option>
                <option value={2}>Template Non Sterile</option>
              </select>
              </Col>
              <Col md={4} className="pe-4" >
                <select className="form-select" name="product"   onChange={handleFormChange}>
                <option key={0}>Select template Name</option>
                {templatesData && templatesData.map((pha) => (<option value={pha.id} key={pha.id}>{pha.title}</option>))}
                </select>
              </Col>
              <Col md={3} style={{display:'flex'}}>
                {templateDetail ? (
                  <>
                    <button 
                      className="ms-1 btn btn-info me-3" 
                      style={{ width: "100%" }} 
                      onClick={handleDisplay}
                    >
                      Generate <i className="bx bxs-file-txt me-1"></i>
                    </button>
                    <button 
                      className="btn btn-primary" 
                      onClick={Print} 
                      style={{marginRight:"10px"}} 
                      disabled={!isPrintable}
                    >
                      <i className="fas fa-file-pdf"></i>
                    </button>
                  </>
                ) : null}
              </Col>
            </Row>
            {loading ? (
              <Loader />
            ) : displayNone ? (
              <>
                <div style={{ display: 'none' }}>
                  
                <div ref={printRef} >
                    <PrintComponent 
                      templateDetail={templateDetail}
                      ingredients={ingredients}
                      equipmentRows={equipmentRows}
                      formData={formData}
                      logo={logo}
                      logobce={logobce}
                      user={user}
                      others={otherRows}
                    />
                       </div>
                       </div>
               
                  <Form >
                    <Row id="print-content">
                      {/* Ajouter le conteneur des logos */}
                    

                      <Col lg={12}>
                        <Card className="card-bordred">
                          <h5 className="card-header">Header</h5>
                          <div className="text-center"> </div>
                          <CardBody>
                            <Row className="mb-3 mt-2 logo-container">
                              <Col md={2} className="logo-left">
                                <img  src={logobce} alt="logo"  height="24"  className="auth-logo logo-dark mx-auto"  />
                              </Col>
                              <Col md={8} className="text-center">

                                <Label className="col-form-label">Compounded  {templateDetail.category=== 1?"Sterile":  "Non Sterile" }  Preparation Protocol</Label>
                                <p> <span className="">Number : {templateDetail.protocolNumber}  </span> <span style={{marginLeft:"12px"}}> Version: {templateDetail.version}</span></p>

                                <div className="col-md-12 text-center mb-2">
                                  <p className="p-card">   <span>{templateDetail.productName}</span><br />  </p>
                                  <br />
                                  <span className=" bg-info p-3 text-white">STATUS: STAGING</span>
                                  <br /><br />
                                </div>


                              </Col>
                              <Col md={2} className="logo-right">
                              <img  src={logo?logo:logoorganization} alt="Logo Organization" className="logo-organization" />
                              </Col>
                            </Row>

                            <Row  >
                              <Col lg={3}>
                                <div>
                                  <Label htmlFor="route_of-administration " className=" col-form-label ms-3"  > Route of administration: </Label>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                  < Input className="form-control" type="text" value={templateDetail.routeOfAdministration} disabled />
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                  <Label htmlFor="effective_date " className="col-form-label ms-3"  > Effective Date: </Label>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                  <Input className="form-control" type="date" value={formData.effectiveDate}  disabled />
                                </div>
                              </Col>
                            </Row>

                            <Row className="mt-2" >
                              <Col lg={3}>
                                <div>
                                  <Label htmlFor="pharmaceutical_form" className=" col-form-label ms-3"  > Pharmaceutical form: </Label>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                  < Input className="form-control" type="text" value={templateDetail.pharmaForm} disabled />
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                  <Label htmlFor="authorized_by" className="col-form-label ms-3"  > Created by: </Label>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                {/* <Input className="form-control" type="text"   value={ templateDetail.approvedBy}  disabled /> */}
                                <select  className="form-select"  name="preparedBy"    onChange={handleFormChange} value={user.id}  disabled>
                                      <option value={formData.preparedBy}>Select User</option>
                                      {users.map((user) => (
                                        <option value={user.idUser}>
                                          {user.firstName} {user.lastName}
                                        </option>
                                      ))}
                                      
                                    </select>
                                {/* <Input className="form-control" type="text"
                                   value={ templateDetail.preparedBy&&templateDetail.preparedBy.map((item) => ( item.username))}  disabled /> */}
                                </div>
                              </Col>


                            </Row>

                            {/* <Row className="mt-3">
                              <Col lg={3}>
                                <div>
                                  <Label htmlFor="approvedBy" className="col-form-label ms-3"  > Compounded by<small className="red-required">*</small> </Label>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                <select  className="form-select"  name="approvedBy"  onChange={handleFormChange}   required>
                                      <option value="">Select User</option>
                                      {users.map((user) => (
                                        <option value={user.idUser}>
                                          {user.firstName} {user.lastName}
                                        </option>
                                      ))}
                                </select>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                  <Label htmlFor="checked_by" className="col-form-label ms-3"  > Checked by<small className="red-required">*</small> </Label>
                                </div>
                              </Col>
                              <Col lg={3}>
                                <div>
                                <select  className="form-select"  name="checkedBy"  onChange={handleFormChange}  required >
                                      <option value="">Select User</option>
                                      {users.map((user) => (
                                        <option value={user.idUser}>
                                          {user.firstName} {user.lastName}
                                        </option>
                                      ))}
                                </select>
                                </div>
                              </Col>

                            </Row > */}


                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg={12}>
                        <Card className="card-bordred">
                          <h5 className="card-header">Formula </h5>
                          <CardBody>

                          <div className="form-check form-check-end">
                            <input  className="form-check-input"  type="checkbox"  id="checked"   name="isChecked"  onChange={handleCheckboxChange}   defaultChecked={isChecked} disabled
                            />
                            <label className="form-check-label" htmlFor="defaultCheck2">Batch quantity auto calculate</label>
                          </div>

                            <Row className="mt-3">
                              <Col lg={3}>
                                <div className="row">
                                  <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                                  <div className="col-md-4">
                                    <input  id="formula_batch"   className="form-control"  type="text"  
                                     value={templateDetail.formulaBatchSize}   name="formula_batch" disabled />
                                  </div>
                                </div>
                              </Col>

                              <Col lg={2}>
                                <div>
                                <select className="form-select" name="unit_formula"  value={templateDetail.formulaBtachSizeUnit} disabled >
                                  {units.map((item) => (<option value={item.shorthand} key={item.id}>{item.shorthand}</option>))}
                                </select>
                               
                                </div>
                              </Col>

                              <Col lg={2}></Col>

                              <Col lg={3}>
                                <div className="row">
                                  <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size<small className="red-required">*</small></label>
                                  <div className="col-md-4">
                                    <input  id="desired_batch"  className="form-control"  type="text"
                                     defaultValue={templateDetail.desiredBatchSize}  onChange={handleDesiredBatchChange}  name="desired_batch"
                                    />
                                  </div>
                                </div>
                              </Col>

                              <Col lg={2}>
                                <div>
                                <select className="form-select" name="unit_desired"   defaultValue={unitDesired} onChange={handleUnitDesiredChange} >
                                  {units.map((item) => (<option value={item.shorthand} key={item.id}>{item.shorthand}</option>))}
                                </select>

                                </div>
                              </Col>
                            </Row>
                            <Row className="mt-3">
                              <div className="table-responsive">
                                <table className="table table-bordered mb-0">
                                  <thead style={{ background: "black" }}>
                                    <tr id="formula">
                                    <td className="th-blue">Ingredients</td>
                                      <td className="th-blue">Manufacturer</td>
                                      <td className="th-blue">Lot Number<small className="red-required">*</small></td>
                                      <td className="th-blue">Exp Date <small className="red-required">*</small></td>
                                      <td className="th-blue">QS</td>
                                      <td className="th-blue">Quantity</td>
                                      <td className="th-blue">Units</td>
                                      <td className="th-blue">Quantity Required</td>

                                    </tr>
                                  </thead>
                                  <tbody>
                                  {ingredients&&ingredients.map((ing, index) => (
                                    <tr key={index}>
                                      <td>
                                        <Input className="form-control" type="text" value={ing.name} disabled />
                                      </td>
                                      <td>
                                        <Input className="form-control" type="text" value={ing.manufacture} disabled />
                                      </td>
                                      <td>
                                        <Input  className="form-control"   type="text"  defaultValue={ing.lotNumber} 
                                          onChange={(e) => handleIngredientChange(index, 'lotNumber', e.target.value)} required
                                        />
                                      </td>
                                      <td>
                                        <Input 
                                            className={`form-control ${ing.expDate !== '' && ing.expDate < formData.effectiveDate ? 'is-invalid' : ''}`} 
                                            type="date" 
                                            defaultValue={ing.expDate} 
                                            onChange={(e) => handleIngredientChange(index, 'expDate', e.target.value)} 
                                            required 
                                        />
                                          {expdateInvalid && ( <div className="invalid-feedback">  Expiration date cannot be earlier than {formData.effectiveDate}  </div>  )}
                                      </td>
                                      <td className="td-disabled">
                                      <input
                                          className="form-check-input"  type="checkbox"  name="qs" defaultChecked={ing.qs} disabled />
                                      </td>
                                      <td>
                                        <Input className="form-control" type="text" value={ing.quantity} disabled />
                                      </td>
                                      <td>
                                        <Input className="form-control" type="text" value={ing.unit} disabled />
                                      </td>
                                      <td>
                                        <Input className="form-control" type="text" value={ing.qteRequired} disabled />
                                      </td>
                                    </tr>
                                  ))}



                                  </tbody>
                                </table>
                              </div>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>

                      <Col lg={12}>
                        <Card className="card-bordred">
                          <h5 className="card-header">Required Equipment/Materiels </h5>
                          <CardBody>

                            <Row className="mt-3">
                              <div className="table-responsive">
                             
                                <table className="table table-bordered mb-0">
                                  <thead style={{ background: "black" }}>
                                    <tr id="Equipement">

                                    <td className="th-blue">Equipment/Materials</td>
                                    <td className="th-blue">Quantity</td>
                                      {/* <th>Other</th> */}
                                    </tr>
                                  </thead>
                                  <tbody>
                                  {scal>1 ? (
                                      equipmentSend.map((equip, index) => (
                                        <tr key={index}>
                                          <td>
                                            <input  className="form-control"   type="text"  value={equip.name}  disabled  />
                                          </td>
                                          <td>
                                            <input className="form-control"  type="number"  value={equip.quantity}  disabled  />
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      equipmentRows.map((equip, index) => (
                                        <tr key={index}>
                                          <td>
                                            <input  className="form-control" type="text"  value={equip.name}  disabled  />
                                          </td>
                                          <td>
                                            <input  className="form-control"  type="number"  value={equip.quantity}  disabled  />
                                          </td>
                                        </tr>
                                      ))
                                    )}


                                  </tbody>
                                </table>
                              </div>
                            </Row>

                          </CardBody>
                        </Card>
                      </Col>


                      <Col lg={12}>
                      {templateDetail.ingredientsInfo?
                        <Card className="card-bordred">
                          <h5 className="card-header">Additional information on ingredients</h5>
                          <CardBody>

                            <CardText>
                              
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.ingredientsInfo }}  />
                            </CardText>

                          </CardBody>
                        </Card>:<></>
                        }
                         {templateDetail.calculations?
                        <Card className="card-bordred">
                          <h5 className="card-header">Calculations and Measurements</h5>
                          <CardBody>

                            <CardText>
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.calculations }}  />
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                         {templateDetail.compoundingMethod?
                        <Card className="card-bordred">
                          <h5 className="card-header">Compounding Method</h5>
                          <CardBody>
                            <CardText>
                            {/* <TextWithInputFields  htmlContent={templateDetail.compoundingMethod}  onInputChange={handleInputChange}   /> */}
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.compoundingMethod }}  />
                    
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                         {templateDetail.qualityControl?
                        <Card className="card-bordred">
                          <h5 className="card-header">Quality Control</h5>
                          <CardBody>

                            <CardText>
                            {/* <TextWithInputFields  htmlContent={templateDetail.qualityControl}  onInputChange={handleInputChange}  /> */}
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.qualityControl }}  />
                         
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                         {templateDetail.packaging?
                        <Card className="card-bordred">
                          <h5 className="card-header">Packaging </h5>
                          <CardBody>

                            <CardText>
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.packaging }}  />
                  
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                         {templateDetail.stabilityAndStorage?
                        <Card className="card-bordred">
                          <h5 className="card-header">Stability and Storage</h5>
                          <CardBody>

                            <CardText>
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.stabilityAndStorage }}  />
                      
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                          {templateDetail.labelling?
                        <Card className="card-bordred">
                          <h5 className="card-header">Labelling</h5>
                          <CardBody>

                            <CardText>
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.labelling }}  />
                      
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                         {templateDetail.training ? (
                          <Card className="card-bordred">
                            <h5 className="card-header">Training</h5>
                            <CardBody>
                              <CardText>
                                {templateDetail.training.split(/(\[PHOTO\])/).map((segment, index) => {
                                  if (segment === '[PHOTO]') {
                                    // Créer un composant pour gérer la photo
                                    return (
                                      <PhotoPlaceholder
                                        key={index}
                                        onPhotoTaken={(photoData) => {
                                          // Gérer la photo prise
                                          console.log('Photo prise:', photoData);
                                          // Ici vous pouvez ajouter la logique pour sauvegarder la photo
                                        }}
                                      />
                                    );
                                  }
                                  return (
                                    <div
                                      key={index}
                                      dangerouslySetInnerHTML={{ __html: segment }}
                                    />
                                  );
                                })}
                              </CardText>
                            </CardBody>
                          </Card>
                        ) : <></>}
                         {templateDetail.refs?
                        <Card className="card-bordred">
                          <h5 className="card-header">References Consulted</h5>
                          <CardBody>

                            <CardText>
                            <div  dangerouslySetInnerHTML={{ __html: templateDetail.refs }}  />
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                          {otherRows&&otherRows.map((oth, index) => (
                         <Card className="card-bordred">
                         <h5 className="card-header">{oth.title}</h5>
                         <CardBody>

                           <CardText>
                           <div  dangerouslySetInnerHTML={{ __html: oth.description }}  />
                           </CardText>

                         </CardBody>
                         </Card>
                           ))}
               
                      </Col>
                      <Col lg={12} className="fixed-bottom-buttons">
                      <Row>
                      <Col md={3}></Col>
                        <Col md={5}>
                          <div className="text-center">
                          {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}
                          {expdateInvalid && alert === "danger" ? ( <Alert color={alert}>{message}</Alert> ) : null}
                          </div>
                        </Col>
                        <Col md={4}>
                          <div className="float-end">
                            <button type="button" className="ms-1 btn btn-info me-3" onClick={handleCompound}>Compound</button>
                            <button type="button" className="ms-1 btn btn-success me-3" onClick={handleSave}>Save</button>
                            <button type="reset" className="btn btn-secondary" onClick={() => goBack()}>Cancel</button>
                          </div>
                        </Col>
                  
                      </Row>
                      </Col>
                    </Row>
                  </Form>
             
                
              </>
            ) : null}


           
                
             
           
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewSheet;
