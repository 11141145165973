import React, { useState ,useEffect} from "react";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { Dropdown, DropdownToggle, DropdownMenu, Row, Col } from "reactstrap";
import SimpleBar from "simplebar-react";
//Import images
import avatar3 from "../../../assets/images/users/avatar-3.jpg";
import avatar4 from "../../../assets/images/users/avatar-4.jpg";

//i18n
import { withTranslation } from "react-i18next";

import { useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

const timeAgo = (dateString) => {
  const now = new Date();
  const [datePart, timePart] = dateString.split(' ');
  const [day, month, year] = datePart.split('-');
  const [hours, minutes] = timePart.split(':');
  
  // Create a Date object from the notification date and add 1 hour
  const notificationDate = new Date(year, month - 1, day, parseInt(hours) + 1, minutes); // Add 1 hour
  
  const seconds = Math.floor((now - notificationDate) / 1000);
  let interval = Math.floor(seconds / 31536000);
  
  if (interval >= 1) return `${interval} year${interval === 1 ? '' : 's'} ago`;
  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) return `${interval} month${interval === 1 ? '' : 's'} ago`;
  interval = Math.floor(seconds / 86400);
  if (interval >= 1) return `${interval} day${interval === 1 ? '' : 's'} ago`;
  interval = Math.floor(seconds / 3600);
  if (interval >= 1) return `${interval} hour${interval === 1 ? '' : 's'} ago`;
  interval = Math.floor(seconds / 60);
  if (interval >= 1) return `${interval} minute${interval === 1 ? '' : 's'} ago`;
  return 'just now';
};

const NotificationDropdown = props => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(false);
  const [notifs, setNotifs] = useState(null);
  const [countNotifs, setCountNotifs] = useState(0);
  const isnotif = localStorage.getItem("Isnotif");
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  console.log('user',user);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const clientId = localStorage.getItem("currentOrg")

  const fetchNotif= async () => {
      try {
        console.log("Fetching organization:", clientId);
        const response = await axios.get(`${BACKEND_URL}api/getNotifications`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
          params: { clientId: clientId }
        });
        localStorage.setItem("Isnotif",false)
        // const response = await axios.get(`${BACKEND_URL}api/getNotifications`,clientId, config);
        setNotifs(response.data);
        setCountNotifs(response.count)
        console.log("Data notifications :", response.data);
        } catch (error) {
          console.error("Error fetching data:", error.response ? error.response : error.message);
      }
  };
  const nbNotifs =notifs&&notifs.length;
  useEffect(() => {
      fetchNotif();
  }, [isnotif]); // Dependency array includes clientId

  const getNotificationLink = (status) => {
    switch (status) {
      case "Rejected":
        return "/List-rejection-queue";
      case "Staging":
        return "/List-approval-queue";
      case "Staging+":
        return "/List-approval-queue";
      case "Approved":
        return "/List-formula-template";
      default:
        return "/List-formula-template";
    }
  };

  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="dropdown d-inline-block"
        tag="li"
      >
        <DropdownToggle
          className="btn header-item noti-icon position-relative"
          tag="button"
          id="page-header-notifications-dropdown"
        >
          <i className="ri-notification-3-line" style={{ fontSize: '24px' }} />
          {isnotif && (
            <span className="position-absolute start-100 translate-middle badge rounded-pill bg-danger" style={{top:25,marginLeft:"-12px"}}>
              {countNotifs}
            </span>
          )}
        </DropdownToggle>

        <DropdownMenu className="dropdown-menu-lg dropdown-menu-end p-0">
          <div className="p-3">
            <Row className="align-items-center">
              <Col>
                <h6 className="m-0">{props.t("Notifications")} </h6>
              </Col>
              <div className="col-auto">
                <Link to="/#" className="small">
                  {" "}
                  View All
                </Link>
              </div>
            </Row>
          </div>

          <SimpleBar style={{ height: "230px" }}>
            {notifs && notifs.map((notif) => {
              // Determine the class based on the notification status
              let statusClass = "";
              switch (notif.status) {
                case "Rejected":
                  statusClass = "bg-danger";
                  break;
                case "Staging":
                  statusClass = "bg-info";
                  break;
                case "Staging+":
                  statusClass = "bg-warning";
                  break;
                case "Approved":
                  statusClass = "bg-success";
                  break;
                default:
                  statusClass = ""; // Default case if needed
              }

              return (
                notif.viewed ? (
                  <div className="text-reset notification-item" key={notif.id} style={{ cursor: 'not-allowed', opacity: 0.6 }}>
                    <div className="d-flex">
                      <div className="flex-shrink-0 me-3">
                        <div className="avatar-xs">
                          <span className={`avatar-title ${statusClass} rounded-circle font-size-16`}>
                            {notif.type === "formula" ? "MF" : "PS"}
                          </span>
                        </div>
                      </div>
                      <div className="flex-grow-1">
                        <h6 className="mb-1">
                        {notif.type === "formula"? "Master Formula":"Preparation Sheet"} is {notif.status === "Staging" ? "Created" : notif.status === "Staging+" ? "Corrected" : notif.status}
                        </h6>
                        <div className="font-size-12 text-muted">
                          <p className="mb-1">{notif.compoundingName} ({notif.compoundingRef})</p>
                          <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {timeAgo(notif.createdAt)}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                <Link to={getNotificationLink(notif.status)} className="text-reset notification-item" key={notif.id}>
                  <div className="d-flex">
                    <div className="flex-shrink-0 me-3">
                      <div className="avatar-xs">
                        <span className={`avatar-title ${statusClass} rounded-circle font-size-16`}>
                          {notif.type === "formula" ? "MF" : "PS"}
                        </span>
                      </div>
                    </div>
                    <div className="flex-grow-1">
                    <h6 className="mb-1">
                    {notif.type === "formula"? "Master Formula":"Preparation Sheet"} is {notif.status === "Staging" ? "Created" : notif.status === "Staging+" ? "Corrected" : notif.status}
                  </h6>
                      <div className="font-size-12 text-muted">
                        <p className="mb-1">{notif.compoundingName} ({notif.compoundingRef })</p>
                        <p className="mb-0"><i className="mdi mdi-clock-outline"></i> {timeAgo(notif.createdAt)}</p>
                      </div>
                    </div>
                  </div>
                </Link>  )
              );
                
            })}
          </SimpleBar>
          <div className="p-2 border-top d-grid">
            <Link className="btn btn-sm btn-link font-size-14 text-center" to="#">
              <i className="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more">{props.t("View More..")}</span>
            </Link>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

export default withTranslation()(NotificationDropdown);

NotificationDropdown.propTypes = {
  t: PropTypes.any
};