import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Modal, Input, ModalBody, Alert, ModalFooter, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Link } from 'react-router-dom';
import axios from 'axios';
import './style.css';
import { BACKEND_URL, } from "../../config/const.js";
import { useDataFetching } from "../../hooks/useDataFetching.js";

const ListPharmaceuticals = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [newPharmaceutical, setNewPharmaceutical] = useState({ nameEn: '' , clientId: '' });
    const [editedPharmaceutical, setEditedPharmaceutical] = useState({ nameEn: '',  });
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modal_standard, setmodal_standard] = useState(false);
    const [pharmaceuticalToDelete, setPharmaceuticalToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    const [update, setUpdate] = useState(false);
    const [clientId, setClientId] = useState(() => {
        return JSON.parse(localStorage.getItem("currentOrg"));
    });

    useEffect(() => {
        const handleStorageChange = () => {
            const newClientId = JSON.parse(localStorage.getItem("currentOrg"));
            if (newClientId !== clientId) {
                setClientId(newClientId);
            }
        };

        window.addEventListener('storage', handleStorageChange);

        return () => {
            window.removeEventListener('storage', handleStorageChange);
        };
    }, [clientId]);

    const config = {
        headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
    };

    const {
        data: pharmaceuticals,
        setData: setPharmaceuticals,
        isLoading,
        error,
        refetch: refetchPharmaceuticals
    } = useDataFetching(
        `${BACKEND_URL}api/pharmaceutical-formsbyclient/${clientId}`,
        clientId,
        config
    );

    useEffect(() => {
        if (clientId) {
            refetchPharmaceuticals();
        }
    }, [clientId]);

    function tog_standard() {
        setmodal_standard(!modal_standard);
    }
    const itemsPerPage = 20;


    console.log('Organization', clientId);

    const handleEditClick = (id) => {
        const pharmaceutical = pharmaceuticals.find(pharmaceutical => pharmaceutical.id === id);
        if (pharmaceutical) {
            setEditIndex(id); // Utilisez l'ID pour éditer
            setEditedPharmaceutical({ ...pharmaceutical });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedPharmaceutical(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Update pharmaceutical
    const handleSave = async (id) => {
        try {
            const updatedPharmaceutical = {
                id: editedPharmaceutical.id,
                nameEn: editedPharmaceutical.nameEn,
                clientId: clientId
            };

            const response = await axios.put(
                `${BACKEND_URL}api/pharmaceutical-forms/${updatedPharmaceutical.id}`, 
                updatedPharmaceutical, 
                config
            );

            if (response.code === 409) {
                setUpdate(true);
                setAlert('danger');
                setMessage('Error: Pharmaceutical with the same name already exists.');
            } else {
                // Mise à jour locale immédiate
                const updatedPharmaceuticals = pharmaceuticals.map(pharmaceutical =>
                    pharmaceutical.id === id ? response.data : pharmaceutical
                );
                setPharmaceuticals(updatedPharmaceuticals);
                refetchPharmaceuticals();
                setEditIndex(null);
            }

        } catch (error) {
            setUpdate(true);
            setAlert('danger');
            setMessage(error.response?.data?.message || 'Error updating pharmaceutical.');
            setTimeout(() => {
                setUpdate(false);
            }, 2000);
        }

        // Clear update status after delay
        setTimeout(() => {
            setUpdate(false);
        }, 2000);
    };

    const handleCancel = () => {
        setEditIndex(null);
    };

    // Delete pharmaceutical
    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/pharmaceutical-forms/${id}`, config);
            // Mise à jour locale immédiate
            const updatedPharmaceuticals = pharmaceuticals.filter(p => p.id !== id);
            setPharmaceuticals(updatedPharmaceuticals);
            setmodal_standard(false);
            setMessage('Pharmaceutical removed successfully...');
            setLoading(true);
            setAlert('warning');
            setTimeout(() => {
                setLoading(false);
            }, 3000);// Close the modal after deletion
        } catch (error) {
            console.error('Error deleting pharmaceutical:', error);
        }
    };

    // Add new pharmaceutical
    const handleAddPharmaceutical = async (e) => {
        e.preventDefault();
        try {
            const pharmaceuticalToCreate = { ...newPharmaceutical, clientId };
            const response = await axios.post(
                `${BACKEND_URL}api/pharmaceutical-forms`, 
                pharmaceuticalToCreate, 
                config
            );
            
            if (response.code === 409) {
                setLoading(true);
                setAlert('danger');
                setMessage('Error: Pharmaceutical with the same name already exists.');
            } else {
                await refetchPharmaceuticals();
                setmodal_list(false);
                setMessage('Pharmaceutical added successfully...');
                setLoading(true);
                setNewPharmaceutical({ nameEn: '', clientId: '' });
                setAlert('success');
            }
        } catch (error) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error adding existing pharmaceutical.')
            console.error('Error adding existing pharmaceutical:', error);
        } finally {
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds(prevSelectedIds =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };


    const filteredPharmaceuticals = pharmaceuticals && pharmaceuticals.filter(pharmaceutical => {
        if (!pharmaceutical) return false; // Ensure pharmaceutical is not null or undefined
        const nameEn = pharmaceutical.nameEn ? pharmaceutical.nameEn.toLowerCase() : '';
        return  nameEn.includes(searchTerm.toLowerCase());
    });


    const totalItems = filteredPharmaceuticals.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const displayedPharmaceuticals = filteredPharmaceuticals.slice((currentPage - 1) * itemsPerPage, currentPage * itemsPerPage);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const toggleModal = () => {
        setmodal_list(!modal_list);
        if (modal_list) {
            // Réinitialiser le formulaire lorsque le modal est fermé
            setNewPharmaceutical({ nameEn: '', clientId: '' });
            setAlert(null);
            setMessage(null);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Management" breadcrumbItem="List Pharmaceuticals" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => setmodal_list(true)} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Pharmaceutical
                                                    </Button>

                                                </div>
                                            </Col>



                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2" style={{ display: 'flex' }}>
                                                        <i className="ri-search-line search-icon mt-1 p-2"></i>
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">

                                            {loading && alert === "success" ? (<Alert color={alert}>{message}</Alert>) : null}
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        {/* <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input" type="checkbox" id="checkAll"  value="option"
                                                                    onChange={(e) => {
                                                                        const isChecked = e.target.checked;
                                                                        setSelectedIds(isChecked ? pharmaceuticals.map(i => i.id) : []);
                                                                    }}
                                                                    checked={selectedIds.length === pharmaceuticals.length && pharmaceuticals.length > 0}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="id">#ID</th> */}

                                                        <th className="sort" data-sort="nameEn">Pharmaceutical Name</th>
                                                    
                                                        <th className="sort" data-sort="action" justifyContent='right'>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {update && alert === "danger" ? (
                                                        <tr>
                                                            <td colSpan={5}><Alert color={alert}>{message}</Alert></td>
                                                        </tr>
                                                    ) : null}

                                                    {displayedPharmaceuticals && displayedPharmaceuticals.map((pharmaceutical) => (
                                                        <tr key={pharmaceutical.id}>
                                                            {/* <td scope="row">
                                                                <div className="form-check">
                                                                    <input  className="form-check-input"  type="checkbox"  name="chk_child"
                                                                        value="option1"  onChange={() => handleCheckboxChange(pharmaceutical.id)}
                                                                        checked={selectedIds.includes(pharmaceutical.id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="id">{pharmaceutical.id}</td> */}
                                                            <td className="name">
                                                                {editIndex === pharmaceutical.id ? (
                                                                    <Input  type="text"  name="nameEn"   value={editedPharmaceutical.nameEn}
                                                                        onChange={handleInputChange}   />
                                                                ) : (
                                                                    pharmaceutical.nameEn
                                                                )}
                                                            </td>
                                                        
                                                        
                                                            <td align='right'>
                                                                <div className="d-flex gap-2">
                                                                    {editIndex === pharmaceutical.id ? (
                                                                        <>
                                                                            <button className="btn btn-sm btn-success" onClick={() => handleSave(pharmaceutical.id)}>
                                                                                <i className="fas fa-save"></i>
                                                                            </button>
                                                                            <button className="btn btn-sm btn-danger" onClick={handleCancel}>
                                                                                <i className='dripicons-return'></i>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-outline-info waves-effect waves-light" onClick={() => handleEditClick(pharmaceutical.id)}>
                                                                                <i className="ri-pencil-fill align-bottom"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-danger waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    setPharmaceuticalToDelete(pharmaceutical);
                                                                                    setmodal_standard(true);
                                                                                }}
                                                                                disabled={pharmaceutical.isUsed}
                                                                            >
                                                                                <i className="ri-delete-bin-fill align-bottom"></i>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {totalItems === 0 && (
                                                <div className="noresult" style={{ padding: "30px 0" }}>
                                                    <div className="text-center">
                                                        {isLoading ? (
                                                            <div className="loading-container">
                                                                <div className="loading-spinner">
                                                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    <h5 className="mt-3 text-primary">Loading data...</h5>
                                                                    <p className="text-muted">Please wait while we fetch the information</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="no-result-container">
                                                            <h5 className="mt-3">Sorry! No Result Found</h5>
                                                                
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {totalItems > itemsPerPage && (
                                            <div className="d-flex justify-content-end mt-3">
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(totalPages)].map((_, pageIndex) => (
                                                            <li key={pageIndex} className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(pageIndex + 1)}  >
                                                                    {pageIndex + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*start model add*/}
            <Modal isOpen={modal_list} toggle={toggleModal} centered className="modal-lg"  >
                <ModalHeader>
                    Add New Pharmaceutical
                </ModalHeader>
                <ModalBody>
                    {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                    <form onSubmit={handleAddPharmaceutical}>

                        <div className="mb-3">
                            <label htmlFor="nameEn" className="form-label">Pharmaceutical Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="nameEn"
                                name="nameEn"
                                value={newPharmaceutical.nameEn}
                                onChange={(e) => setNewPharmaceutical({ ...newPharmaceutical, nameEn: e.target.value })}
                                required
                            />
                        </div>
                  
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={toggleModal}>Cancel</Button>
                            <Button type="submit" color="primary">Add Pharmaceutical</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>
            {/*end model add */}

            {/*start model delete */}
            <Modal isOpen={modal_standard} toggle={() => setmodal_standard(!modal_standard)} className="modal-dialog-centered">

                <CardBody>
                    <div className="text-center">
                        <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                        </div>
                        <h4 className="alert-heading">
                            Are you sure?
                        </h4>
                        <p className="mb-0">
                            you won't be able to revert this !
                            <br />   <br />
                        </p>
                    </div>
                </CardBody>

                <div className="modal-footer text-center " style={{ justifyContent: "center" }}>
                    <Button color="success" onClick={() => pharmaceuticalToDelete && handleRemove(pharmaceuticalToDelete.id)}>Yes, delete it !</Button>


                    <button type="button" className="btn btn-danger " onClick={() => { tog_standard(); }}  >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* end model delete */}
        </React.Fragment>
    );
};

export default ListPharmaceuticals;
