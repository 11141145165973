import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Modal, Input, ModalBody, Alert, ModalFooter, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios';
import './style.css';
import { BACKEND_URL } from "../../config/const.js";
import { useDataFetching } from "../../hooks/useDataFetching.js";

const ListManufacturers = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [newManufacturer, setNewManufacturer] = useState({ name: '', shortName: '', clientId: '' });
    const [editedManufacturer, setEditedManufacturer] = useState({ name: '', shortName: '' });
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modal_standard, setmodal_standard] = useState(false);
    const [manufacturerToDelete, setManufacturerToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    const [update, setUpdate] = useState(false);

    const itemsPerPage = 5;
    const clientId = JSON.parse(localStorage.getItem("currentOrg"));

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    // Utilisation du hook useDataFetching
    const {
        data: manufacturers,
        setData: setManufacturers,
        isLoading,
        error,
        refetch: refetchManufacturers
    } = useDataFetching(
        `${BACKEND_URL}api/manufacturersbyclient/${clientId}`,
        clientId,
        config
    );

    const handleEditClick = (id) => {
        const manufacturer = manufacturers.find(manufacturer => manufacturer.id === id);
        if (manufacturer) {
            setEditIndex(id);
            setEditedManufacturer({ ...manufacturer });
        }
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedManufacturer(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    const handleSave = async (id) => {
        try {
            const updatedManufacturer = {
                id: editedManufacturer.id,
                name: editedManufacturer.name,
                shortName: editedManufacturer.shortName,
                clientId: clientId
            };

            const response = await axios.put(
                `${BACKEND_URL}api/manufacturers/${updatedManufacturer.id}`,
                updatedManufacturer,
                config
            );

            if (response.data.code === 409) {
                setUpdate(true);
                setAlert('danger');
                setMessage('Error: Manufacturer with the same name already exists.');
            } else {
                const updatedManufacturers = manufacturers.map(manufacturer =>
                    manufacturer.id === id ? response.data : manufacturer
                );
                setManufacturers(updatedManufacturers);
                setEditIndex(null);
            }
        } catch (error) {
            setUpdate(true);
            setAlert('danger');
            setMessage(error.response?.data?.message || 'Error updating manufacturer.');
            setTimeout(() => {
                setUpdate(false);
            }, 2000);
        }
    };

    const handleAddManufacturer = async (e) => {
        e.preventDefault();
        try {
            const manufacturerToCreate = { ...newManufacturer, clientId: clientId };
            const response = await axios.post(`${BACKEND_URL}api/manufacturers`, manufacturerToCreate, config);

            if (response.data.code === 409) {
                setLoading(true);
                setAlert('danger');
                setMessage('Error: Manufacturer with the same name already exists.');
            } else {
                setManufacturers([...manufacturers, response.data]);
                setmodal_list(false);
                setMessage('Manufacturer added successfully...');
                setLoading(true);
                setAlert('success');
                refetchManufacturers(); // Utilisation de refetch au lieu de fetchManufacturers
            }
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error adding new manufacturer.');
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/manufacturers/${id}`, config);
            const updatedManufacturers = manufacturers.filter(manufacturer => manufacturer.id !== id);
            setManufacturers(updatedManufacturers);
            setmodal_standard(false);
            setMessage('Manufacturer removed successfully...');
            setLoading(true);
            setAlert('warning');
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            console.error('Error deleting manufacturer:', error);
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
    };

    const handleCheckboxChange = (id) => {
        setSelectedIds(prevSelectedIds =>
            prevSelectedIds.includes(id)
                ? prevSelectedIds.filter(selectedId => selectedId !== id)
                : [...prevSelectedIds, id]
        );
    };

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Filtrage et pagination
    const filteredManufacturers = manufacturers ? manufacturers.filter(manufacturer => {
        if (!manufacturer) return false;
        const shortName = manufacturer.shortName ? manufacturer.shortName.toLowerCase() : '';
        const name = manufacturer.name ? manufacturer.name.toLowerCase() : '';
        return shortName.includes(searchTerm.toLowerCase()) || name.includes(searchTerm.toLowerCase());
    }) : [];

    const totalItems = filteredManufacturers.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredManufacturers.slice(indexOfFirstItem, indexOfLastItem);

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Management" breadcrumbItem="List Manufacturers" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => setmodal_list(true)} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Manufacturer
                                                    </Button>

                                                </div>
                                            </Col>



                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2" style={{ display: 'flex' }}>
                                                        <i className="ri-search-line search-icon mt-1 p-2"></i>
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">

                                            {loading && alert === "success" ? (<Alert color={alert}>{message}</Alert>) : null}
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                    onChange={(e) => {
                                                                        const isChecked = e.target.checked;
                                                                        setSelectedIds(isChecked ? manufacturers.map(i => i.id) : []);
                                                                    }}
                                                                    checked={selectedIds.length === manufacturers.length && manufacturers.length > 0}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="id">#ID</th>

                                                        <th className="sort" data-sort="name">Manufacturer Name</th>
                                                        <th className="sort" data-sort="shortName">Short Name</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {update && alert === "danger" ? (
                                                        <tr>
                                                            <td colSpan={5}><Alert color={alert}>{message}</Alert></td>
                                                        </tr>
                                                    ) : null}

                                                    {currentItems && currentItems.map((manufacturer) => (
                                                        <tr key={manufacturer.id}>
                                                            <td scope="row">
                                                                <div className="form-check">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="checkbox"
                                                                        name="chk_child"
                                                                        value="option1"
                                                                        onChange={() => handleCheckboxChange(manufacturer.id)}
                                                                        checked={selectedIds.includes(manufacturer.id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="id">{manufacturer.id}</td>
                                                            <td className="shortName">
                                                                {editIndex === manufacturer.id ? (
                                                                    <Input
                                                                        type="text"
                                                                        name="shortName"
                                                                        value={editedManufacturer.shortName}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                ) : (
                                                                    manufacturer.shortName
                                                                )}
                                                            </td>
                                                            <td className="name">
                                                                {editIndex === manufacturer.id ? (
                                                                    <Input
                                                                        type="text"
                                                                        name="name"
                                                                        value={editedManufacturer.name}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                ) : (
                                                                    manufacturer.name
                                                                )}
                                                            </td>
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {editIndex === manufacturer.id ? (
                                                                        <>
                                                                            <button className="btn btn-sm btn-success" onClick={() => handleSave(manufacturer.id)}>
                                                                                <i className="fas fa-save"></i>
                                                                            </button>
                                                                            <button className="btn btn-sm btn-danger" onClick={handleCancel}>
                                                                                <i className='dripicons-return'></i>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-outline-info waves-effect waves-light" onClick={() => handleEditClick(manufacturer.id)}>
                                                                                <i className="ri-pencil-fill align-bottom"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-danger waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    setManufacturerToDelete(manufacturer);
                                                                                    setmodal_standard(true);
                                                                                }}
                                                                                disabled={manufacturer.isUsed}
                                                                            >
                                                                                <i className="ri-delete-bin-fill align-bottom"></i>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {totalItems === 0 && (
                                                <div className="noresult" style={{ padding: "30px 0" }}>
                                                    <div className="text-center">
                                                        {isLoading ? (
                                                            <div className="loading-container">
                                                                <div className="loading-spinner">
                                                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    <h5 className="mt-3 text-primary">Loading data...</h5>
                                                                    <p className="text-muted">Please wait while we fetch the information</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="no-result-container">
                                                   
                                                                <h5 className="mt-3">Sorry! No Result Found</h5>
                                                          
                                                                
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {totalItems > itemsPerPage && (
                                            <div className="d-flex justify-content-end mt-3">
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(totalPages)].map((_, pageIndex) => (
                                                            <li key={pageIndex} className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(pageIndex + 1)}  >
                                                                    {pageIndex + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*start model add*/}
            <Modal isOpen={modal_list} toggle={() => { setmodal_list(!modal_list); }} centered className="modal-lg"  >
                <ModalHeader>
                    Add New Manufacturer
                </ModalHeader>
                <ModalBody>
                    {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                    <form onSubmit={handleAddManufacturer}>

                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={newManufacturer.name}
                                onChange={(e) => setNewManufacturer({ ...newManufacturer, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="shortName" className="form-label">shortName</label>
                            <input
                                type="text"
                                className="form-control"
                                id="shortName"
                                name="shortName"
                                value={newManufacturer.shortName}
                                onChange={(e) => setNewManufacturer({ ...newManufacturer, shortName: e.target.value })}
                                required
                            />
                        </div>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={() => setmodal_list(false)}>Cancel</Button>
                            <Button type="submit" color="primary">Add Manufacturer</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>
            {/*end model add */}

            {/*start model delete */}
            <Modal isOpen={modal_standard} toggle={() => setmodal_standard(!modal_standard)} className="modal-dialog-centered">

                <CardBody>
                    <div className="text-center">
                        <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                        </div>
                        <h4 className="alert-heading">
                            Are you sure?
                        </h4>
                        <p className="mb-0">
                            you wont be able to revert this !
                            <br />   <br />
                        </p>
                    </div>
                </CardBody>

                <div className="modal-footer text-center " style={{ justifyContent: "center" }}>
                    <Button color="success" onClick={() => manufacturerToDelete && handleRemove(manufacturerToDelete.id)}>Yes, delete it !</Button>


                    <button type="button" className="btn btn-danger " onClick={() => { setmodal_standard(!modal_standard); }}  >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* end model delete */}
        </React.Fragment>
    );
};

export default ListManufacturers;
