import React, { useState, useEffect } from "react";
import Creatable from 'react-select/creatable';
import { Card, CardBody, Form, Col, Row,Label, Container, Alert } from "reactstrap";
import logobce from "../../../assets/images/logo_bce.png";
import logoorganization from "../../../assets/images/organization_logo.png";
import axios from 'axios';
import '../style.css';
import Loader from '../Loader.js';
import { BACKEND_URL } from "../../../config/const.js";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { EditorState, convertToRaw ,ContentState} from 'draft-js';
import draftToHtml from 'draftjs-to-html';
// import { EditorState, ContentState } from 'draft-js';
import { convertFromHTML } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
const UpdateFormula = () => {
  const { id } = useParams();
  document.title = "Create New Template | Quess Magistrale";
  const navigate = useNavigate();
  const [productNames, setProductNames] = useState([{ id: 1, productName: '', concentration: '', symbole: '' }]);
  const [authorizeds, setAuthorizeds] = useState([{ id: 1, userId: null }]);
  const [equipmentRows, setEquipmentRows] = useState([{ id: 1, name: '' }]);
  const [formulaRows, setFormulaRows] = useState([{ id: 1, name: '', manufacture: '', quantity: 0, unit: '', qteRequired: 0, lotNumber: '', expDate: '' }]);
  //const [equipmentRows, setEquipmentRows] = useState([{ id: 1 }]);
  const [otherRows, setOtherRows] = useState([{ id: 1 }]);
  const [other, setOther] = useState([{ id: 1 }]);
  const [formulaBatch, setFormulaBatch] = useState('');
  const [desiredBatch, setDesiredBatch] = useState('');
  const [unitFormula, setUnitFormula] = useState('');
  const [unitDesired, setUnitDesired] = useState('');
  //const [columns, setColumns] = useState(['Quantity']);
  const [isColumnAdded, setIsColumnAdded] = useState(false);
  const [columns, setColumns] = useState([{ name: 'quantity' }]); // Initial column names
  const [isChecked, setIsChecked] = useState(false);
  const [clientId, setClientId] = useState(null);
  const [equipements, setEquipements] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [pharmaceuticals, setPharmaceuticals] = useState([]);
  const [units, setUnits] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);
  const [formulaDetail, setFormulaDetail] = useState(null);
  const [isEdit, setIsEdit] = useState(false);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    const client = JSON.parse(localStorage.getItem("currentOrg"));
    const logoOrg = localStorage.getItem("logoOrg"); // Assuming logoOrg is just a URL string
  
    if (logoOrg) {
      setLogo(`${BACKEND_URL}${logoOrg}`);// Ensure this constructs a valid URL
    }
  if (client) {
      setClientId(client);
  }
  }, [clientId, logo]);
  useEffect(() => {
    fetchUnits();
    fetchEquipements();
    fetchIngredients();
    fetchPharmaceuticals();
    fetchRoutes();
    fetchSuppliers();
    fetchUsers();
  }, [clientId]);

  const fetchFormulaDetail = async () => {
    setLoading(true); // Set loading to true before the request starts
    try {
      const response = await axios.get(`${BACKEND_URL}api/template/${id}`, config);
      const data = response; // Extract data from the response object
      console.log('data Formula:',data);
      setFormulaDetail(data);
      setFormulaRows(data.ingredients || []); 
      setEquipmentRows(data.equipements) ;
      setFormulaBatch(data.formulaBatchSize);
      setDesiredBatch(data.desiredBatchSize);
      setUnitFormula(data.formulaBtachSizeUnit);
      setUnitDesired(data.desiredBtachSizeUnit);
      const updatedAuthorizeds = data.approvedBy.map((user, index) => ({
        id: index + 1, // Assuming you want id to start from 1
        userId: user.id, // Setting userId to the id from approvedBy
      }));
      setAuthorizeds(updatedAuthorizeds);// Safely handle ingredients if available
      console.log('Formula Detail:', data);
      updateDescriptionsFromData(data);
    } catch (error) {
      console.error('Error fetching sheet detail:', error);
    } finally {
      setLoading(false); // Turn off the loading state after the request completes
    }
  };
  const updateDescriptionsFromData = (data) => {
    setActivite((prevActivite) =>
      prevActivite.map((act) => {
        let newDescription = '';
        if (act.name === 'training' && data.training) {
          newDescription = data.training;
        } else if (act.name === 'packaging' && data.packaging) {
          newDescription = data.packaging;
        } else if (act.name === 'ingredientsInfo' && data.ingredientsInfo) {
          newDescription = data.ingredientsInfo;
        } else if (act.name === 'calculations' && data.calculations) {
          newDescription = data.calculations;
        } else if (act.name === 'compoundingMethod' && data.compoundingMethod) {
          newDescription = data.compoundingMethod;
        } else if (act.name === 'qualityControl' && data.qualityControl) {
          newDescription = data.qualityControl;
        } else if (act.name === 'stabilityAndStorage' && data.stabilityAndStorage) {
          newDescription = data.stabilityAndStorage;
        } else if (act.name === 'labelling' && data.labelling) {
          newDescription = data.labelling;
        } else if (act.name === 'refs' && data.refs) {
          newDescription = data.refs;
        }
  
        // Convertir la description HTML en EditorState si elle n'est pas vide
        const contentState = newDescription
          ? ContentState.createFromBlockArray(convertFromHTML(newDescription))
          : ContentState.createFromText(''); // Si vide, laisser vide
  
        return {
          ...act,
          description: newDescription, // Met à jour la description
          editorState: EditorState.createWithContent(contentState), // Met à jour l'état de l'éditeur avec le contenu
        };
      })
    );
  };
  useEffect(() => {
    if (id) {
      fetchFormulaDetail();  // Only fetch details if an ID is present
    }
  }, [id]);
  const fetchEquipements = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/equipementsbyclient/${clientId}`, config);
        console.log('res', response);
        setEquipements(response.data);
      }
    } catch (error) {
      console.error('Error fetching equipment:', error);
    }
  };

  const fetchIngredients = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/ingredientsbyclient/${clientId}`, config);
        console.log('ingredients:', response);
        setIngredients(response.data);
      }
    } catch (error) {
      console.error('Error fetching ingredient:', error);
    }
  };

  const fetchPharmaceuticals = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/pharmaceutical-formsbyclient/${clientId}`, config);
        console.log('pharmaceuticals:', response);
        setPharmaceuticals(response.data);
      }
    } catch (error) {
      console.error('Error fetching pharmaceutical:', error);
    }
  };


  const fetchRoutes = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/route-administrationsbyclient/${clientId}`, config);
       
       // console.log('routes:', response.data);
        setRoutes(response.data);
      }

    } catch (error) {
      console.error('Error fetching route:', error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/manufacturersbyclient/${clientId}`, config);
        console.log('suppliers:', response);
        setSuppliers(response.data);
      }
    } catch (error) {
      console.error('Error fetching supplier:', error);
    }
  };

  const fetchUnits = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/unitsbyclient/${clientId}`, config);
        console.log('units:', response);
        setUnits(response.data);
      }
    } catch (error) {
      console.error('Error fetching unit:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/getGroupUsers/1`, config);
        console.log('users:', response);
        setUsers(response.data);
      }
    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({ data: { link: reader.result } });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }
    );
  };

  const [activite, setActivite] = useState([
    { id: 1, title: "Additional information on ingredients", name: "ingredientsInfo", description: "", editorState: EditorState.createEmpty(), },
    { id: 2, title: "Calculations and Measurements", name: "calculations", description: "", editorState: EditorState.createEmpty(), },
    { id: 3, title: "Compounding Method", name: "compoundingMethod", description: "", editorState: EditorState.createEmpty(), },
    { id: 4, title: "Quality Control", name: "qualityControl", description: "", editorState: EditorState.createEmpty(), },
    { id: 5, title: "Packaging", name: "packaging", description: "", editorState: EditorState.createEmpty(), },
    { id: 6, title: "Stability and Storage", name: "stabilityAndStorage", description: "", editorState: EditorState.createEmpty(), },
    { id: 7, title: "Labellingg", name: "labelling", description: "", editorState: EditorState.createEmpty(), },
    { id: 8, title: "Training", name: "training", description: "", editorState: EditorState.createEmpty(), },
    { id: 9, title: "References Consulted", name: "refs", description: "", editorState: EditorState.createEmpty(), },
  ]);


  const handleFormulaBatchChange = (e) => {
    setFormulaBatch(e.target.value);
    setDesiredBatch(e.target.value); // Update desiredBatch in real-time
  };

  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
  };

  const handleUnitFormulaChange = (e) => {
    setUnitFormula(e.target.value);
    setUnitDesired(e.target.value); // Update unitDesired in real-time
  };

  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);

  };
  

  // Handle input change

  const handleUserChange = (e, id) => {
    const selectedUserId = e.target.value; // Get the selected user ID
    setAuthorizeds(authorizeds.map(authorized =>
      authorized.id === id ? { ...authorized, userId: selectedUserId } : authorized
    ));
  };
  const getUserIdsString = () => {
    return authorizeds.map(authorized => authorized.userId).join(', ');
  };
console.log('authorized:',authorizeds)
  // Get the comma-separated string of user IDs
  const userIds = getUserIdsString();
  console.log('authorized:',userIds)
  // Add a new row
  const addAuthorizedRow = () => {
    setAuthorizeds([...authorizeds, { id: authorizeds.length + 1, userId: '' }]);
  };

  // Remove a row
  const removeAuthorizedRow = (id) => {
    setAuthorizeds(authorizeds.filter(authorized => authorized.id !== id));
  };
  const addFormulaRow = () => {
    setFormulaRows([...formulaRows, { id: formulaRows.length + 1 }]);
  };
  const removeFormulaRow = (id) => {
    setFormulaRows(formulaRows.filter(formula => formula.id !== id));
  };
  const handleFormulaChange = (id, field, value) => {
    const updatedFormulaRows = formulaRows.map((formula) => {
      if (formula.id === id) {
        return { ...formula, [field]: value };  // Mise à jour du champ modifié
      }
      return formula;
    });
    setFormulaRows(updatedFormulaRows);  // Mise à jour de l'état avec la nouvelle valeur
  };
  const addOtherRow = () => {
    setOtherRows([...otherRows, { id: otherRows.length + 1 }]);
  };
  const removeOtherRow = (id) => {
    setOtherRows(otherRows.filter(other => other.id !== id));
  };
  const removeActivite = (id) => {
    setActivite(activite.filter((activite) => activite.id !== id));
  };






  const addColumn = () => {
    setColumns([...columns, <input className="form-control" type="text" placeholder="new column" />]);
    setIsColumnAdded(true);
  };

  const removeColumn = () => {
    setColumns(columns.slice(0, -1));
    setIsColumnAdded(false);
  };
  const handleColumnNameChange = (index, value) => {
    // Mettre à jour le nom de la colonne à l'index spécifié
    setColumns(columns.map((col, i) =>
      i === index ? { ...col, name: value } : col
    ));
  };
  const handleEquipementChange = (id, field, value) => {
    const updatedRows = equipmentRows.map(equip => {
      if (equip.id === id) {
        return {
          ...equip,
          [field]: value // Mettre à jour le champ modifié
        };
      }
      return equip;
    });
    setEquipmentRows(updatedRows); // Mise à jour de l'état
  };

  // Function to add a new row
  const addEquipmentRow = () => {
    setEquipmentRows([
      ...equipmentRows,
      { id: equipmentRows.length + 1, name: '', quantity: 0 }
    ]);
  };

  // Function to remove a row by id
  const removeEquipmentRow = (id) => {
    setEquipmentRows(equipmentRows.filter(row => row.id !== id));
  };
  const [formData, setFormData] = useState({
    productName: '',
    routeOfAdministration: '',
    pharmaForm: '',
    effectiveDate: '',
    version:''
  });

  // Update formData when formulaDetail changes
  useEffect(() => {
    if (formulaDetail) {
      const formattedEffectiveDate = formulaDetail.effectiveDate 
        ? formulaDetail.effectiveDate.split(' ')[0] 
        : '';

      setFormData({
        routeOfAdministration: formulaDetail.routeOfAdministration || '',
        pharmaForm: formulaDetail.pharmaForm || '',
        effectiveDate: formattedEffectiveDate,
        version: formulaDetail.version || '',
      });
    }
  }, [formulaDetail]);

  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };



  const handleDescriptionChange = (id, editorState) => {
    const newDescription = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    setActivite((prevActivite) =>
      prevActivite.map((act) =>
        act.id === id
          ? {
            ...act,
            editorState, // Mise à jour de l'état de l'éditeur pour que le texte soit modifiable
            description: newDescription.trim() === "" ? "" : newDescription, // Si vide, définir comme ""
          }
          : act
      )
    );
  };



  const activiteData = activite.reduce((acc, act) => {
    acc[act.name] = act.description;
    return acc;
  }, {});
  console.log('activite', activiteData);
  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
    if (e.target.checked) {
      const scal = formulaBatch / desiredBatch; // Calculate the scale once checkbox is checked

      const updatedFormulaRows = formulaRows.map((formula) => ({
        ...formula,
        qteRequired: formula.quantity * scal, // Update the Quantity Required for each row
      }));

      setFormulaRows(updatedFormulaRows); // Update state with the new values
    }
  };
  useEffect(() => {
    if (isChecked && formulaBatch > 0 && desiredBatch > 0) {
      const scale = formulaBatch / desiredBatch;

      const updatedFormulaRows = formulaRows.map((formula) => ({
        ...formula,
        qteRequired: formula.quantity * scale // Mise à jour de Quantity Required avec le scale
      }));

      setFormulaRows(updatedFormulaRows);
    }
  }, [isChecked, formulaBatch, desiredBatch, formulaRows]);



  const handleSubmit = (e) => {
    e.preventDefault();

    const templateData = {
      id:formulaDetail.id,
      category: formulaDetail.category,
      pharmaForm: formData.pharmaForm,
      routeOfAdministration: formData.routeOfAdministration,
      effectiveDate: formData.effectiveDate,
      formulaBatchSize: parseInt(formulaBatch, 10), // 10 pour base décimale
      desiredBatchSize: parseInt(desiredBatch, 10),
      formulaBtachSizeUnit: unitFormula,
      desiredBtachSizeUnit: unitDesired,
      productName: formData.productName,
      preparedBy: (Array.isArray(formulaDetail.preparedBy) && formulaDetail.preparedBy.length > 0)   ? formulaDetail.preparedBy : "",
      ingredients: formulaRows,
      equipements: equipmentRows,
      otherRows: otherRows,
      clientId: clientId,
      columns: columns,
      approvedBy: userIds,
      status: formulaDetail.status,
      lotNumber:formulaDetail.lotNumber||'',
      protocolNumber: formulaDetail.protocolNumber||'',
      parentId: formulaDetail.protocolNumber||'',
      version:formData.version,
      checkedBy:  (Array.isArray(formulaDetail.checkedBy) && formulaDetail.checkedBy.length > 0)   ? formulaDetail.checkedBy : "",
      activite: activiteData,
      ...activiteData,
    };


    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
   
    axios.put(`${BACKEND_URL}api/templates/update`, templateData, config)
    .then((response) => {
      console.log(response);
  
      setMessage('Master Formula updated successfully...');
      setAlert('success');
      setLoading(true);
  
      // Afficher le message pendant 3 secondes avant de naviguer
      setTimeout(() => {
        setLoading(false);
        navigate('/List-formula-template'); // Naviguer après 3 secondes
      }, 3000); // 3000 ms = 3 secondes
    })
      .catch((error) => {
        if (error === "Request failed with status code 400")  {
        
          // Handle other types of errors
          setLoading(true);
          setAlert('danger');
          setMessage('Error updated Formula.');
      }            
      // Log the error for debugging
      console.error('Error  updated Formula:', error);
      setMessage('Error updated Formula.');
      setTimeout(() => {
          setLoading(false);
      }, 2000);
      });
  };

  const goBack = () => {
    navigate("/List-formula-template"); // Redirect after successful creation
  }
  
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Master Formula" breadcrumbItem="Update Master Formula" />
          <div className="row-form mb-4">
  
          {formulaDetail?
            <Form onSubmit={handleSubmit}>
      
            <Row className="mb-3 mt-2">
                      <Col md={2}>
                        <img  src={logobce}  alt="BCE Logo"  height="24" className="auth-logo logo-dark mx-auto"  />
                      </Col>
                      <Col md={8} className="text-center">
                        <Label className="col-form-label">Compounded {formulaDetail.category === 1 ? "Sterile":"Non Sterile"} Preparation Protocol</Label>                       
                        <p> <span className="float-start"></span> <span > Version: {formulaDetail.version}</span></p>                                
                        <div className="col-md-12 text-center mb-2">
                          <p className="p-card" >
                            <Row>
                            <Col md={10} className="text-right">
                            <h5 className={isEdit?"opacity":""}>{formulaDetail.productName}</h5> </Col>
                            <Col md={2} className="text-left"> <button className={isEdit?"opacity btn btn-primary btn-edit":"btn btn-primary btn-edit waves-effect waves-light"} type="button"  onClick={() => { setIsEdit(!isEdit);}}>
                            <i className="mdi mdi-eyedropper-minus"></i>  </button> </Col>
                            </Row>
                          </p> 
                          <br></br>
                               {/* <span className="bg-success p-3 text-white">STATUS: {formulaDetail.status}</span> */}
                        </div>
                      </Col>
                      <Col md={2}>
                  <img  src={logo?logo:logoorganization} alt="Logo Organization" height="40" className="logo-organization" />
                      </Col>
                    </Row>
              <Row>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Header</h5>
                    <div className="text-center"> </div>
                    <CardBody>
                    

                        {isEdit && (
                          <Row id="row-productName" className="mt-3">
                            <Col lg={12}>
                              <div >
                              <input type="text" className="form-control" name="productName" value={formulaDetail.productName} onChange={handleFormChange} />
                              </div>

                            </Col>
                       
                          </Row>
                        )}
                     
                            
                      <Row className="mt-3">
                        <Col lg={4}>

                          <div>
                            <label htmlFor="nameFr" className="form-label">Pharmaceutical form:</label>
                            <select className="form-select" name="pharmaForm" value={formData.pharmaForm}
                              onChange={handleFormChange} required>
                     
                              {pharmaceuticals.map((pha) => (<option value={pha.nameEn}>{pha.nameEn}</option>))}
                            </select>
                          </div>
                        </Col>
                        <Col lg={4}>

                          <div>
                            <label htmlFor="nameFr" className="form-label">Route Of Administration:</label>
                            <select className="form-select" name="pharmaForm" value={formData.routeOfAdministration}
                              onChange={handleFormChange} required>
                     
                              {routes.map((pha) => (<option value={pha.name}>{pha.name}</option>))}
                            </select>
                       
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="row">
                            <label htmlFor="example-date-input" className="form-label">Effective Date:</label>
                            <input className="form-control" type="date" name="effectiveDate" value={formData.effectiveDate}
                              onChange={handleFormChange} required />

                          </div>
                        </Col>
                      </Row>
                      <div className="mt-3" >
                        <label className="form-label">Authorized By:</label>
                        {authorizeds.map((authorized) => (
                          <Row id="row-authorized" key={authorized.id} className="mb-3">
                            <Col lg={11}>
                              <div>

                                <select  className="form-select"   id={`nameFr-${authorized.id}`}
                                  value={authorized.userId || ''}  onChange={(e) => handleUserChange(e, authorized.id)}  required>
                                  <option value="">Select User</option>
                                  {users.map((user) => (
                                    <option value={user.idUser}>
                                      {user.firstName} {user.lastName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col lg={1}>
                              <div>
                                {authorized.id === 1 ? (
                                  <button className="btn btn-primary waves-effect waves-light" type="button" onClick={addAuthorizedRow}>
                                    <i className="fa fa-plus" />  </button>
                                ) : (
                                  <button className="btn btn-danger waves-effect waves-light" type="button" onClick={() => removeAuthorizedRow(authorized.id)}>
                                    <i className="fa fa-minus" />   </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>

                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Formula</h5>
                    <CardBody>
                      {/* <div className="form-check form-check-end">
                        <input  className="form-check-input"  type="checkbox"  id="checked"   name="isChecked"   onChange={handleCheckboxChange} // Corrected onChange handler
                        />
                        <label className="form-check-label" htmlFor="defaultCheck2">Batch quantity auto calculate</label>
                      </div> */}
                      <Row className="mt-3">
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="formula_batch" className="col-md-8 col-form-label">Formula Batch Size:</label>
                            <div className="col-md-4">
                              <input id="formula_batch" className="form-control" type="text" defaultValue={formulaBatch}  
                                onChange={handleFormulaBatchChange} name="formula_batch" required />
                            </div>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <select className="form-select" name="unit_formula" defaultValue={unitFormula}   onChange={handleUnitFormulaChange}>

                              {units.map((item) => (<option value={item.shorthand}>{item.shorthand}</option>))}
                            </select>
                          </div>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="desired_batch" className="col-md-8 col-form-label">Desired Batch Size:</label>
                            <div className="col-md-4">
                              <input id="desired_batch" className="form-control" type="text" defaultValue={desiredBatch}
                                onChange={handleDesiredBatchChange} name="desired_batch" />
                            </div>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <select className="form-select" name="unit_desired"  defaultValue={unitDesired} onChange={handleUnitDesiredChange} >

                              {units.map((item) => (<option value={item.shorthand}>{item.shorthand}</option>))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="formula">  
                                <td className="th-blue">Ingredients</td>  <td className="th-blue">Manufacture</td>  <td className="th-blue">Lot Number</td>
                                <td className="th-blue">Exp Date</td>  <td className="th-blue">Quantity</td>
                                <td className="th-blue">Units</td>  <td className="th-blue">Quantity Required</td> <td className="th-blue">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {formulaRows.map((formula) => (
                                <tr key={formula.id}>
                                  <td>
                                    <select  className="form-select"  name="ingredientName"  value={formula.name}  onChange={(e) => handleFormulaChange(formula.id, 'name', e.target.value)} required  >
                                    {ingredients.map((item) => (
                                        <option key={item.id} value={item.drugName}>
                                          {item.drugName}  {item.drugForm} {item.concentration} {item.size}
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td >       <select className="form-select" name="manufacture" value={formula.manufacture}
                                    onChange={(e) => handleFormulaChange(formula.id, 'manufacture', e.target.value)} required  >
                                      {suppliers.map((item) => ( <option key={item.id} value={item.shortName}>   {item.name} ( {item.shortName}) </option>   ))}
                                  </select>
                                  </td>
                                  <td className="td-disabled">{formula.lotNumber}</td>  <td className="td-disabled">{formula.expDate}</td>
                                  <td>
                                    <input className="form-control" type="number" name="quantityIngredient" value={formula.quantity}
                                      placeholder="1" onChange={(e) => handleFormulaChange(formula.id, 'quantity', e.target.value)} required />
                                  </td>
                                  <td>
                                    <select  className="form-select"  name="unitIngredient"  value={formula.unit}
                                      onChange={(e) => handleFormulaChange(formula.id, 'unit', e.target.value)} required  >
                                      <option>Units</option>
                                      {units.map((item) => (  <option key={item.id} value={item.shorthand}>{item.shorthand}</option>  ))}
                                    </select>
                                  </td>
                                  <td>
                                    <input className="form-control"  type="number"   placeholder="20"  name="qteRequired"  value={parseFloat(formula.qteRequired).toFixed(0)} // Limit to 3 decimal places
                                      onChange={(e) => {  const value = parseFloat(e.target.value);
                                        if (!isNaN(value)) {   handleFormulaChange(formula.id, "qteRequired", value.toFixed(3));   }
                                      }}  required  disabled={isChecked ? true : false}   />
                                  </td>
                                  <td>
                                    {formula.id === 1 ? (  <button  className="btn btn-primary waves-effect waves-light"   type="button"  onClick={addFormulaRow}  > <i className="fa fa-plus" /> </button>
                                    ) : ( <button  className="btn btn-danger waves-effect waves-light"   type="button"  onClick={() => removeFormulaRow(formula.id)} > <i className="fa fa-minus" />  </button>)}
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Required equipment/materials</h5>
                    <CardBody>
                      <p className="card-title-desc">Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="Equipement">
                                <td className="th-blue">Equipment/Materials</td>

                                <td className="th-blue">Quantity</td>
                                <td className="th-blue">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                              {equipmentRows.map((equipement) => (
                                <tr key={equipement.id}>
                                  <td>
                                    <select  className="form-select"  value={equipement.name}  onChange={(e) => handleEquipementChange(equipement.id, 'name', e.target.value)}   >
                                      <option>select equipment</option> 
                                       {equipements.map((item) => (      <option key={item.id} value={item.itemName}>
                                      {item.itemName}  {item.unit}  {item.size}
                                    </option>  ))}
                                    </select>
                                  </td>
                                  {columns.map(( index) => (
                                    <td key={index}>
                                      <input  className="form-control"  type="number"  defaultValue={equipement.quantity}
                                        onChange={(e) => handleEquipementChange(equipement.id, 'quantity', e.target.value)}
                                      />
                                    </td>
                                  ))}
                                  <td className="text-end">
                                    <button  className={`btn ${equipement.id === 1 ? 'btn-primary' : 'btn-danger'} waves-effect waves-light`}  type="button"   onClick={equipement.id === 1 ? addEquipmentRow : () => removeEquipmentRow(equipement.id)}  >
                                      <i className={`fa fa-${equipement.id === 1 ? 'plus' : 'minus'}`} />
                                    </button>
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>

                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {activite.map((item) => (
                  <Col lg={12} key={item.id}>
                    <Card className="card-bordred">
                      <h5 className="card-header">
                        <Row>
                          <Col md={11}>{item.title}</Col>
                          <Col md={1}>
                            <button  className="btn btn-danger waves-effect waves-light float-end"  type="button"  onClick={() => removeActivite(item.id)} >
                              <i className="fa fa-minus" /> </button>
                          </Col>
                        </Row>
                      </h5>
                      <CardBody style={{ background: "#fafafa" }}>
                        <Row>
                          <Col md={12}>
                          <Editor  editorState={item.editorState}  onEditorStateChange={(editorState) => handleDescriptionChange(item.id, editorState)}
                            toolbar={{
                              options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'image', 'emoji', 'list', 'textAlign', 'link'],
                              inline: { inDropdown: true },  color: true,  list: { inDropdown: true },  textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              image: {  uploadEnabled: true,  uploadCallback: uploadImageCallBack,   previewImage: true, alt: { present: true, mandatory: false } },
                            }}  />
                            
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
                   {/* 
                    {otherRows.map((other) => (
                      <Col lg={12} key={other.id}>
                        <Card className="card-bordred">
                          <h5 className="card-header">
                            <Row>
                              <Col md={11}>
                                <input className="form-control" type="text" placeholder="Title" />
                              </Col>
                              <Col md={1}>
                                <button className={`btn ${other.id === 1 ? 'btn-primary' : 'btn-danger'} waves-effect waves-light float-end`} type="button" onClick={other.id === 1 ? addOtherRow : () => removeOtherRow(other.id)}>
                                  <i className={`fa fa-${other.id === 1 ? 'plus' : 'minus'}`} />
                                </button>
                              </Col>
                            </Row>
                          </h5>
                          <CardBody style={{ background: "#fafafa" }}>
                            <Row>
                              <Col md={12}>
                                <Editor toolbarClassName="toolbarClassName" wrapperClassName="wrapperClassName" editorClassName="editorClassName" placeholder="Start From Here..." editorStyle={{ minHeight: '120px', background: "white" }} />
                              </Col>
                            </Row>
                          </CardBody>
                        </Card>
                      </Col>
                    ))} 
                     */}

                <Col lg={12} className="fixed-bottom-buttons">
                  <Row>
                  <Col md={3}></Col>
                    <Col md={5}>
                      <div className="text-center">
                      {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="float-end">
                        <button type="submit" className="ms-1 btn btn-success me-3">Save</button>
                        <button type="reset" className="btn btn-info" onClick={() => goBack()}>Cancel</button>
                      </div>
                    </Col>
                  </Row>
                
                </Col>


              </Row>
            </Form>
            :   <Loader />
          }
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UpdateFormula;
