import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { BasicTable } from './datatableCom';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import { BACKEND_URL } from "../../../config/const.js";
import axios from 'axios';

const ListFormulaTemplate = () => {
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState('');
    const [clientId, setClientId] = useState(null);
    const [sterileData, setSterileData] = useState([]);
    const [nonSterileData, setNonSterileData] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);
    const [loading, setLoading] =useState(false);
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    // Récupération des informations de l'utilisateur connecté
    useEffect(() => {
          const client = JSON.parse(localStorage.getItem("currentOrg"));
        if (client) {
            setClientId(client);
        }
    }, []);

    const user= JSON.parse(localStorage.getItem("user"));
    useEffect(() => {
        const client = JSON.parse(localStorage.getItem("currentOrg"));
        if (client) {
            setClientId(client);
        }
    }, []);
    // Récupération des templates lorsque clientId est défini
    useEffect(() => {
        if (clientId) {
            fetchSterileData();
            fetchNonSterileData();
        }
    }, [clientId,isDeleted]);


    const fetchNonSterileData = async () => {
        try {
            // const status = "Approved"
            const reviewedBy="all";
            const response = await axios.get(`${BACKEND_URL}api/templates/${clientId}/2/${reviewedBy}`, config);
            console.log('template:', response);
            if (response && response.length > 0) {  // Assurez-vous de vérifier response.data si la réponse est dans cette structure.
                const nonSterileData = response.map(item => ({
                    id: item.id,
                    srNo: item.protocolNumber,
                    title: item.productName,
                    createdBy: item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                    ? item.preparedBy[0].username : "Unknown",  
                    createDate: item.effectiveDate,
                    priority: item.status,
                    isUsed:item.isUsed,
                    isreviewed: item.reviewedBy && Array.isArray(item.reviewedBy) && item.reviewedBy.includes(user.id),
                    historicals:item.historical

                }));               
                setNonSterileData(nonSterileData);
              
            }
        } catch (error) {
            console.error('Error fetching template:', error);
        }
    };
    
    const fetchSterileData = async () => {
        try {
        //    const status = "Approved"
        const reviewedBy="all";
            const response = await axios.get(`${BACKEND_URL}api/templates/${clientId}/1/${reviewedBy}`, config);
            console.log('template:', response);
    
            if (response && response.length > 0) { 
                const sterileData = response.map(item => ({
                    id: item.id,
                    srNo: item.protocolNumber,
                    title: item.productName,
                    createdID:item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                    ? item.preparedBy[0].id : null,
                    createdBy: item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                    ? item.preparedBy[0].username : "Unknown", 
                   
                    createDate: item.effectiveDate,
                    priority: item.reviewedBy.includes(user.id)? "Compleated":item.status,
                    isUsed:item.isUsed,
                    isReviewed: item.reviewedBy && Array.isArray(item.reviewedBy) && item.reviewedBy.includes(user.id),
                    historicals:item.historical
                }));
                setSterileData(sterileData);
                console.log('sterilleData:', sterileData);
            }
        } catch (error) {
          
            console.error('Error fetching template:', error);
        }
    };
    
    console.log("sterile:",sterileData );
    console.log("sterile:",nonSterileData );
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Master Formula" breadcrumbItem="List of Master Formula" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="primary"  className="add-btn"   tag={Link} to="/create-template"   id="create-btn"  >
                                                        <i className="ri-add-line align-bottom me-1"></i>
                                                        Create New Master Formula
                                                    </Button>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Col lg={12}>
                                            <Nav tabs className="nav-tabs-custom nav-justified">
                                                <NavItem>
                                                    <NavLink  style={{ cursor: "pointer" }}  className={classnames({
                                                            active: customActiveTab === "1",
                                                        })}   onClick={() => {   toggleCustom("1");   }}   >
                                                        <span className="d-sm-block">
                                                            Formula Sterile
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({  active: customActiveTab === "2",  })}
                                                        onClick={() => {  toggleCustom("2");  }}
                                                    >
                                                        <span className="d-sm-block">
                                                            Formula Non-Sterile
                                                        </span>
                                                    </NavLink>
                                                </NavItem>
                                            </Nav>
                                            <TabContent activeTab={customActiveTab} className="p-3 text-muted">
                                                <TabPane tabId="1">
                                                    <Row>
                                                        <Col className="col-sm m-2" >
                                                            <div className="d-flex justify-content-sm-end">
                                                                <div className="search-box">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control search"
                                                                        placeholder="Search..."
                                                                        value={searchTerm}
                                                                        onChange={e => setSearchTerm(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                      
                                                            <BasicTable searchTerm={searchTerm} initialData={sterileData} isDeleted={isDeleted} setIsDeleted={setIsDeleted} />
                                                        
                                                            </Col>
                                                    </Row>
                                                </TabPane>
                                                <TabPane tabId="2">
                                                    <Row>
                                                        <Col className="col-sm m-2">
                                                            <div className="d-flex justify-content-sm-end">
                                                                <div className="search-box">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control search"
                                                                        placeholder="Search..."
                                                                        value={searchTerm}
                                                                        onChange={e => setSearchTerm(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                
                                                            <BasicTable searchTerm={searchTerm} initialData={nonSterileData} isDeleted={isDeleted} setIsDeleted={setIsDeleted} />
                                                    
                                                        </Col>
                                                    </Row>
                                                </TabPane>
                                            </TabContent>

                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListFormulaTemplate;
