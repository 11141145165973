import React, { useState, useEffect ,useRef} from "react";
import { Card, CardBody, Form, Col, Row, Container, CardText, CardTitle, Input, Label,  } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logoorganization from "../../assets/images/organization_logo.png";
import './style.css';
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import PrintPDF from './printPDF.js';
import { useReactToPrint } from 'react-to-print';
// import { QRCode } from "./QRCode.js";
const Loader = () => {
  return (
    <div className="loader-container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};

const Showsheet = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  document.title = "Show Sheet | Quess Magistrale";
  
  // Tous les hooks au début
  const printRef = useRef(null);
  const [isPrintable, setIsPrintable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [sheetDetail, setSheetDetail] = useState(null);
  const [ingredients, setIngredients] = useState([]);
  const [equipements, setEquipments] = useState([]);
  const [logo, setLogo] = useState(null);
  const [otherRows, setOtherRows] = useState([]);

  // Déplacer le hook useReactToPrint avant toute condition
  const toPDF = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Formula-${sheetDetail?.productName || 'Sheet'}`,
    onBeforeGetContent: () => {
      if (!isPrintable) {
        alert("Please generate the content first before printing");
        return false;
      }
      return true;
    },
    onAfterPrint: () => {
      console.log('Printed successfully');
    },
  });

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  useEffect(() => {
    const logoOrg = localStorage.getItem("logoOrg");
    if (logoOrg) {
      setLogo(`${BACKEND_URL}${logoOrg}`);
    }
  }, [logo]);

  const fetchSheetDetail = async () => {
    setLoading(true);
    try {
      const response = await axios.get(`${BACKEND_URL}api/formula/${id}`, config);
      const data = response;
      setSheetDetail(data);
      setIngredients(data.ingredients || []);
      setEquipments(data.equipements||[])
      setOtherRows(data.others)
    } catch (error) {
      console.error('Error fetching sheet detail:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (id) {
      fetchSheetDetail();
    }
  }, [id]);

  const goBack = () => {
    navigate("/List-formula-sheet");
  };

  // Afficher le loader si nécessaire
  if (loading || !sheetDetail) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <div className="page-content" id="print-container">
    
        <Container fluid={true}>
        <Breadcrumbs title="Preparation Sheet" breadcrumbItem="Detail Preparation sheet" />
        <div style={{ margin: '1%', display: 'flex', justifyContent: 'flex-end' }}>
        <button className="btn btn-primary" onClick={toPDF}  style={{marginRight:"10px"}}>
                <i className="fas fa-file-pdf"></i> 
         </button>
              <button className="btn btn-primary" onClick={()=>goBack()}>
                <i className="fas fa-arrow-left"></i> 
              </button>
          
            </div>
          <div className="row-form mb-4" id="pdf-print">
          <div style={{ display: 'none' }}>
                  
                  <div ref={printRef} >
                      <PrintPDF
                        templateDetail={sheetDetail}
                        ingredients={ingredients}
                        equipments={equipements}  
                        logo={logo}
                        logobce={logobce}
                        others={otherRows}
                        
                      />
                         </div>
                         </div>
          <Row className="mb-3 mt-2">
                      <Col md={2}>
                        <img
                          src={logobce}
                          alt="BCE Logo"
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                      </Col>
                      <Col md={8} className="text-center">
                        <Label className="col-form-label">Compounded Sterile Preparation Protocol</Label>
                        <div>
                          <span>Number: {sheetDetail.protocolNumber}</span>{" "}
                          <span style={{marginLeft:"4%"}}>Version: {sheetDetail.version}</span>
                        </div>
                        <div className="col-md-12 text-center mb-2">
                          <p className="p-card">
                            <h5>{sheetDetail.productName}</h5>
                          </p>
                          <br></br>
                          <span className="bg-success p-3 text-white">STATUS: {sheetDetail.status}</span>
                        </div>
                      </Col>
                      <Col md={2}>
                  <img  src={logo?logo:logoorganization} alt="Logo Organization" height="40" className="logo-organization" />
                      </Col>
                    </Row>
            <Row>
              <Col lg={12}>
                <Card className="card-bordred">
                  <h5 className="card-header" style={{background:"#3fb3d6 !important"}}>Header</h5>
                  <CardBody>

                    {/* Details Section */}
                    <Row  >
                        <Col lg={3}>
                            <Label htmlFor="route_of-administration " className=" col-form-label ms-3"  > Route of administration: </Label>
                        </Col>
                        <Col lg={3}>
                        <p className="p-show">{sheetDetail.routeOfAdministration}</p>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="effective_date " className="col-form-label ms-3"  > Effective Date: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show">
                          {new Date(sheetDetail.effectiveDate).toLocaleDateString('en-GB', {
                            day: '2-digit',
                            month: '2-digit',
                            year: 'numeric',
                          })}
                        </p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-2" >
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="pharmaceutical_form" className=" col-form-label ms-3"  > Pharmaceutical form: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show">{sheetDetail.pharmaForm}</p>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="authorized_by" className="col-form-label ms-3"  > Created by: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show"> { sheetDetail.preparedBy&&sheetDetail.preparedBy.map((item) => ( item.username))}</p>
                          </div>
                        </Col>
                      </Row>

                      <Row className="mt-3">
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="prepared_by" className="col-form-label ms-3"  > Compounded by: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show">  { sheetDetail.approvedBy&&sheetDetail.approvedBy.map((item) => ( item.username))}</p>
                      
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                            <Label htmlFor="checked_by" className="col-form-label ms-3"  > Checked by: </Label>
                          </div>
                        </Col>
                        <Col lg={3}>
                          <div>
                          <p className="p-show"> { sheetDetail.checkedBy&&sheetDetail.checkedBy.map((item) => ( item.username))}</p>
                          </div>
                        </Col>

                      </Row >

                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Formula </h5>
                      <CardBody>
                      <Row className="mt-3">
                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                              <div className="col-md-4">                               
                             <p className="p-show"> {sheetDetail.formulaBatchSize} </p>
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>
                            <p className="p-show"> {sheetDetail.formulaBtachSizeUnit} </p>
                           
                            </div>
                          </Col>

                          <Col lg={2}></Col>

                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size:</label>
                              <div className="col-md-4">
                              <p className="p-show"> {sheetDetail.desiredBatchSize} </p>
                             
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>   <p className="p-show"> {sheetDetail.desiredBtachSizeUnit} </p>  </div>
                          </Col>
                        </Row>

                    {/* Ingredients Table */}
                    <Row className="mt-3">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead style={{ background: "black" }}>
                                <tr id="formula">
                                  <td className="th-blue">Ingredients</td>
                                  <td className="th-blue">Manufacture</td>
                                  <td className="th-blue">Lot Number</td>
                                  <td className="th-blue">Exp Date</td>
                                  <td className="th-blue">QS</td>
                                  <td className="th-blue">Quantity</td>
                                  <td className="th-blue">Units</td>
                                  <td className="th-blue">Quantity Required</td>
                                </tr>
                              </thead>
                              <tbody>
                              {ingredients&&ingredients.map((ing, index) => (
                              <tr key={index}>
                                <td>  {ing.name} </td>
                                <td>  {ing.manufacture}  </td>
                                <td>   {ing.lotNumber}    </td>
                                <td>   {ing.expDate}  </td>
                                <td>   {ing.qs? <i className="mdi mdi-checkbox-marked icone-check" ></i> : ''}  </td>
                                <td>  {ing.quantity}  </td>
                                <td> {ing.unit}  </td>
                                <td>  {ing.qteRequired}   </td>
                              </tr>
                            ))}
                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Required equipment/materiels </h5>
                      <CardBody>
                      <Row className="mt-3">
                      <p>Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <div className="table-responsive">
                        <table className="table table-bordered mb-0">
                          <thead style={{ background: "black" }}>
                          <tr>
                          {Object.keys(sheetDetail.equipements[0])
                            .filter(key => key !== "id" && key !== "check") // Exclure "id"
                            .map((key, index) => (
                              <td key={index} className="th-blue">
                                {key === "name" ? "Equipment/Materials" : key.charAt(0).toUpperCase() + key.slice(1)}
                              </td>
                          ))}
                        </tr>
                          </thead>
                          <tbody>
                          {sheetDetail.equipements.map((equip, index) => (
                          <tr key={index}>
                            {Object.keys(equip)
                              .filter(key => key !== "id" && key !== "check") // Exclure "id"
                              .map((key, i) => (
                                <td key={i}>{String(equip[key])}</td>
                            ))}
                          </tr>
                        ))}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                      </CardBody>
                </Card>
              </Col>
              <Row>
                    <Col lg={12}>
                  {sheetDetail.ingredientsInfo?
                    <Card className="card-bordred">
                      <h5 className="card-header">Additional information on ingredients</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.ingredientsInfo }}  />
                        </CardText>

                      </CardBody>
                    </Card>:<></>
                    }
                     {sheetDetail.calculations?
                    <Card className="card-bordred">
                      <h5 className="card-header">Calculations and Measurements</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.calculations }}  />
             
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.compoundingMethod?
                    <Card className="card-bordred">
                      <h5 className="card-header">Compounding Method</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.compoundingMethod }}  />
                
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.qualityControl?
                    <Card className="card-bordred">
                      <h5 className="card-header">Quality Control</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.qualityControl }}  />
                     
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.packaging?
                    <Card className="card-bordred">
                      <h5 className="card-header">Packaging</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.packaging }}  />
              
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.stabilityAndStorage?
                    <Card className="card-bordred">
                      <h5 className="card-header">Stability and Storage</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.stabilityAndStorage }}  />
                  
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                    {sheetDetail.labelling?
                        <Card className="card-bordred">
                          <h5 className="card-header">Labelling</h5>
                          <CardBody>

                            <CardText>
                            <div  dangerouslySetInnerHTML={{ __html: sheetDetail.labelling }}  />
                      
                            </CardText>

                          </CardBody>
                          </Card>:<></>
                        }
                     {sheetDetail.training?
                    <Card className="card-bordred">
                      <h5 className="card-header">Training</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.training }}  />
                      
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {sheetDetail.refs?
                    <Card className="card-bordred">
                      <h5 className="card-header">References Consulted</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: sheetDetail.refs }}  />
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }

                    {otherRows&&otherRows.map((oth, index) => (
                         <Card className="card-bordred">
                         <h5 className="card-header">{oth.title}</h5>
                         <CardBody>

                           <CardText>
                           <div  dangerouslySetInnerHTML={{ __html: oth.description }}  />
                           </CardText>

                         </CardBody>
                         </Card>
                           ))}
           
                  </Col>
                  {/* <div className="mt-8 flex flex-col items-center">
            <h3 className="text-lg font-semibold mb-4">Scan for digital version</h3>
            <QRCode value="https://example.com/protocol/SC0-181224-142743" size={200} />
          </div> */}
                    </Row>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Showsheet;
