// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.modal-dialog-centered {
    display: flex;
    justify-content: center;
 
  }

 
  
  .delete-button:hover {
    color: darkred; /* Darker red on hover */
  }
.delete-button {
color: red;
font-size: 21px; 
cursor: pointer;
}
.checkboxQuessCompounding {
  margin-left: 2%;
}
.form-header-qcom 
{
  padding: 1%;
  font-weight: bold;
}
.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
  margin-left: 2%;

}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 34px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4caf50;
}

input:checked + .slider:before {
  transform: translateX(26px);
}
.addUserButton {
 margin-bottom: 1%;
}
 `, "",{"version":3,"sources":["webpack://./src/Pages/Administration/GroupAdministration/Styles.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,uBAAuB;;EAEzB;;;;EAIA;IACE,cAAc,EAAE,wBAAwB;EAC1C;AACF;AACA,UAAU;AACV,eAAe;AACf,eAAe;AACf;AACA;EACE,eAAe;AACjB;AACA;;EAEE,WAAW;EACX,iBAAiB;AACnB;AACA;EACE,kBAAkB;EAClB,qBAAqB;EACrB,WAAW;EACX,YAAY;EACZ,eAAe;;AAEjB;;AAEA;EACE,UAAU;EACV,QAAQ;EACR,SAAS;AACX;;AAEA;EACE,kBAAkB;EAClB,eAAe;EACf,MAAM;EACN,OAAO;EACP,QAAQ;EACR,SAAS;EACT,sBAAsB;EACtB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,kBAAkB;EAClB,WAAW;EACX,YAAY;EACZ,WAAW;EACX,SAAS;EACT,WAAW;EACX,uBAAuB;EACvB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,2BAA2B;AAC7B;AACA;CACC,iBAAiB;AAClB","sourcesContent":[".modal-dialog-centered {\n    display: flex;\n    justify-content: center;\n \n  }\n\n \n  \n  .delete-button:hover {\n    color: darkred; /* Darker red on hover */\n  }\n.delete-button {\ncolor: red;\nfont-size: 21px; \ncursor: pointer;\n}\n.checkboxQuessCompounding {\n  margin-left: 2%;\n}\n.form-header-qcom \n{\n  padding: 1%;\n  font-weight: bold;\n}\n.switch {\n  position: relative;\n  display: inline-block;\n  width: 60px;\n  height: 34px;\n  margin-left: 2%;\n\n}\n\n.switch input {\n  opacity: 0;\n  width: 0;\n  height: 0;\n}\n\n.slider {\n  position: absolute;\n  cursor: pointer;\n  top: 0;\n  left: 0;\n  right: 0;\n  bottom: 0;\n  background-color: #ccc;\n  transition: 0.4s;\n  border-radius: 34px;\n}\n\n.slider:before {\n  position: absolute;\n  content: \"\";\n  height: 26px;\n  width: 26px;\n  left: 4px;\n  bottom: 4px;\n  background-color: white;\n  transition: 0.4s;\n  border-radius: 50%;\n}\n\ninput:checked + .slider {\n  background-color: #4caf50;\n}\n\ninput:checked + .slider:before {\n  transform: translateX(26px);\n}\n.addUserButton {\n margin-bottom: 1%;\n}\n "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
