import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";


import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import withRouter from "../../components/Common/withRouter";

import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate ,useParams} from 'react-router-dom';
import logoEnDark from "../../assets/images/QUESS_COMPOUNDING.png"
import axios from "axios";
import { BACKEND_URL,BACKEND_Externe_URL } from "../../config/const.js";
const DoubleAuthentication = () => {
 
  const {t} = useTranslation();
  document.title = t('doubleAuthentication');
  const { language } = useParams();
  const history = useNavigate ();

  const [lang,setLang]= useState(localStorage.getItem('lang'))
  const [code,setCode]= useState()
  const [userId,setUserId]= useState()
  const [error,setError]= useState("")
  const [emailSentResult, setEmailSentResult] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BACKEND_Externe_URL}connectedUserInfo`, config);
       
        
        setUserId(response.id)
     
      } catch (error) {
        console.error("Error fetching profile data:", error.response ? error.response : error.message);
     
      }
    };

    fetchUserProfile();
  }, []); // Empty array to fetch data only on component mount
  const changeLanguage=(val)=> { 
    const langUrl = localStorage.getItem("lang");
    if(language=="fr"||language=="en"){
     
      if (val !== langUrl) {
        localStorage.setItem("lang", val);
        setLang(val);
        history.push("/"+val)
        window.location.reload();
      }else{
        window.location.reload();
      }
    } else{
      
      localStorage.setItem("lang",val);
      setLang(val);
      window.location.reload();
    } 
  }
  const  loginMFA= async ()=>{
    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('token')}`,
      },
    };
    const userData={
      userId :userId,
      verificationcode:code
    }
    const response = await axios.post(`${BACKEND_URL}api/user/login2FA`, userData, config);
    if (response.isVerified) {
      setEmailSentResult({ text: t("loginSuccess"), type: "success" });
      setTimeout(() => {
        setEmailSentResult(null);

         window.location="/dashboard"
      }, 2500);
    } else {
      setEmailSentResult({ text: t("errorLogin"), type: "error" });
      setTimeout(() => {
        setEmailSentResult(null);
      }, 2500);
    }
   
  }

  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay "></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={6}>
                <Card className='mt-5'>
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img src={lang=="fr"?logodark:logoEnDark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                          <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                        </Link>
                      </div>
                      <h4 className="font-size-18 text-muted mt-3 text-center">{t('doubleAuthentication')}</h4>
                      <section className="jss109">
                      
                      </section>
                      {/* <p className="mb-5 text-center"></p> */}
                  
                        <Row>
                          <Col md={12}>
                          

                            <div className="mt-4" style={{alignItems:'center'}}>
                              <label className="form-label" htmlFor="code" style={{textAlign:'center'}}>Code</label>
                              <input type="number" className="form-control" id="code" placeholder={t('enterCode')}  onChange={(e)=>setCode(e.target.value)}/>
                            </div>
                            <div className="d-grid mt-4">
                              <button  className="btn btn-primary waves-effect waves-light" onClick={()=>{loginMFA()}}>{t('sendCode')}</button >
                            </div>
                            <div  className="lngBtn" style={{display:'flex',alignItems:'center', justifyContent:'center', marginTop:"5%"}}> 
              <div  style={{  color: '#039be5', textAlign:'center'}}  >
                <button id="fr" onClick={()=>changeLanguage("fr")}        
                style={{  borderBottom: localStorage.getItem('lang') === 'fr' ? 'solid #32a1ce' : 'initial', borderRadius:'0'
                ,color: '#039be5'}}
                  className="btn  waves-effect waves-light"
                >{t("francais")}
                </button>
                /
                <button  id="en"
                style={{ borderBottom: localStorage.getItem('lang') === 'en' ? 'solid #32a1ce' : 'initial', borderRadius:'0'
                ,color: '#039be5'}}
                onClick={()=>changeLanguage("en")}   className="btn  waves-effect waves-light">{t("anglais")}
                
                </button>
              </div>
              <i className="bx bx-globe" style={{ fontSize: '25px' }} />
              

            </div>
                          </Col>
                        </Row>
                    
                    </div>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                       <p className="text-white-50">© {new Date().getFullYear()} BCE PHARMA.   {t("reservedRights")}.</p>
                </div>
              </Col>
            </Row>
          </Container>
          {emailSentResult && (
            <div
              style={{
                position: "fixed",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "10px 20px",
                backgroundColor:
                  emailSentResult.type === "success" ? "green" : "#ff3d60",
                color: "white",
                borderRadius: "5px",
                zIndex: 1000,
                minWidth: "25%",
                textAlign: "center",
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              {emailSentResult.text}
            </div>
          )}
        </div>
      </div>

    </React.Fragment>
  );
}
export default withRouter(DoubleAuthentication);

DoubleAuthentication.propTypes = {
  history: PropTypes.object,
};
