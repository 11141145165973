// Fonction pour initialiser l'écouteur d'événement de fermeture du navigateur
export const initSessionManager = () => {
  // Vérifier si une session existe déjà
  const existingSessionId = localStorage.getItem('sessionId');
  const browserSessionId = existingSessionId || Math.random().toString(36).substring(2);
  
  // Stocker l'ID de session et le timestamp
  localStorage.setItem('browserSession', 'active');
  localStorage.setItem('sessionId', browserSessionId);
  localStorage.setItem('lastActivity', Date.now().toString());

  // Fonction pour vérifier si le navigateur est toujours actif
  const checkBrowserSession = () => {
    const lastActivity = parseInt(localStorage.getItem('lastActivity'));
    const currentTime = Date.now();

    // Vérifier seulement l'inactivité (30 minutes = 1800000 ms)
    if (currentTime - lastActivity > 900000) {
      clearSession();
      return;
    }

    // Mettre à jour le timestamp d'activité
    localStorage.setItem('lastActivity', currentTime.toString());
  };

  // Vérifier la session toutes les 30 secondes
  const sessionCheck = setInterval(checkBrowserSession, 30000);

  // Sauvegarder l'ID de l'intervalle
  localStorage.setItem('sessionCheckInterval', sessionCheck.toString());

  // Gestionnaire pour la fermeture de la fenêtre/onglet
  const handleUnload = (event) => {
    // Vérifier si c'est une actualisation
    if (event.type === 'beforeunload' && performance.navigation.type === 1) {
      return; // Ne rien faire si c'est une actualisation
    }

    const activeTabsCount = localStorage.getItem('activeTabsCount') || '0';
    const newCount = Math.max(0, parseInt(activeTabsCount) - 1);
    
    if (newCount === 0) {
      clearSession();
    } else {
      localStorage.setItem('activeTabsCount', newCount.toString());
    }
  };

  // Gestionnaire pour l'ouverture d'un nouvel onglet
  const handleLoad = () => {
    const activeTabsCount = localStorage.getItem('activeTabsCount') || '0';
    localStorage.setItem('activeTabsCount', (parseInt(activeTabsCount) + 1).toString());
  };

  // Ajouter les écouteurs d'événements
  window.addEventListener('beforeunload', handleUnload);
  handleLoad(); // Incrémenter le compteur d'onglets pour cet onglet

  // Gestionnaire d'activité utilisateur
  const updateActivity = () => {
    localStorage.setItem('lastActivity', Date.now().toString());
  };

  // Écouter les événements d'activité
  ['mousedown', 'keydown', 'scroll', 'touchstart'].forEach(eventName => {
    document.addEventListener(eventName, updateActivity, true);
  });

  // Mise à jour initiale de l'activité
  updateActivity();
};

// Fonction pour nettoyer la session
const clearSession = () => {
  const items = [
    'browserSession',
    'sessionId',
    'lastActivity',
    'activeTabsCount',
    'sessionCheckInterval',
    'authUser',
    'token',
    'roles',
    'user',
    'currentOrg',
    'logoOrg'
  ];

  items.forEach(item => localStorage.removeItem(item));

  // Rediriger vers la page de login si nécessaire
  if (window.location.pathname !== '/login') {
    window.location.href = '/login';
  }
};

// Fonction pour vérifier si la session est active
export const isSessionActive = () => {
  const sessionId = localStorage.getItem('sessionId');
  const lastActivity = parseInt(localStorage.getItem('lastActivity'));
  const currentTime = Date.now();

  if (!sessionId || !lastActivity) {
    return false;
  }

  // Session expire après 30 minutes d'inactivité
  return (currentTime - lastActivity) < 900000;
};

// Fonction pour nettoyer manuellement la session (utile pour le logout)
export const clearSessionManually = () => {
  const intervalId = localStorage.getItem('sessionCheckInterval');
  if (intervalId) {
    clearInterval(parseInt(intervalId));
  }
  clearSession();
}; 