import React, { useState } from 'react';
import { Button, Card, CardBody, Col, Container, Row, Modal, Input, ModalBody, Alert, ModalFooter, ModalHeader } from 'reactstrap';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import axios from 'axios';
import './style.css';
import { BACKEND_URL } from "../../config/const.js";
import { useDataFetching } from "../../hooks/useDataFetching.js";

const ListUnits = () => {
    const [modal_list, setmodal_list] = useState(false);
    const [modal_delete, setmodal_delete] = useState(false);
    const [editIndex, setEditIndex] = useState(null);
    const [newUnit, setNewUnit] = useState({ name: '', shorthand: '', clientId: '' });
    const [editedUnit, setEditedUnit] = useState({ name: '', shorthand: '' });
    const [selectedIds, setSelectedIds] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [modal_standard, setmodal_standard] = useState(false);
    const [unitToDelete, setUnitToDelete] = useState(null);
    const [loading, setLoading] = useState(false);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    const [update, setUpdate] = useState(false);

    const itemsPerPage = 5;
    const clientId = JSON.parse(localStorage.getItem("currentOrg"));
    
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    // Utilisation du hook useDataFetching
    const {
        data: units,
        setData: setUnits,
        isLoading,
        error,
        refetch: refetchUnits
    } = useDataFetching(
        `${BACKEND_URL}api/unitsbyclient/${clientId}`,
        clientId,
        config
    );

    // Fonctions de gestion
    const handleEditClick = (id) => {
        const unit = units.find(unit => unit.id === id);
        if (unit) {
            setEditIndex(id);
            setEditedUnit({ ...unit });
        }
    };

    const handleSave = async (id) => {
        try {
            const updatedUnit = {
                id: editedUnit.id,
                name: editedUnit.name,
                shorthand: editedUnit.shorthand,
                clientId: clientId
            };

            const response = await axios.put(
                `${BACKEND_URL}api/units/${updatedUnit.id}`,
                updatedUnit,
                config
            );

            if (response.data.code === 409) {
                setUpdate(true);
                setAlert('danger');
                setMessage('Error: Unit with the same name already exists.');
            } else {
                const updatedUnits = units.map(unit =>
                    unit.id === id ? response.data : unit
                );
                setUnits(updatedUnits);
                setEditIndex(null);
            }
        } catch (error) {
            setUpdate(true);
            setAlert('danger');
            setMessage(error.response?.data?.message || 'Error updating unit.');
            setTimeout(() => {
                setUpdate(false);
            }, 2000);
        }
    };

    const handleCancel = () => {
        setEditIndex(null);
    };

    // Fonction pour gérer le changement de page
    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    // Fonction pour gérer les changements de checkbox
    const handleCheckboxChange = (id) => {
        setSelectedIds(prevIds => {
            if (prevIds.includes(id)) {
                return prevIds.filter(itemId => itemId !== id);
            } else {
                return [...prevIds, id];
            }
        });
    };

    // Fonction pour gérer les changements d'input
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setEditedUnit(prevState => ({
            ...prevState,
            [name]: value
        }));
    };

    // Fonction pour le toggle du modal de suppression
    const tog_standard = () => {
        setmodal_standard(!modal_standard);
    };

    // Filtrage des unités
    const filteredUnits = units.filter(unit =>
        unit.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        unit.shorthand.toLowerCase().includes(searchTerm.toLowerCase())
    );

    // Calcul de la pagination
    const totalItems = filteredUnits.length;
    const totalPages = Math.ceil(totalItems / itemsPerPage);

    // Calcul des éléments à afficher sur la page courante
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const displayedUnits = filteredUnits.slice(indexOfFirstItem, indexOfLastItem);

    // Add new unit
    // Add new unit
    const handleAddUnit = async (e) => {
        e.preventDefault();
        try {
            const unitToCreate = { ...newUnit, clientId: clientId };
            const response = await axios.post(`${BACKEND_URL}api/units`, unitToCreate, config);

            if (response.data.code === 409) {
                setLoading(true);
                setAlert('danger');
                setMessage('Error: Unit with the same name already exists.');
            } else {
                setUnits([...units, response.data]);
                setmodal_list(false);
                setMessage('Unit added successfully...');
                setLoading(true);
                setAlert('success');
            }
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error adding new unit.');
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        }
    };

    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/units/${id}`, config);
            const updatedUnits = units.filter(unit => unit.id !== id);
            setUnits(updatedUnits);
            setmodal_standard(false);
            setMessage('Unit removed successfully...');
            setLoading(true);
            setAlert('warning');
            setTimeout(() => {
                setLoading(false);
            }, 3000);
        } catch (error) {
            console.error('Error deleting unit:', error);
        }
    };

    // Afficher le loading pendant le chargement
  

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Management" breadcrumbItem="List Units" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                        <Row className="g-4 mb-3">
                                            <Col className="col-sm-auto">
                                                <div className="d-flex gap-1">
                                                    <Button color="success" className="add-btn" onClick={() => setmodal_list(true)} id="create-btn">
                                                        <i className="ri-add-line align-bottom me-1"></i> Add Unit
                                                    </Button>

                                                </div>
                                            </Col>



                                            <Col className="col-sm">
                                                <div className="d-flex justify-content-sm-end">
                                                    <div className="search-box ms-2" style={{ display: 'flex' }}>
                                                        <i className="ri-search-line search-icon mt-1 p-2"></i>
                                                        <input
                                                            type="text"
                                                            className="form-control search"
                                                            placeholder="Search..."
                                                            value={searchTerm}
                                                            onChange={(e) => setSearchTerm(e.target.value)}
                                                        />

                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <div className="table-responsive table-card mt-3 mb-1">

                                            {loading && alert === "success" ? (<Alert color={alert}>{message}</Alert>) : null}
                                            <table className="table align-middle table-nowrap" id="customerTable">
                                                <thead className="table-light">
                                                    <tr>
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            <div className="form-check">
                                                                <input
                                                                    className="form-check-input"
                                                                    type="checkbox"
                                                                    id="checkAll"
                                                                    value="option"
                                                                    onChange={(e) => {
                                                                        const isChecked = e.target.checked;
                                                                        setSelectedIds(isChecked ? units.map(i => i.id) : []);
                                                                    }}
                                                                    checked={selectedIds.length === units.length && units.length > 0}
                                                                />
                                                            </div>
                                                        </th>
                                                        <th className="sort" data-sort="id">#ID</th>

                                                        <th className="sort" data-sort="name">Unit Name</th>
                                                        <th className="sort" data-sort="shorthand">ShortName</th>
                                                        <th className="sort" data-sort="action">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody className="list form-check-all">
                                                    {update && alert === "danger" ? (
                                                        <tr>
                                                            <td colSpan={5}><Alert color={alert}>{message}</Alert></td>
                                                        </tr>
                                                    ) : null}

                                                    {displayedUnits && displayedUnits.map((unit) => (
                                                        <tr key={unit.id}>
                                                            <td scope="row">
                                                                <div className="form-check">
                                                                    <input  className="form-check-input"  type="checkbox"  name="chk_child"
                                                                        value="option1"  onChange={() => handleCheckboxChange(unit.id)}
                                                                        checked={selectedIds.includes(unit.id)}
                                                                    />
                                                                </div>
                                                            </td>
                                                            <td className="id">{unit.id}</td>
                                                            <td className="name">
                                                                {editIndex === unit.id ? (
                                                                    <Input
                                                                        type="text"
                                                                        name="name"
                                                                        value={editedUnit.name}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                ) : (
                                                                    unit.name
                                                                )}
                                                            </td>
                                                            <td className="shorthand">
                                                                {editIndex === unit.id ? (
                                                                    <Input
                                                                        type="text"
                                                                        name="shorthand"
                                                                        value={editedUnit.shorthand}
                                                                        onChange={handleInputChange}
                                                                    />
                                                                ) : (
                                                                    unit.shorthand
                                                                )}
                                                            </td>
                                                        
                                                            <td>
                                                                <div className="d-flex gap-2">
                                                                    {editIndex === unit.id ? (
                                                                        <>
                                                                            <button className="btn btn-sm btn-success" onClick={() => handleSave(unit.id)}>
                                                                                <i className="fas fa-save"></i>
                                                                            </button>
                                                                            <button className="btn btn-sm btn-danger" onClick={handleCancel}>
                                                                                <i className='dripicons-return'></i>
                                                                            </button>
                                                                        </>
                                                                    ) : (
                                                                        <>
                                                                            <button className="btn btn-outline-info waves-effect waves-light" onClick={() => handleEditClick(unit.id)}>
                                                                                <i className="ri-pencil-fill align-bottom"></i>
                                                                            </button>
                                                                            <button
                                                                                className="btn btn-outline-danger waves-effect waves-light"
                                                                                onClick={() => {
                                                                                    setUnitToDelete(unit);
                                                                                    setmodal_standard(true);
                                                                                }}
                                                                                disabled={unit.isUsed}
                                                                            >
                                                                                <i className="ri-delete-bin-fill align-bottom"></i>
                                                                            </button>
                                                                        </>
                                                                    )}
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>

                                            </table>
                                            {totalItems === 0 && (
                                                <div className="noresult" style={{ padding: "30px 0" }}>
                                                    <div className="text-center">
                                                        {isLoading ? (
                                                            <div className="loading-container">
                                                                <div className="loading-spinner">
                                                                    <div className="spinner-border text-primary" role="status" style={{ width: "3rem", height: "3rem" }}>
                                                                        <span className="visually-hidden">Loading...</span>
                                                                    </div>
                                                                    <h5 className="mt-3 text-primary">Loading data...</h5>
                                                                    <p className="text-muted">Please wait while we fetch the information</p>
                                                                </div>
                                                            </div>
                                                        ) : (
                                                            <div className="no-result-container">
                                                       
                                                                <h5 className="mt-3">Sorry! No Result Found</h5>
                                                            
                                                                
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>

                                        {totalItems > itemsPerPage && (
                                            <div className="d-flex justify-content-end mt-3">
                                                <nav>
                                                    <ul className="pagination">
                                                        {[...Array(totalPages)].map((_, pageIndex) => (
                                                            <li key={pageIndex} className={`page-item ${currentPage === pageIndex + 1 ? 'active' : ''}`}>
                                                                <button className="page-link" onClick={() => handlePageChange(pageIndex + 1)}  >
                                                                    {pageIndex + 1}
                                                                </button>
                                                            </li>
                                                        ))}
                                                    </ul>
                                                </nav>
                                            </div>
                                        )}

                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            {/*start model add*/}
            <Modal isOpen={modal_list} toggle={() => { setmodal_list(!modal_list); }} centered className="modal-lg"  >
                <ModalHeader>
                    Add New Unit
                </ModalHeader>
                <ModalBody>
                    {alert && alert === "danger" ? (<Alert color={alert}>{message}</Alert>) : null}
                    <form onSubmit={handleAddUnit}>

                        <div className="mb-3">
                            <label htmlFor="name" className="form-label">Unit Name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="name"
                                name="name"
                                value={newUnit.name}
                                onChange={(e) => setNewUnit({ ...newUnit, name: e.target.value })}
                                required
                            />
                        </div>
                        <div className="mb-3">
                            <label htmlFor="shorthand" className="form-label">Shorthand</label>
                            <input
                                type="text"
                                className="form-control"
                                id="shorthand"
                                name="shorthand"
                                value={newUnit.shorthand}
                                onChange={(e) => setNewUnit({ ...newUnit, shorthand: e.target.value })}
                                required
                            />
                        </div>
                        <ModalFooter>
                            <Button type="button" color="secondary" onClick={() => setmodal_list(false)}>Cancel</Button>
                            <Button type="submit" color="primary">Add Unit</Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>
            {/*end model add */}

            {/*start model delete */}
            <Modal isOpen={modal_standard} toggle={() => setmodal_standard(!modal_standard)} className="modal-dialog-centered">

                <CardBody>
                    <div className="text-center">
                        <div className="mb-4">
                            <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                        </div>
                        <h4 className="alert-heading">
                            Are you sure?
                        </h4>
                        <p className="mb-0">
                            you wont be able to revert this !
                            <br />   <br />
                        </p>
                    </div>
                </CardBody>

                <div className="modal-footer text-center " style={{ justifyContent: "center" }}>
                    <Button color="success" onClick={() => unitToDelete && handleRemove(unitToDelete.id)}>Yes, delete it !</Button>


                    <button type="button" className="btn btn-danger " onClick={() => { tog_standard(); }}  >
                        Cancel
                    </button>
                </div>
            </Modal>
            {/* end model delete */}
        </React.Fragment>
    );
};

export default ListUnits;
