import { useState, useEffect, useCallback } from 'react';
import axios from 'axios';

export const useDataFetching = (url, dependencies = null, config = {}) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);

  // Cache duration in milliseconds (e.g., 5 minutes)
  const CACHE_DURATION = 5 * 60 * 1000;
  const CACHE_KEY = `${url}_${JSON.stringify(dependencies)}`;
  const TIMEOUT_DURATION = 500; // Set timeout duration (e.g., 500ms)

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);
      const response = await axios.get(url, config);
      setData(response.data);
      setError(null);
    } catch (err) {
      setError(err.message);
      setData([]);
    } finally {
      setIsLoading(false);
    }
  }, [url, JSON.stringify(config)]);

  useEffect(() => {
    if (url) {
      fetchData();
    }
  }, [fetchData, dependencies]);

  const updateLocalData = (newData) => {
    setData(newData);
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data: newData,
      timestamp: Date.now()
    }));
  };

  return {
    data,
    setData: updateLocalData,
    isLoading,
    error,
    refetch: fetchData
  };
};

export default useDataFetching; 