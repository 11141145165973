import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Form,
  Col,
  Row,
  CardSubtitle,
  Alert,
  Container,
  Progress,
} from "reactstrap";
import axios from "axios";
import { BACKEND_URL } from "../../config/const.js";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import withRouter from "../../components/Common/withRouter.js";

import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";
import logoEnDark from "../../assets/images/QUESS_COMPOUNDING.png";
const ChangePasswordForm = () => {
  const { t } = useTranslation();
  document.title = t("changePasswordForm");
  const { language } = useParams();
  const history = useNavigate();
  const [password, setPassword] = useState("");
  const [emailSentResult, setEmailSentResult] = useState(null);
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordConfirmation, setPasswordConfirmation] = useState("");

  const navigate = useNavigate();
  const [lang, setLang] = useState(localStorage.getItem("lang"));
 
  const [passwordStrength, setPasswordStrength] = useState({
    value: 0,
    text: "",
    color: "",
  });
  const [passwordError, setPasswordError] = useState(null);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const checkpassword = () => {
    if (password == "") {
      setPasswordError(true);
      return false;
    } else {
      setPasswordError(null);
      return true;
    }
  };
  useEffect(() => {
    const storedLang = localStorage.getItem("lang");
    if (lang !== storedLang) {
        localStorage.setItem("lang", lang);
        window.location.reload();
    }
  }, [lang]);
  const checkPasswordMatch = (password, passwordConfirmation) => {
    setPasswordMatch(password === passwordConfirmation);
  };
  useEffect(() => {
    calculatePasswordStrength(password);
    checkPasswordMatch(password, passwordConfirmation);
  }, [password, passwordConfirmation]);
console.log(language)
  const changeLanguage = (val) => {
    const langUrl = localStorage.getItem("lang");
    if (language == "fr" || language == "en") {
      if (val !== langUrl) {
        localStorage.setItem("lang", val);
        setLang(val);
       
        window.location.reload();
      } else {
        window.location.reload();
      }
    } else {
      localStorage.setItem("lang", val);
      setLang(val);
      window.location.reload();
    }
  };

  const calculatePasswordStrength = (password) => {
    let strength = 0;
    let text = t("veryWeak");
    let color = "dark";

    // Check length
    const isLongEnough = password.length >= 4;

    if (!isLongEnough) {
      // If password is too short
      setPasswordStrength({ value: 0, text: t("veryWeak"), color: "dark" });
      return;
    }

    // Criteria checks
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasDigit = /\d/.test(password);
    const hasSpecialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    const criteriaCount = [
      hasUpperCase,
      hasLowerCase,
      hasDigit,
      hasSpecialChar,
    ].filter(Boolean).length;

    switch (criteriaCount) {
      case 4:
        strength = 100;
        text = t("strong");
        color = "success";
        break;
      case 3:
        strength = 75;
        text = t("good");
        color = "info";
        break;
      case 2:
        strength = 50;
        text = t("fair");
        color = "warning";
        break;
      case 1:
        strength = 25;
        text = t("weak");
        color = "danger";
        break;
      default:
        strength = 0;
        text = t("veryWeak");
        color = "light";
    }

    setPasswordStrength({ value: strength, text, color });
  };
  const handleSendEmail = async (e) => {
    e.preventDefault();
    const userData = {
      password: password
    };
  
    if (!checkpassword() || !passwordMatch) {
      setEmailSentResult({ text: t("passwordChangedFailure"), type: "error" });
      setTimeout(() => {
        setEmailSentResult(null);
      }, 2500);
    } else {
      const response = await axios.post(
        `${BACKEND_URL}api/user/changePassword`,
        userData,
       config
      );
      if (response.message == true) {
        setEmailSentResult({ text: t("passwordChangedSuccess"), type: "success" });
        setTimeout(() => {
          setEmailSentResult(null);

          navigate("/login");
          localStorage.removeItem("token")
        }, 2500);
      } else {
        setEmailSentResult({ text: t("passwordChangedFailure"), type: "error" });
        setTimeout(() => {
          setEmailSentResult(null);
        }, 2500);
      }
    }
  };
  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay "></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={6}>
                <Card className="mt-5">
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img
                            src={lang == "fr" ? logodark : logoEnDark}
                            alt=""
                            height="24"
                            className="auth-logo logo-dark mx-auto"
                          />
                          <img
                            src={logolight}
                            alt=""
                            height="24"
                            className="auth-logo logo-light mx-auto"
                          />
                        </Link>
                      </div>
                      <h4 className="font-size-18 text-muted mt-3 text-center">
                        {t("changePassword")}
                      </h4>
                     
                      {/* <p className="mb-5 text-center"></p> */}
                      <Form onSubmit={handleSendEmail}>
                        <Row>
                          <Col md={6} lg={6} sm={12}>
                          
                            <Row>
                            <div className="mt-4">
                              <label className="form-label" htmlFor="password">
                                {t("password")}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="password"
                                autocomplete="new-password"
                                placeholder={t("password")}
                                onChange={(e) => setPassword(e.target.value)}
                              />
                              {passwordError && (
                                <code className="text-danger">
                                  {" "}
                                  {t("passwordRequiredMessage")}
                                </code>
                              )}
                            </div>
                            </Row>
                            <Row>
                            <div className="mt-4">
                              <label className="form-label" htmlFor="passwordConfirm">
                                {t("passwordConfirm")}
                              </label>
                              <input
                                type="password"
                                className="form-control"
                                id="passwordConfirm"
                                autocomplete="new-password"
                                placeholder={t("password")}
                                onChange={(e) => setPasswordConfirmation(e.target.value)}
                              />
                              {!passwordMatch && (
                                <code className="text-danger">
                                  {" "}
                                  {t("passwordDontMatch")}
                                </code>
                              )}
                            </div>
                            </Row>
                           
                           
                          </Col>
                          <Col lg={6} md={6}  sm={12}>
                          <Row>

                          </Row>
                          <Row>
                            
                          </Row>
                          <div style={{marginTop:'25%'}}>
                          <div
                            className={`progress-label text-${passwordStrength.color} border-${passwordStrength.color} mb-2`}
                          >
                            {passwordStrength.text}
                          </div>
                          <Progress
                            striped
                            color={passwordStrength.color}
                            value={passwordStrength.value}
                            id="strongpassword"
                          />
                          </div>
                          
                        </Col>
                        </Row>
                        <Row>
                        <div className="d-grid mt-4">
                              <button
                                className="btn btn-primary waves-effect waves-light"
                                type="submit"
                              >
                                {t("changePasswordButton")}
                              </button>
                            </div>
                        </Row>
                      </Form>
                    </div>
                    <Row>
                      <div
                        className="lngBtn"
                        style={{
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          marginTop: "5%",
                        }}
                      >
                        <div style={{ color: "#039be5", textAlign: "center" }}>
                          <button
                            id="fr"
                            onClick={() => changeLanguage("fr")}
                            type="button"
                            style={{
                              borderBottom:
                                localStorage.getItem("lang") === "fr"
                                  ? "solid #32a1ce"
                                  : "initial",
                              borderRadius: "0",
                              color: "#039be5",
                            }}
                            className="btn  waves-effect waves-light"
                          >
                            {t("francais")}
                          </button>
                          /
                          <button
                            id="en"
                            type="button"
                            style={{
                              borderBottom:
                                localStorage.getItem("lang") === "en"
                                  ? "solid #32a1ce"
                                  : "initial",
                              borderRadius: "0",
                              color: "#039be5",
                            }}
                            onClick={() => changeLanguage("en")}
                            className="btn  waves-effect waves-light"
                          >
                            {t("anglais")}
                          </button>
                        </div>
                        <i
                          className="bx bx-globe"
                          style={{ fontSize: "25px" }}
                        />
                      </div>
                    </Row>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                  <p className="text-white-50">
                    © {new Date().getFullYear()} BCE PHARMA.{" "}
                    {t("reservedRights")}.
                  </p>
                </div>
              </Col>
            </Row>
          </Container>
          {emailSentResult && (
            <div
              style={{
                position: "fixed",
                bottom: "10px",
                left: "50%",
                transform: "translateX(-50%)",
                padding: "10px 20px",
                backgroundColor:
                  emailSentResult.type === "success" ? "green" : "#ff3d60",
                color: "white",
                borderRadius: "5px",
                zIndex: 1000,
                minWidth: "25%",
                textAlign: "center",
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              {emailSentResult.text}
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};
export default withRouter(ChangePasswordForm);

ChangePasswordForm.propTypes = {
  history: PropTypes.object,
};
