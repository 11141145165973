import React, { useState, useEffect, version } from "react";
import Creatable from 'react-select/creatable';
import { Card, CardBody, Form, Col, Row, Container,Alert } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import logoorganization from "../../assets/images/organization_logo.png";
import axios from 'axios';
import './style.css';
import { BACKEND_URL} from "../../config/const.js";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { EditorState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { Icon } from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
const CreateNewTemplate = () => {
  document.title = "Create New Template | Quess Magistrale";
  const navigate = useNavigate();
  const [routeNames, setRouteNames] = useState([{ id: 1, name: '' }]);
  const [authorizeds, setAuthorizeds] = useState([{ id: 1, userId: null }]);
  const [isChecked, setIsChecked] = useState(false);
  const [formulaRows, setFormulaRows] = useState([
    { id: 1, name: '', manufacture: '', quantity: 0, unit: '', qteRequired: 0, lotNumber: '', expDate: '', check: false }
  ]);
  
  const [equipmentRows, setEquipmentRows] = useState([
    { id: 1, name: '', quantity: 0, check: false }
  ]);
  const [otherRows, setOtherRows] = useState([{ id: 1 }]);
  const [formulaBatch, setFormulaBatch] = useState('');
  const [desiredBatch, setDesiredBatch] = useState('');
  const [unitFormula, setUnitFormula] = useState('Units');
  const [unitDesired, setUnitDesired] = useState('Units');
  const [selectedIds, setSelectedIds] = useState([]);
  //const [columns, setColumns] = useState(['Quantity']);
  const [isColumnAdded, setIsColumnAdded] = useState(false);
  const [columns, setColumns] = useState([{ name: 'quantity' }]); // Initial column names
 
  const [clientId, setClientId] = useState(null);
  const [equipements, setEquipements] = useState([]);
  const [routes, setRoutes] = useState([]);
  const [ingredients, setIngredients] = useState([]);
  const [pharmaceuticals, setPharmaceuticals] = useState([]);
  const [units, setUnits] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };

  const client = JSON.parse(localStorage.getItem("currentOrg"));
  useEffect(() => {
    const client = JSON.parse(localStorage.getItem("currentOrg"));
    const logoOrg = localStorage.getItem("logoOrg"); // Assuming logoOrg is just a URL string
  
    if (logoOrg) {
      setLogo(`${BACKEND_URL}${logoOrg}`);// Ensure this constructs a valid URL
    }
  if (client) {
      setClientId(client);
  }
  }, [client]);
  useEffect(() => {
    fetchUnits();
    fetchEquipements();
    fetchIngredients();
    fetchPharmaceuticals();
    fetchRoutes();
    fetchSuppliers();
    fetchUsers();
  }, [clientId]);

  const fetchEquipements = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/equipementsbyclient/${clientId}`, config);
        //console.log('res', response);
        setEquipements(response.data);
      }

    } catch (error) {
      console.error('Error fetching equipment:', error);
    }
  };

  const fetchIngredients = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/ingredientsbyclient/${clientId}`, config);
        //console.log('ingredients:', response);
        setIngredients(response.data);
      }

    } catch (error) {
      console.error('Error fetching ingredient:', error);
    }
  };

  const fetchPharmaceuticals = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/pharmaceutical-formsbyclient/${clientId}`, config);
        //console.log('pharmaceuticals:', response);
        setPharmaceuticals(response.data);
      }

    } catch (error) {
      console.error('Error fetching pharmaceutical:', error);
    }
  };

  const fetchRoutes = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/route-administrationsbyclient/${clientId}`, config);
       
       // console.log('routes:', response.data);
        setRoutes(response.data);
      }

    } catch (error) {
      console.error('Error fetching route:', error);
    }
  };

  const fetchSuppliers = async () => {
    try {
      if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/manufacturersbyclient/${clientId}`, config);
       // console.log('suppliers:', response);
        setSuppliers(response.data);
      }

    } catch (error) {
      console.error('Error fetching supplier:', error);
    }
  };

  const fetchUnits = async () => {
    try { if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/unitsbyclient/${clientId}`, config);
        //console.log('units:', response);
        setUnits(response.data);
      }

    } catch (error) {
      console.error('Error fetching unit:', error);
    }
  };

  const fetchUsers = async () => {
    try { if (clientId) {
        const response = await axios.get(`${BACKEND_URL}api/getGroupUsers/1`, config);
        console.log('users:', response);
        setUsers(response); }

    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({ data: { link: reader.result } });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }
    );
  };

  const [activite, setActivite] = useState([
    { id: 1, title: "Additional information on ingredients", name: "ingredientsInfo", description: "", editorState: EditorState.createEmpty(), },
    { id: 2, title: "Calculations and Measurements", name: "calculations", description: "", editorState: EditorState.createEmpty(), },
    { id: 3, title: "Compounding Method", name: "compoundingMethod", description: "", editorState: EditorState.createEmpty(), },
    { id: 4, title: "Quality Control", name: "qualityControl", description: "", editorState: EditorState.createEmpty(), },
    { id: 5, title: "Packaging", name: "packaging", description: "", editorState: EditorState.createEmpty(), },
    { id: 6, title: "Stability and Storage", name: "stabilityAndStorage", description: "", editorState: EditorState.createEmpty(), },
    { id: 7, title: "Labellingg", name: "labelling", description: "", editorState: EditorState.createEmpty(), },
    { id: 8, title: "Training", name: "training", description: "", editorState: EditorState.createEmpty(), },
    { id: 9, title: "References Consulted", name: "refs", description: "", editorState: EditorState.createEmpty(), },
  ]);


  const handleFormulaBatchChange = (e) => {
    setFormulaBatch(e.target.value);
    setDesiredBatch(e.target.value); // Update desiredBatch in real-time
  };

  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
  };

  const handleUnitFormulaChange = (e) => {
    setUnitFormula(e.target.value);
    setUnitDesired(e.target.value); // Update unitDesired in real-time
  };

  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);

  };

  const handleUserChange = (e, id) => {
    const selectedUserId = e.target.value; // Get the selected user ID
    setAuthorizeds(authorizeds.map(authorized =>
      authorized.id === id ? { ...authorized, userId: selectedUserId } : authorized
    ));
  };
  const getUserIdsString = () => {
    return authorizeds.map(authorized => authorized.userId).join(', ');
  };
//console.log('authorized:',authorizeds)
  // Get the comma-separated string of user IDs
  const userIds = getUserIdsString();
 // console.log('authorized:',userIds)
  // Add a new row
  const addAuthorizedRow = () => {
    setAuthorizeds([...authorizeds, { id: authorizeds.length + 1, userId: '' }]);
  };

  // Remove a row
  const removeAuthorizedRow = (id) => {
    setAuthorizeds(authorizeds.filter(authorized => authorized.id !== id));
  };
  const addFormulaRow = () => {
    setFormulaRows([
      ...formulaRows,
      {
        id: formulaRows.length + 1,
        name: '',
        manufacture: '',
        quantity: 0,
        unit: '',
        qteRequired: 0,
        lotNumber: '',
        expDate: '',
        check: isChecked // Utiliser l'état actuel de isChecked
      }
    ]);
    const addEquipmentRow = () => {
  setEquipmentRows([
    ...equipmentRows,
    {
      id: equipmentRows.length + 1,
      name: '',
      quantity: 0,
      check: isChecked // Utiliser l'état actuel de isChecked
    }
  ]);
};
  };
  const removeFormulaRow = (id) => {
    setFormulaRows(formulaRows.filter(formula => formula.id !== id));
  };
  const handleFormulaChange = (id, field, value) => {
    const updatedFormulaRows = formulaRows.map((formula) => {
      if (formula.id === id) {
        return { ...formula, [field]: value };  // Mise à jour du champ modifié
      }
      return formula;
    });
    setFormulaRows(updatedFormulaRows);  // Mise à jour de l'état avec la nouvelle valeur
  };
  const addOtherRow = () => {
    setOtherRows([...otherRows, { id: otherRows.length + 1 }]);
  };
  const removeOtherRow = (id) => {
    setOtherRows(otherRows.filter(other => other.id !== id));
  };
  const removeActivite = (id) => {
    setActivite(activite.filter((activite) => activite.id !== id));
  };



  const addColumn = () => {
    setColumns([...columns, <input className="form-control" type="text" placeholder="new column" />]);
    setIsColumnAdded(true);
  };

  const removeColumn = () => {
    setColumns(columns.slice(0, -1));
    setIsColumnAdded(false);
  };
  const handleColumnNameChange = (index, value) => {
    setColumns(columns.map((col, i) =>
      i === index ? { ...col, name: value } : col
    ));
  };
  const handleEquipementChange = (id, field, value) => {
    const updatedRows = equipmentRows.map(equip => {
      if (equip.id === id) {
        return {
          ...equip,
          [field]: value 
        };
      }
      return equip;
    });
    setEquipmentRows(updatedRows); 
  };
  
  const addEquipmentRow = () => {
    setEquipmentRows([
      ...equipmentRows,
      {
        id: equipmentRows.length + 1,
        name: '',
        quantity: 0,
        check: isChecked // Utiliser l'état actuel de isChecked
      }
    ]);
  };
  
  // Function to remove a row by id
  const removeEquipmentRow = (id) => {
    setEquipmentRows(equipmentRows.filter(row => row.id !== id));
  };
  const selectedEquipments = equipmentRows.map(row => row.name).filter(Boolean);
  const [formData, setFormData] = useState({
    productName: '',
    routeOfAdministration: '',
    pharmaForm: '',
    effectiveDate: new Date().toISOString().split('T')[0], 
    category: 1, 

  });
  const [existingProductNames, setExistingProductNames] = useState([]);
  const [productNameExists, setProductNameExists] = useState(false);

  // Récupérer les noms de produits existants au chargement
  useEffect(() => {
    const fetchProductNames = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}api/templates/${clientId}/1`, config);
       // console.log('productNames:', response );
        const productNames = response.map(item => item.productName.toLowerCase()); // Convertir en minuscules
        setExistingProductNames(productNames);
        //console.log('productNames:', productNames);
      } catch (error) {
        console.error('Error fetching product names:', error);
      }
    };

    if (clientId) {
      fetchProductNames();
    }
  }, [clientId]);

  // Modifier handleFormChange pour vérifier en temps réel
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'productName') {
      // Vérifier si le nom existe (ignorer la casse)
      const exists = existingProductNames.some(
        existingName => existingName === value.toLowerCase()
      );
      setProductNameExists(exists);
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };


  const handleDescriptionChange = (id, editorState) => {
    const newDescription = draftToHtml(convertToRaw(editorState.getCurrentContent()));

    setActivite((prevActivite) =>
      prevActivite.map((act) =>
        act.id === id
          ? {
            ...act,
            editorState, // Mise à jour de l'état de l'éditeur pour que le texte soit modifiable
            description: newDescription.trim() === "" ? "" : newDescription, // Si vide, définir comme ""
          }
          : act
      )
    );
  };


  const activiteData = activite.reduce((acc, act) => {
    acc[act.name] = act.description;
    return acc;
  }, {});
  console.log('activite', activiteData);
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsChecked(isChecked);
    
    // Mettre à jour formulaRows
    setFormulaRows(prevRows => 
      prevRows.map(row => ({
        ...row,
        check: isChecked ? true : false // Garder l'état actuel si on décoche
      }))
    );
  
    // Mettre à jour equipmentRows
    setEquipmentRows(prevRows => 
      prevRows.map(row => ({
        ...row,
        check: isChecked ? true : false // Garder l'état actuel si on décoche
      }))
    );
  
    // if (isChecked && formulaBatch && desiredBatch) {
    //   const scale = formulaBatch / desiredBatch;
    //   updateQuantityRequired(scale);
    // }
  };
  useEffect(() => {
    if (isChecked && formulaBatch > 0 && desiredBatch > 0) {
      const scale = formulaBatch / desiredBatch;

      const updatedFormulaRows = formulaRows.map((formula) => ({
        ...formula,
        qteRequired: formula.check ? formula.quantity * scale : formula.qteRequired
      }));

      setFormulaRows(updatedFormulaRows);
    }
  }, [isChecked, formulaBatch, desiredBatch, formulaRows]);

  useEffect(() => {
    setFormulaRows((prevRows) =>
      prevRows.map((row) => ({ ...row, check: isChecked }))
    );
  }, [isChecked]);
  const handleSubmit = (e) => {
    e.preventDefault();

    const templateData = {
      category: formData.category,
      pharmaForm: formData.pharmaForm,
      routeOfAdministration: formData.routeOfAdministration,
      effectiveDate: formData.effectiveDate,
      formulaBatchSize: parseInt(formulaBatch, 10), // 10 pour base décimale
      desiredBatchSize: parseInt(desiredBatch, 10),
      formulaBtachSizeUnit: unitFormula,
      desiredBtachSizeUnit: unitDesired,
      productName: formData.productName,
      preparedBy: 1,
      ingredients: formulaRows,
      equipements: equipmentRows,
      otherRows: otherRows,
      clientId: clientId,
      columns: columns,
      approvedBy: userIds,
      status: "Created",
      scal: isChecked ? 1 : 0,
      protocolNumber: "",
      parentId: '',
      checkedBy: 1,
      activite: activiteData,
      ...activiteData,
    };


    const config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      },
    };
   
    axios.post(`${BACKEND_URL}api/templates/new`, templateData, config)
      .then((response) => {
        console.log(response);
        setMessage('Master Formula Added successfully...');
        setAlert('success');
        setLoading(true);
    
        // Afficher le message pendant 3 secondes avant de naviguer
        setTimeout(() => {
          setLoading(false);
          navigate('/List-formula-template'); // Naviguer après 3 secondes
        }, 3000); 
  
      })
      .catch((error) => {
        if (error === "Request failed with status code 400")  {
        
          // Handle other types of errors
          setLoading(true);
          setAlert('danger');
          setMessage('Error updated Formula.');
      }            
      // Log the error for debugging
      console.error('Error  updated Formula:', error);
      setMessage('Error updated Formula.');
      setTimeout(() => {
          setLoading(false);
      }, 2000);
      });
  };
  const goBack = () => {
    navigate("/List-formula-template"); // Redirect after successful creation
  }
  const addRoute = () => {
    console.log('addRoute');
      };
      const addPharmaForm = async (nameEn) => {
        try {// Assurez-vous que clientId est récupéré correctement
      
          const pharmaceuticalToCreate = {
            nameEn: nameEn,
            clientId: clientId
          };
      
          const response = await axios.post(
            `${BACKEND_URL}api/pharmaceutical-forms`,
            pharmaceuticalToCreate,
            config
          );
      
          console.log('Pharmaceutical form created:', response.data);
      
          // Mettre à jour la liste des formes pharmaceutiques après la création
          setPharmaceuticals((prev) => [...prev, response.data]);
      
        } catch (error) {
          console.error('Error creating pharmaceutical form:', error);
        }
      };

  // Ajouter la fonction pour créer une nouvelle route
  const addRouteOfAdministration = async (name) => {
    try {
      const routeToCreate = {
        name: name,
        clientId: clientId
      };

      const response = await axios.post(
        `${BACKEND_URL}api/route-administrations`,
        routeToCreate,
        config
      );

      console.log('Route created:', response.data);
      
      // Mettre à jour la liste des routes après la création
      setRoutes((prev) => [...prev, response.data]);
      
    } catch (error) {
      console.error('Error creating route:', error);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Master Formula" breadcrumbItem="Create New Master Formula" />
          <div className="row-form mb-4">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 mt-2">
                <Col md={2}>
                  <img src={logobce} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                </Col>
                <Col md={8} className="row">
                  <label className="col-md-2 col-form-label">Compounded</label>
                  <div className="col-md-7">
                    <select className="form-select" name="category" value={formData.category}
                      onChange={handleFormChange} required>
                  
                      <option value={1} defaultChecked>Template Sterile</option>
                      <option value={2}>Template Non Sterile</option>
                    </select>
                    <p> <span className="float-start"></span> <span className="float-end"> Version: 1</span></p>
                  </div>
                  <label className="col-md-3 col-form-label">Preparation protocol</label>
                </Col>
                <Col md={2}>
                <img  src={logo?logo:logoorganization} alt="Logo Organization"  className="logo-organization" />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Header</h5>
                    <div className="text-center"> </div>
                    <CardBody>
                      <div>
                        <label htmlFor="nameFr" className="form-label">Name of Compounded Product:</label>
                        <input 
                          className={`form-control ${productNameExists ? 'is-invalid' : ''}`}
                          placeholder="Name of Compounded Product" 
                          type="text" 
                          name="productName" 
                          value={formData.productName}
                          onChange={handleFormChange} 
                          required 
                        />
                        {productNameExists && (
                          <div className="invalid-feedback">
                            This product name already exists.
                          </div>
                        )}
                      </div>
                      <Row className="mt-3">
                        <Col lg={4}>
                          <div>
                            <label htmlFor="nameFr" className="form-label">Pharmaceutical form: 
                             </label>
                            <CreatableSelect
                              isClearable
                              name="pharmaForm"
                              value={formData.pharmaForm ? { value: formData.pharmaForm, label: formData.pharmaForm } : null}
                              onChange={(selectedOption) => {
                                handleFormChange({  target: {  name: 'pharmaForm',  value: selectedOption ? selectedOption.value : ''   }  });
                              }}
                                onCreateOption={addPharmaForm}
                              options={pharmaceuticals.map((pha) => ({  value: pha.nameEn,  label: pha.nameEn   }))}
                              placeholder="Select or create a pharmaceutical form"
                            />
                           

                          </div>
                        </Col>
                        <Col lg={4}>

                          <div>
                            <label htmlFor="nameFr" className="form-label">Route Of Administration:  
                             
                             </label>
                            <CreatableSelect
                              isClearable
                              name="routeOfAdministration"
                              value={formData.routeOfAdministration ? 
                                { value: formData.routeOfAdministration, label: formData.routeOfAdministration } : 
                                null
                              }
                              onChange={(selectedOption) => {
                                handleFormChange({
                                  target: {
                                    name: 'routeOfAdministration',
                                    value: selectedOption ? selectedOption.value : ''
                                  }
                                });
                              }}
                              onCreateOption={addRouteOfAdministration}
                              options={routes.map((route) => ({
                                value: route.name,
                                label: route.name
                              }))}
                              placeholder="Select or create a route of administration"
                            />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="row">
                            <label htmlFor="example-date-input" className="form-label">Effective Date:</label>
                            <input className="form-control" type="date" name="effectiveDate" value={formData.effectiveDate}
                              onChange={handleFormChange} required disabled /> 

                          </div>
                        </Col>
                      </Row>
                      <div className="mt-3" >
                        <label className="form-label">Authorized By:</label>
                        {authorizeds.map((authorized) => (
                          <Row id="row-authorized" key={authorized.id} className="mb-3">
                            <Col lg={11}>
                              <div>

                                <select  className="form-select"   id={`nameFr-${authorized.id}`}
                                  value={authorized.userId || ''}  onChange={(e) => handleUserChange(e, authorized.id)} >
                                  <option value="">Select User</option>
                                  {users.map((user) => (
                                    <option value={user.idUser}  key={user.idUser}>
                                      {user.firstName} {user.lastName}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </Col>
                            <Col lg={1}>
                              <div>
                                {authorized.id === 1 ? (
                                  <button className="btn btn-primary waves-effect waves-light" type="button" onClick={addAuthorizedRow}>
                                    <i className="fa fa-plus" />  </button>
                                ) : (
                                  <button className="btn btn-danger waves-effect waves-light" type="button" onClick={() => removeAuthorizedRow(authorized.id)}>
                                    <i className="fa fa-minus" />   </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Formula</h5>
                    <CardBody>
                      <div className="form-check form-check-end">
                        <input  className="form-check-input"  type="checkbox"  id="checked"   name="isChecked"   onChange={handleCheckboxChange}  // Corrected onChange handler
                        />
                        <label className="form-check-label" htmlFor="defaultCheck2">Batch quantity auto calculate</label>
                      </div>
                      <Row className="mt-3">
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="formula_batch" className="col-md-8 col-form-label">Formula Batch Size:</label>
                            <div className="col-md-4">
                              <input id="formula_batch" className="form-control" type="text" value={formulaBatch}
                                onChange={handleFormulaBatchChange} name="formula_batch" required />
                            </div>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <select className="form-select" name="unit_formula" value={unitFormula} onChange={handleUnitFormulaChange}>
                              {units.map((item) => (<option value={item.shorthand} key={item.id}>{item.name}({item.shorthand})</option>))}
                            </select>
                          </div>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="desired_batch" className="col-md-8 col-form-label">Desired Batch Size:</label>
                            <div className="col-md-4">
                              <input id="desired_batch" className="form-control" type="text" value={desiredBatch}
                                onChange={handleDesiredBatchChange} name="desired_batch"  disabled/>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <select className="form-select" name="unit_desired" value={unitDesired} onChange={handleUnitDesiredChange} disabled>
                              {units.map((item) => (<option value={item.shorthand} key={item.id}>{item.name}({item.shorthand})</option>))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="formula">
                                <td scope="col" className="th-blue" hidden={!isChecked}>  #  </td> <td className="th-blue">Ingredients </td> 
                                 <td className="th-blue">Manufacture</td>  <td className="th-blue">Lot Number</td>
                                <td className="th-blue">Exp Date</td>  <td className="th-blue">Quantity</td>
                                <td className="th-blue">Units</td>  <td className="th-blue">Quantity Required</td> 
                                <td className="th-blue">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                            {formulaRows.map((formula) => {

                              const isFormIncomplete = !formula.name || !formula.manufacture || !formula.quantity || !formula.unit || !formula.qteRequired;

                              return (
                                <tr  key={formula.id} >
                                    <td scope="row" hidden={!isChecked} style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <input
                                      className="form-check-input"  type="checkbox"  name="check"
                                      onChange={(e) => handleFormulaChange(formula.id, 'check', e.target.checked)}
                                      defaultChecked disabled={!isChecked} 
                                    />
                                  </td>
                               
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <div className="d-flex align-items-center">
                                    <select className="form-select" name="ingredientName"  value={formula.name} 
                                     onChange={(e) => handleFormulaChange(formula.id, 'name', e.target.value)}
                                      required   >
                                      <option value="">Select Ingredient</option>
                                      {ingredients.map((item) => (
                                        <option key={item.id} value={item.drugName}>
                                          {item.drugName}  {item.drugForm} {item.concentration} {item.size}
                                        </option>
                                      ))}
                                    </select>
                                    <button className="btn btn-outline-primary waves-effect waves-light btn-plus" type="button" onClick={addPharmaForm}>
                                    <i className="fa fa-plus" />  </button>
                                     </div>
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <select   className="form-select"  name="manufacture"  value={formula.manufacture}  
                                      onChange={(e) => handleFormulaChange(formula.id, 'manufacture', e.target.value)}  required  >
                                      <option>Select option</option>
                                      {suppliers.map((item) => ( <option key={item.id} value={item.shortName}>   {item.name} ( {item.shortName}) </option>   ))}
                                    </select>
                                  </td>
                                  <td className="td-disabled"  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>{formula.lotNumber}</td>
                                  <td className="td-disabled"  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>{formula.expDate}</td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <input  className="form-control"  type="number" name="quantityIngredient" value={formula.quantity}
                                      placeholder="1"  onChange={(e) => handleFormulaChange(formula.id, 'quantity', e.target.value)} required  />
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <select
                                      className="form-select"
                                      name="unitIngredient"
                                      value={formula.unit}
                                      onChange={(e) => handleFormulaChange(formula.id, 'unit', e.target.value)}
                                      required
                                    >
                                      <option>Units</option>
                                      {units.map((item) => (
                                        <option value={item.shorthand} key={item.id}>{item.name}({item.shorthand})
                                        </option>
                                      ))}
                                    </select>
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <input  className="form-control" type="number"  name="qteRequired"
                                      value={parseFloat(formula.qteRequired).toFixed(0)} 
                                      onChange={(e) => {  const value = parseFloat(e.target.value);
                                        if (!isNaN(value)) {  handleFormulaChange(formula.id, 'qteRequired', value.toFixed(0));  }
                                      }}  required  disabled={formula.check ? true : false}   />
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}> 
                                    {/* checkbox pour check si l'ingrédient est obligatoire bien designer */}
                                   {formula.id === 1 ? (
                                      <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="button"
                                        onClick={addFormulaRow} disabled={isFormIncomplete}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="button"
                                        onClick={() => removeFormulaRow(formula.id)}
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}

                            </tbody>
                          </table>
                        </div>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Required equipment/materials</h5>
                    <CardBody>
                      <p className="card-title-desc">Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="Equipement">
                              <td scope="col" className="th-blue" hidden={!isChecked}>
                                   #
                                </td>
                                <td className="th-blue">Equipment/Materials</td>

                                {columns.map((column, index) => (
                                  <td className="th-blue" key={index} style={{ textTransform: "capitalize" }}>
                                    <Row>
                                      <Col lg={8}>
                                        {column.name === 'quantity' ? ( column.name) : (<input className="form-control" type="text" placeholder="New Column"
                                            value={column.name} onChange={(e) => handleColumnNameChange(index, e.target.value)} />)}
                                      </Col>
                                      <Col lg={4}>
                                        {column.name === 'quantity' ? (
                                          <button className="btn btn-white btn-add waves-effect waves-light" type="button" onClick={addColumn}   > <i className="fa fa-plus" /> </button>
                                        ) : (  <button   className="btn btn-danger btn-remove"  type="button" onClick={removeColumn} >  <i className="fa fa-minus" />  </button>
                                        )}
                                      </Col>
                                    </Row>
                                  </td>
                                ))}
                                <td className="th-blue">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                            {equipmentRows.map((equipement) => {
                            const isRowIncomplete = !equipement.name || !equipement.quantity;

                           return (
                          <tr  key={equipement.id}>
                            <td scope="row"  style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }} hidden={!isChecked}>
                            <input  className="form-check-input" type="checkbox"  name="check"
                              onChange={(e) => handleEquipementChange(equipement.id, 'check', e.target.checked)}
                              defaultChecked disabled={!isChecked}
                            />
                            </td>
                            <td  style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }}>
                              <select
                                className="form-select"
                                value={equipement.name}
                                onChange={(e) => handleEquipementChange(equipement.id, 'name', e.target.value)}
                              >
                                <option value="">Select equipment</option>
                                {/* Filter options to exclude already selected equipment */}
                                {equipements
                                  .filter(item => !selectedEquipments.includes(item.nameEn) || item.nameEn === equipement.name)
                                  .map((item) => (
                                    <option key={item.id} value={item.itemName}>
                                      {item.itemName}  {item.unit}  {item.size}
                                    </option>
                                  ))}
                              </select>
                            </td>
                            {columns.map((column, index) => (
                              <td key={index}  style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }}>
                                <input
                                  className="form-control"
                                  type={column.name === 'quantity' ? 'number' : 'text'}
                                  value={equipement[column.name] || ''}
                                  onChange={(e) => handleEquipementChange(equipement.id, column.name, e.target.value)}
                                />
                              </td>
                            ))}
                            <td className="text-end"  style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }}>
                              <button
                                className={`btn ${equipement.id === 1 ? 'btn-primary' : 'btn-danger'} waves-effect waves-light`}
                                type="button"
                                onClick={equipement.id === 1 ? addEquipmentRow : () => removeEquipmentRow(equipement.id)} disabled={isRowIncomplete}
                              >
                                <i className={`fa fa-${equipement.id === 1 ? 'plus' : 'minus'}`} />
                              </button>
                            </td>
                          </tr>
                             );
                           })}

                            </tbody>
                          </table>

                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {activite.map((item) => (
                  <Col lg={12} key={item.id}>
                    <Card className="card-bordred">
                      <h5 className="card-header">
                        <Row>
                          <Col md={11}>{item.title}</Col>
                          <Col md={1}>
                            <button  className="btn btn-danger waves-effect waves-light float-end"  type="button"  onClick={() => removeActivite(item.id)} >
                              <i className="fa fa-minus" /> </button>
                          </Col>
                        </Row>
                      </h5>
                      <CardBody style={{ background: "#fafafa" }}>
                        <Row>
                          <Col md={12}>
                          <Editor  editorState={item.editorState}  onEditorStateChange={(editorState) => handleDescriptionChange(item.id, editorState)}
                            toolbar={{
                              options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'image', 'emoji', 'list', 'textAlign', 'link'],
                              inline: { inDropdown: true },  color: true,  list: { inDropdown: true },  textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              image: {  uploadEnabled: true,  uploadCallback: uploadImageCallBack,   previewImage: true, alt: { present: true, mandatory: false } },
                            }}  />
                            
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                {otherRows.map((other) => (
                  <Col lg={12} key={other.id}>
                    <Card className="card-bordred">
                      <h5 className="card-header">
                        <Row>
                          <Col md={11}>
                            <input className="form-control" type="text" placeholder="Title" />
                          </Col>
                          <Col md={1}>
                            <button className={`btn ${other.id === 1 ? 'btn-primary' : 'btn-danger'} waves-effect waves-light float-end`} type="button" onClick={other.id === 1 ? addOtherRow : () => removeOtherRow(other.id)}>
                              <i className={`fa fa-${other.id === 1 ? 'plus' : 'minus'}`} />
                            </button>
                          </Col>
                        </Row>
                      </h5>
                      <CardBody style={{ background: "#fafafa" }}>
                        <Row>
                          <Col md={12}>

                          <Editor  
                            toolbar={{
                              options: ['inline', 'fontSize', 'fontFamily', 'colorPicker', 'image', 'emoji', 'list', 'textAlign', 'link'],
                              inline: { inDropdown: true },  color: true,  list: { inDropdown: true },  textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              image: {  uploadEnabled: true,  uploadCallback: uploadImageCallBack,   previewImage: true, alt: { present: true, mandatory: false } },
                            }}  />
                            
                          </Col>
                         
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}

                <Col lg={12} className="fixed-bottom-buttons">
                <Row>
                  <Col md={3}></Col>
                    <Col md={5}>
                      <div className="text-center">
                      {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="float-end">
                        <button type="submit" className="ms-1 btn btn-success me-3">Save</button>
                        <button type="reset" className="btn btn-info" onClick={() => goBack()}>Cancel</button>
                      </div>
                    </Col>
                  </Row>
                </Col>


              </Row>
            </Form>
          </div>
        </Container>
        
      </div>
    </React.Fragment>
  );
};

export default CreateNewTemplate;
