import React, { useState } from "react";
import { Container, Alert, Form, FormGroup, Row, Col, Card, CardBody,  Label, Input, Button } from "reactstrap"; // Import de l'Alert de Reactstrap et des composants nécessaires
import "./style.css";
//Import Breadcrumb
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { useParams } from 'react-router-dom';
import { BACKEND_URL } from "../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Profile = () => {

  document.title = "Profile | Quess Magistrale";
  const user = JSON.parse(localStorage.getItem("user"));
  const navigate = useNavigate();
  console.log('user',user);
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  // État pour le nom et le code PIN
  const [name, setName] = useState("");
  const [pin, setPin] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);
  const handleSubmit = async (e) => {
    e.preventDefault();
    
    // Récupérer les valeurs des champs de PIN
    const pinValues = [...Array(4)].map((_, index) => document.getElementById(`pin-${index}`).value);
    const pin = pinValues.join(''); // Combiner les valeurs en une seule chaîne

    console.log("Nom:", name, "Code PIN:", pin); // Afficher le nom et le code PIN

    try {
      const response = await axios.post(`${BACKEND_URL}api/profile/save`, {
        userId: user.id,
        codepin: pin // Utiliser le code PIN combiné
      }, config);

      console.log('response:', response);

      if (response.message=== "success") {
        setMessage('Profile saved successfully!');
        setAlert('success');
        localStorage.setItem("pinUser", response.pin);
        setLoading(true);
        navigate("/dashboard");
      }
    } catch (error) {
      console.error('Error profile saved:', error);
      setMessage('Failed to save profile.');
      setAlert('danger');
      setLoading(true);
      navigate("/dashboard");
    } finally {
      navigate("/dashboard");
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Quess" breadcrumbItem="Profile" />
       {!user ? (
            <Alert color="info">Loading Profile...</Alert>
          ) : error ? (
            <Alert color="danger">{error}</Alert>
          ) : (
            <>
            <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  {/* Avatar avec les initiales */}
                  <div className="d-flex">
                  <div className="ms-3">
                  <div className="avatar">
                    {user.firstname.charAt(0).toUpperCase()}{user.lastname.charAt(0).toUpperCase()}
                  </div>
                  </div>
                  <div className="flex-grow-1 align-self-center">
                  <div className="text-muted m-2 " style={{paddingLeft:"2%"}}>
                  <h5>Username: {user.firstname} {user.lastname}</h5>
                  <p>Email: {user.email}</p>
                  <p>Phone: {user.phone}</p>
                  </div>
                  </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <h4 className="card-title mb-4">Add/Update PIN Code</h4>
          <Row>
            <Col lg="12">
              <Card className="shadow-sm border-0 rounded-lg">
                <CardBody className="p-4" >
                  <div  style={{maxWidth:"50%",margin:"0% 22%"}}>
           
             {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}
      
                  <h4 className="text-center mb-4">Enter Your PIN Code</h4>
                  <Form onSubmit={handleSubmit} className="mt-4">
                    <FormGroup >
                      <div className="d-flex justify-content-between mb-3 pad-5" style={{maxWidth:"90%"}}>
                        {[...Array(4)].map((_, index) => (
                          <Input
                            key={index}
                            type="text"
                            id={`pin-${index}`}
                            maxLength={1}
                            onChange={(e) => {
                              const value = e.target.value;
                              if (value) {
                                // Move to the next input
                                const nextInput = document.getElementById(`pin-${index + 1}`);
                                if (nextInput) nextInput.focus();
                              }
                            }}
                            className="pin-input" // Classe personnalisée pour le style
                            placeholder="•"
                            style={{
                              width: "60px",
                              height: "60px",
                              textAlign: "center",
                              fontSize: "24px",
                              border: "2px solid #007bff",
                              borderRadius: "8px",
                              backgroundColor: "#f9f9f9",
                              transition: "border-color 0.3s",
                            }}
                            onFocus={(e) => e.target.style.borderColor = "#0056b3"}
                            onBlur={(e) => e.target.style.borderColor = "#007bff"}
                          />
                        ))}
                      </div>
                      <Button type="submit" color="primary" className="btn-lg w-100">Save</Button>
                    </FormGroup>
                  </Form></div>
                </CardBody>
              </Card>
            </Col>
          </Row>
      
          </> )}

 
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
