import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, CardBody, Modal } from 'reactstrap';
import axios from 'axios';
import { BACKEND_URL } from "../../../config/const";
import { useNavigate } from 'react-router-dom';
const BasicTable = ({ searchTerm, initialData,isDeleted, setIsDeleted }) => {
    const navigate = useNavigate();
    const userId=localStorage.getItem("userId")
    const [itemToDelete, setItemToDelete] = useState(null);
    const [refItem, setRefItem] = useState(null);
    const [modal_standard, setModalStandard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState(initialData);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);
    
   // console.log('userId:',userId)
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const toggleModal = () => {
        setModalStandard(!modal_standard);
    };
   // console.log("idd ::::",itemToDelete);
    // Delete item
  
    const goViewSheet = (id) => {
        console.log('id send', id);
        navigate(`/show-formula/${id}`); // Include id as a URL parameter
      };

    const goUpdateFormula = (id) => {
        console.log('id send', id);
        navigate(`/Verification-formula/${id}`); // Include id as a URL parameter
      };

    const columns = [
        {
            name: <span className='font-weight-bold fs-15'> Control #</span>,
            selector: row => (   <span className='fontTable' title={row.srNo}>   {row.srNo}  </span>),
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Name</span>,
            selector: row => (   <span className='fontTable' title={row.title}>   {row.title}  </span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Date Creation</span>,
            selector: row => {
                const date = new Date(row.createDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return <span className='fontTable'>{`${day}-${month}-${year}`}</span>;
            },
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Created by</span>,
            selector: row => (<span className='fontTable'>{row.createdBy}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Status</span>,
            selector: cell => {
                switch (cell.priority) {
           
                    case "Rejected":
                        return <span className="badge bg-warning"> Correction in Progress </span>;
                    case "Staging":
                        return <span className="badge bg-info"> {cell.priority} </span>;
                    case "Staging+":
                        return <span className="badge bg-primary"> Staging after Correction </span>;
                   
                }
            },
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: row => (
                <div className="d-flex gap-2">
                    {/* <div className="edit">
                        <button className="btn btn-outline-success waves-effect waves-light" title="View"  onClick={() => goViewSheet(row.id)} >
                        <i className="mdi mdi-eye"></i>
                        </button>
                    </div> */}
                  
                    <div className="edit">
                        <button className="btn btn-outline-info waves-effect waves-light" title="Verification"  onClick={() => goUpdateFormula(row.id)} disabled={ row.createdID == userId} >
                         <i className="ri-file-search-line align-bottom"></i> Verification
                        </button>
                    </div>
                    <div className="remove">
         
                    </div>
            
                </div>
            ),
            className: 'table-header'
        }
    ];

    const [filteredData, setFilteredData] = useState(initialData);

    useEffect(() => {
        setFilteredData(
            initialData.filter(item =>
                item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createdBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(item.createDate).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.priority.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, initialData]);

    return (
        <DataTable
            columns={columns}
            data={filteredData}
            pagination
        />
    );
};

export { BasicTable };
