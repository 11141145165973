import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import { connect } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import NotificationDropdown from "../../components/Common/TopbarDropdown/NotificationDropdown";
import axios from "axios";
import { BACKEND_URL, BACKEND_Externe_URL } from "../../config/const.js";
import { withTranslation } from "react-i18next";
//import images
import logoSm from "../../assets/images/logo-sm.png";
import logoFrDark from "../../assets/images/logo-dark.png";
import logoDark from "../../assets/images/QUESS_COMPOUNDING.png";

// import logoLight from "../../assets/images/logo-light.png";
// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
} from "../../store/actions";
import { useTranslation } from "react-i18next";

const Header = (props) => {
  const [loading, setLoading] = useState(true);
  const [organizations, setOrganization] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();
  const lang = localStorage.getItem("lang");
  const [clientId, setClientId] = useState(null);
  const [logoOrganization, setLogoOrganization] = useState(null);
  const [nameOrganization, setNameOrganization] = useState(null);
  const [groups,setGroups] = useState([]);
  const [admin,setAdmin] = useState(false);
  const[BceAdmin,setBceAdmin]= useState(false)
  const [selectedGroup, setselectedGroup] = useState({
    label: t("selectOrg"),
    value: 0,
  });
  const navigate = useNavigate();

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const isnotif = localStorage.getItem("Isnotif");
  // Fetch User Profile
  useEffect(() => {
    const fetchUserProfile = async () => {
      try {
        const response = await axios.get(`${BACKEND_Externe_URL}connectedUserInfo`, config);
        localStorage.setItem("user", JSON.stringify(response));
        localStorage.setItem("userId", JSON.stringify(response.id));
        console.log("User profile data:", response);
        if (response) {
          const pinresponse = await axios.get(`${BACKEND_URL}api/getPin`, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
            params: { userId: response.id }
          });
          localStorage.setItem("pinUser", pinresponse.data);
         // console.log("pin:", pinresponse);
        }
        setLoading(false);
      } catch (error) {
        console.error("Error fetching profile data:", error.response ? error.response : error.message);
       
        setLoading(false);
      }
    };
    fetchUserProfile();
  }, []);

  // Retrieve client from localStorage
  useEffect(() => {
    const client = localStorage.getItem("currentOrg");
    if (client) {
      setClientId(client);
    }
  }, []);

  //const IdClient = localStorage.getItem("currentOrg");
  const fetchGroups = async () => {
    const data = {
      currentOrg: localStorage.getItem("currentOrg"),
    };

    try {
      const response = await axios.post(`${BACKEND_URL}api/user/isAdmin`,data,  config);
      localStorage.setItem("roleUser", response);
      console.log('roleUser:',response)
    switch (response) {
      
      case "BCEAdmin":
      setBceAdmin(true)
    
      break;
      case "Admin": 
        setAdmin(true)
      break;
    }
    const groupsData = await axios.get(`${BACKEND_URL}api/clients`,  config);
    setGroups(groupsData)
       // Forcer le rechargement de la page
    //    window.location.reload();
    // window.reload()
    } catch (error) {
      console.error("Error fetching profile data:", error.response ? error.response : error.message);
    
    }
};

useEffect(() => {
  const currentOrg = localStorage.getItem("currentOrg");
  if (currentOrg) { // Vérifiez si currentOrg n'est pas null
    fetchGroups();
  }
}, [clientId]);
  // Fetch Organizations
  useEffect(() => {
    const fetchOrganization = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}api/user/organisations`, config);
        if (response && response.length > 0) {
          const orgOptions = response.map((org) => ({
            label: org.name,
            value: org.id,
          }));
          setOrganization(orgOptions);
          if(!localStorage.getItem("currentOrg"))
          {

            localStorage.setItem("currentOrg",response[0].id)
          }
          // Check if a group was previously selected and set it
          const clientId = localStorage.getItem("currentOrg")?localStorage.getItem("currentOrg"):response[0].id;
          if (clientId) {
            fetchLogo();
            fetchGroups();
            const selectedOrg = orgOptions.find((org) => org.value === parseInt(clientId));
            setselectedGroup(selectedOrg || orgOptions[0]);
          } else {
            setselectedGroup(orgOptions[0]);
          }
          setLoading(false);
        } else {
          setError("No organizations found");
          setLoading(false);
        }
      } catch (error) {
        setError("Failed to load organizations");
        setLoading(false);
      }
    };

    fetchOrganization();
  }, []);

  // Handle Group Selection
  function handleSelectGroup(selectedOption) {
    console.log("Selected organization:", selectedOption);
    setselectedGroup(selectedOption);
    localStorage.setItem("currentOrg", selectedOption.value);
    setClientId(selectedOption.value);
  }

  // Fetch Organization Logo
  const fetchLogo = async () => {
    const clientId = localStorage.getItem("currentOrg")
      try {
        console.log("Fetching logo for organization:", clientId);
        const response = await axios.get(`${BACKEND_URL}api/client/${clientId}`, config);

        if (response && response.logoUrl) {
          const logoUrl = response.logoUrl;
          setLogoOrganization(logoUrl);
          setNameOrganization(response.name);
          localStorage.setItem("logoOrg", logoUrl);
          localStorage.setItem("organizationName", response.name);
          console.log("Organization logo URL:", logoUrl);
          // navigate('/');
        } else {
          console.error("Logo URL not found in the response");
        }
      } catch (error) {
        console.error("Error fetching logo:", error.response ? error.response : error.message);
      }
 
  };

  useEffect(() => {
    fetchLogo();
  }, [clientId]);

  // Sidebar Toggle Function
  function tToggle() {
    const body = document.body;
    if (window.screen.width <= 998) {
      body.classList.toggle("sidebar-enable");
    } else {
      body.classList.toggle("vertical-collpsed");
      body.classList.toggle("sidebar-enable");
    }
  }

  // Language Change Function
  const changeLanguage = () => {
    const currentLang = localStorage.getItem("lang");
    const newLang = currentLang === "en" ? "fr" : "en";
    localStorage.setItem("lang", newLang);
    window.location.reload();
  };

  // Logout Function
  const handleLogout = () => {
    navigate("/logout");
  };

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">
            <div className="navbar-brand-box text-center">
              <Link to="/" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSm} alt="logo-sm-dark" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={lang === "fr" ? logoFrDark : logoDark} alt="logo-dark" height="57" />
                </span>
              </Link>

              <Link to="/" className="logo logo-light">
                <span className="logo-sm">
                  <img src={lang === "fr" ? logoFrDark : logoDark} alt="logo-sm-light" height="22" />
                </span>
                <span className="logo-lg">
                  <img src={lang === "fr" ? logoFrDark : logoDark} alt="logo-light" height="57" />
                </span>
              </Link>
            </div>

            <button  type="button"  className="btn btn-sm px-3 font-size-24 header-item waves-effect"  id="vertical-menu-btn"  onClick={tToggle}  >
              <i className="ri-menu-2-line align-middle"></i>
            </button>

            <span className="logo-lg">
              {logoOrganization ? (
                <img src={`${BACKEND_URL}${logoOrganization}`} alt={nameOrganization} height="65" className="logo-org" />
              ) : (
                <h4 style={{ color: "white", marginTop: 22 }}>{nameOrganization ? nameOrganization : "Logo Organization"}</h4>
              )}
            </span>
          </div>

          <div className="d-flex align-items-center">
            <Label style={{ marginRight: "8px", color: "white" }}>{t("organisation")}:</Label>
            <form className="app-search d-none d-lg-block">
              {loading ? (
                <p>Loading organizations...</p>
              ) : error ? (
                <p>{error}</p>
              ) : (
                <Select value={selectedGroup}  onChange={handleSelectGroup}  options={organizations} 
                 classNamePrefix="select2-selection"  placeholder={t("selectOrg")}   />
              )}
            </form>

            <div className="dropdown d-none d-lg-inline-block ms-1" style={{ display: "flex", flexDirection: "row" }}>
              <button type="button" className="btn header-item noti-icon" data-toggle="translate" onClick={changeLanguage}>
                <i className="bx bx-globe" />
                <div style={{ display: "flex", flexDirection: "row" }}>
                  <p style={{ color: "white", textDecoration: lang === "en" ? "underline" : "none" }}>EN</p> /
                  <p style={{ color: "white", textDecoration: lang === "fr" ? "underline" : "none" }}>FR</p>
                </div>
              </button>
            </div>

            <NotificationDropdown />
            

            <button type="button"  onClick={handleLogout}   className="btn header-item noti-icon right-bar-toggle waves-effect"   >
              <i className="dripicons-enter"></i>
            </button>
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
