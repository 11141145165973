import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,HIDE_DIALOG,SHOW_DIALOG
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  dialog: {
    visible: false,
    message: '',
    isError: false,
  },
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGOUT_USER:
      state = { ...state, isUserLogout: false };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state, isUserLogout: true };
      break;
    case API_ERROR:
      state = {
        ...state,
        error: action.payload,
        loading: false,
        isUserLogout: false,
      };
      break;
      case SHOW_DIALOG:
        return {
          ...state,
          dialog: { 
            visible: true, 
            message: action.payload.message || '', 
            isError: action.payload.isError 
          },
        };
      case HIDE_DIALOG:
        return {
          ...state,
          dialog: { 
            visible: false, 
            message: '', 
            isError: false 
          },
        };
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
