import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import '../table.css'
 // Assurez-vous de bien inclure votre fichier CSS
 import { useTranslation } from 'react-i18next';
 import { useNavigate } from 'react-router-dom';
import DeleteDialog from './DeleteDialog'; // Import the dialog component

const BasicTable = ({ searchTerm,initialData,isBce,fetchGroups }) => {
    const navigate = useNavigate();
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [groupId, setgroupId] = useState(null);
    const {t} = useTranslation();
    const paginationOptions = {
        rowsPerPageText: t('rowsPerPage'),
        rangeSeparatorText: t('of'),
        selectAllRowsItem: true,
        selectAllRowsItemText: t('all'),
    };
    const goToUpdateGroup = (groupId) => {
        
        localStorage.setItem('groupId',groupId)
          navigate(`/update-group/${groupId}`);
        
      };

      const handleOpenDialog = (id) => {
        setgroupId(id); // Pass User ID to dialog
        setIsDialogOpen(true);
      };
    
      const handleCloseDialog = () => {
        setIsDialogOpen(false);
        setgroupId(null); // Reset groupId after closing dialog
      };
    
    const columns = [
    
        {
            name: <span className='font-weight-bold fs-15'>ID #</span>,
            selector: row => row.id,
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.id - b.id, 

        },
        {
            name: <span className='font-weight-bold fs-14'>{t('name')}</span>,
            selector: row => (<span className='fontTable' >{row.name}</span>),
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.name.localeCompare(b.name), 

        },
       
        {
            name: <span className='font-weight-bold fs-15'>{t('actifUsers')}</span>,
            selector: row => (<span className='fontTable'>{row.actifUsers}</span>),
            sortable: true,
            className: 'table-header',
            sortFunction: (a, b) => a.actifUsers - b.actifUsers, 
        },
       
        {
            name: <span className='font-weight-bold fs-13'>{t('action')}</span>,
            sortable: true,
            cell: row => (
                <div className="d-flex gap-2">
                    <div className="edit">
                        <button className="btn btn-outline-info waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#showModal" 
                         onClick={()=>goToUpdateGroup(row.id)} title={t('update')} >
                            <i className="ri-pencil-fill align-bottom  "></i>
                        </button>
                    </div>
                     <div className="remove">
                            <button className="btn btn-outline-danger waves-effect waves-light" data-bs-toggle="modal" 
                            
                            data-bs-target="#deleteRecordModal"  onClick={() => handleOpenDialog(row.id)}title={t('delete')} >
                                <i className="ri-delete-bin-fill align-bottom  "></i>
                            </button>
                        </div>
                    
                  
                      {/* Delete Dialog - controlled via state */}
                    {isDialogOpen && (
                        <DeleteDialog
                        isOpen={isDialogOpen}
                        handleClose={handleCloseDialog}
                        groupId={groupId} // Pass groupId to the dialog
                        fetchGroups={fetchGroups}
                        />
                    )}
                </div>
            ),
            className: 'table-header'
        }
    ];

 

    const [filteredData, setFilteredData] = useState([]);
  
    useEffect(() => {
       
            setFilteredData(
                initialData.filter(item =>
                    item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
                    item.actifUsers.toString().includes(searchTerm.toLowerCase()) 
                )
            );
       
      
    }, [searchTerm]);
console.log(filteredData)
    return (
        <DataTable
            columns={columns}
            data={filteredData.length>1?filteredData:initialData}
            pagination
            paginationPerPage={50} // Set default rows per page to 50
            paginationRowsPerPageOptions={[10, 25, 50, 100, 300]} // Provide options for rows per page
            paginationComponentOptions={paginationOptions} // Custom pagination options
            noDataComponent={t('noGroupsFound')} // Custom translation key

        />
    );
};

export { BasicTable };
