import { call, put, takeEvery } from "redux-saga/effects";
import axios from "axios";
// Login Redux States
import { LOGIN_USER, LOGOUT_USER } from "./actionTypes";
import { apiError, loginSuccess, logoutUserSuccess,showDialog } from "./actions";
import { BACKEND_Externe_URL,BACKEND_URL } from "../../../config/const";
import { ErrorMessage } from "formik";
//Include Both Helper File with needed methods
//import {  postFakeLogin,  postJwtLogin,  } from "../../../helpers/fakebackend_helper";

function* loginUser({ payload: { user, history } }) {
  try {
    console.log('start ', user);

    // Perform the login API call
    const response = yield call(axios.post, BACKEND_Externe_URL + "login", {
      username: user.username,
      password: user.password,
    });

    console.log('data ', response);

    // Save response data to localStorage
    localStorage.setItem("authUser", JSON.stringify(response.token));
    localStorage.setItem("token", response.token);
    localStorage.setItem("roles", JSON.stringify(response.roles));
    console.log('roles:', localStorage.getItem("roles"));

    // Dispatch a login success action
    yield put(loginSuccess(response.token));
    console.log('token ', response.token);

    // Dispatch success message
    yield put(showDialog('Login successful!', false));

    // Perform additional functions after a successful login
    const whereToGo= yield call(handlePostLoginTasks, response,user.username);
    console.log(whereToGo)

    // Navigate to the dashboard
    history(whereToGo);
  } catch (error) {
    console.log('error: ', error);
 // Assurons-nous d'avoir toujours un message d'erreur

 const errorMessage = error =='Request failed with status code 401' ? 'Invalid username or password' : 'An error occurred during login';
 // Dispatch an error action
   yield put(apiError(errorMessage));

    // Dispatch error message
    yield put(showDialog(errorMessage, true));
  }
}
function* handlePostLoginTasks(response,userName) {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${response.token}`,
      },
    };
    // Example: Fetch user profile data
    const isLegible = yield call(axios.post, BACKEND_URL + "api/user/isEligible", {},config);
    //yield put(setUserProfile(userProfile));
    if(isLegible.isEligible)
    {
      if(isLegible.hasPasswordChange)
      {
        return "/change-password"
      }
      const hasMFA = yield call(axios.get, BACKEND_URL + "api/user/has2FA/Connected",config);
      console.log(hasMFA)
      if(hasMFA.data==false)
      {
       return "/dashboard"
      }
      else {
        const dataEmail= {
          username:  userName,
          lang: localStorage.getItem("lang")?localStorage.getItem("lang"):"en"
        }
        const hasMFA = yield call(axios.post, BACKEND_URL + "api/user/sendCode2FA",dataEmail,config);
        return "/MFA"
      }
    }
    // Example: Perform other tasks like fetching initial app data
   

    // Add any other functions you want to call after a successful login
  } catch (error) {
    console.log('Post-login tasks failed:', error);
    // Handle any errors in the additional tasks
  }
}

function* logoutUser() {
  try {
    localStorage.removeItem("authUser");
    localStorage.removeItem("token");
    localStorage.removeItem("roles");
    localStorage.removeItem("user");
    localStorage.removeItem("currentOrg");
    localStorage.removeItem("logoOrg");
    if (process.env.REACT_APP_DEFAULTAUTH !== "firebase") {
      yield put(logoutUserSuccess(LOGOUT_USER, true));
    }
  } catch (error) {
    yield put(apiError(LOGOUT_USER, error));
  }
}


function* authSaga() {
  yield takeEvery(LOGIN_USER, loginUser);
  yield takeEvery(LOGOUT_USER, logoutUser);
}

export default authSaga;
