import React from 'react';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.error("Error caught by Error Boundary: ", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      // Render any custom fallback UI
      return (
        <div style={{ textAlign: 'center', marginTop: '50px' }}>
          <h1>404 - Page Not Found</h1>
          <p>Oops! Something went wrong.</p>
        </div>
      );
    }

    // If no error, render children components normally
    return this.props.children;
  }
}

export default ErrorBoundary;
