import { useState, useEffect } from 'react';
import axios from 'axios';

export const useDataFetching = (endpoint, clientId, config) => {
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [lastFetched, setLastFetched] = useState(null);

  // Cache duration in milliseconds (e.g., 5 minutes)
  const CACHE_DURATION = 5 * 60 * 1000;
  const CACHE_KEY = `${endpoint}_${clientId}`;

  // Load data from localStorage on init
  useEffect(() => {
    const cachedData = localStorage.getItem(CACHE_KEY);
    if (cachedData) {
      const { data: storedData, timestamp } = JSON.parse(cachedData);
      if (Date.now() - timestamp < CACHE_DURATION) {
        setData(storedData);
        setIsLoading(false);
        setLastFetched(timestamp);
        return;
      }
    }
    fetchData(true);
  }, [clientId, endpoint]);

  const fetchData = async (force = false) => {
    if (!force && lastFetched && Date.now() - lastFetched < CACHE_DURATION) {
      return;
    }

    setIsLoading(true);
    setError(null);

    try {
      if (!clientId) {
        throw new Error('Client ID is required');
      }

      const response = await axios.get(endpoint, config);
      setData(response.data);
      
      // Save to localStorage
      localStorage.setItem(CACHE_KEY, JSON.stringify({
        data: response.data,
        timestamp: Date.now()
      }));
      
      setLastFetched(Date.now());
    } catch (err) {
      setError(err.message || 'Error fetching data');
      console.error('Error fetching data:', err);
    } finally {
      setIsLoading(false);
    }
  };

  const updateLocalData = (newData) => {
    setData(newData);
    localStorage.setItem(CACHE_KEY, JSON.stringify({
      data: newData,
      timestamp: Date.now()
    }));
  };

  return {
    data,
    setData: updateLocalData,
    isLoading,
    error,
    refetch: () => fetchData(true),
  };
};

export default useDataFetching; 