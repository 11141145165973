import React, { useState, useEffect, version } from "react";
import Creatable from 'react-select/creatable';
// import Switch from '@mui/material/Switch';
import { Card, CardBody, Form, Col, Row, Container,Alert , Modal, Input, ModalBody,ModalFooter, ModalHeader } from "reactstrap";
import logobce from "../../../assets/images/logo_bce.png";
import RichTextEditor from '../EditorText';
import logoorganization from "../../../assets/images/organization_logo.png";
import axios from 'axios';
import '../style.css';
import { BACKEND_URL} from "../../../config/const.js";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { EditorState, convertToRaw } from 'draft-js';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import draftToHtml from 'draftjs-to-html';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { useNavigate } from 'react-router-dom';
import { Icon } from "@material-ui/core";
import CreatableSelect from 'react-select/creatable';
import { useDataFetching } from '../../../hooks/useDataFetching';
import AddIngredientModal from '../../../components/Mgistrale/AddIngredientModal.js';
import AddManufacturerModal from "../../../components/Mgistrale/AddManufacturerModal.js";
import AddUnitModal from '../../../components/Mgistrale/AddUnitModal.js';
import AddEquipmentModal from "../../../components/Mgistrale/AddEquipmentModal.js";
import { useParams } from 'react-router-dom';
const UpdateTemplate = () => {
  const { id } = useParams();
  document.title = "Update Formula | Quess Magistrale";
  const navigate = useNavigate();
  const [routeNames, setRouteNames] = useState([{ id: 1, name: '' }]);
  const [authorizeds, setAuthorizeds] = useState([{ id: 1, userId: null }]);
  const [isChecked, setIsChecked] = useState(false);
  const [newIngredient, setNewIngredient] = useState({ drugName: '', drugForm: '', concentration: '', size: '', clientId: '' });
  const [newManufacturer, setNewManufacturer] = useState({ name: '', shortName: '' });
  const [newEquipment, setNewEquipment] = useState({ itemName: '', unit: '', size: '', clientId: '' });
  const [formulaRows, setFormulaRows] = useState([
    { id: 1, name: '', manufacture: '', quantity: 0, qs: false, unit: '', qteRequired: 0, lotNumber: '', expDate: '', check: false }
  ]);
  const [formulaDetail, setFormulaDetail] = useState(null);
  const [comments, setComments] = useState(null);
  const [newUnit, setNewUnit] = useState({ name: '', shorthand: '', clientId: '' });
  const [equipmentRows, setEquipmentRows] = useState([  { id: 1, name: '', quantity: 0, check: false }  ]);
  const [otherRows, setOtherRows] = useState([{ id: 1, title: '', description: '' }]);
  const [formulaBatch, setFormulaBatch] = useState('');
  const [desiredBatch, setDesiredBatch] = useState('');
  const [unitFormula, setUnitFormula] = useState('Units');
  const [unitDesired, setUnitDesired] = useState('Units');
  const [selectedIds, setSelectedIds] = useState([]);
  //const [columns, setColumns] = useState(['Quantity']);
  const [isColumnAdded, setIsColumnAdded] = useState(false);
  const [columns, setColumns] = useState([{ name: 'quantity' }]); // Initial column names
  const [modal_addIngredient, setmodal_addIngredient] = useState(false);
  const [modal_addManufacturer,setmodal_addManufacturer]=useState(false);
  const [modal_addEquipment,setmodal_addEquipment]=useState(false);
  const [modal_addUnit,setmodal_addUnit]=useState(false);
  const [clientId, setClientId] = useState(null);
  const [users, setUsers] = useState([]);
  const [logo, setLogo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);
  const [username, setUsername] = useState(null);
  const [data, setData] = useState("");
  const [editorData, setEditorData] = useState(''); // Initialize to an empty string
  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  const user= JSON.parse(localStorage.getItem("user"));
  useEffect(() => {
   // console.log('user connectee', user);
    setUsername(user.username)
   // console.log('user connectee', username);
    }, [user]);
  const client = JSON.parse(localStorage.getItem("currentOrg"));

  const { data: equipements, setData: setEquipments, refetch: refetchEquipments, isLoading: isEquipmentsLoading } = useDataFetching(
    clientId ? `${BACKEND_URL}api/equipementsbyclient/${clientId}` : null,
    clientId,
    config
  );

  const { data: ingredients, setData: setIngredients, refetch: refetchIngredients, isLoading: isIngredientsLoading } = useDataFetching(
    clientId ? `${BACKEND_URL}api/ingredientsbyclient/${clientId}` : null,
    clientId,
    config
  );

  const { data: pharmaceuticals, setData: setPharmaceuticals, refetch: refetchPharmaceuticals, isLoading: isPharmaceuticalsLoading, error: pharmaceuticalsError } = useDataFetching( clientId ?
      `${BACKEND_URL}api/pharmaceutical-formsbyclient/${clientId}`:null,
    clientId,
    config
  );

  const { data: routes, setData: setRoutes, isLoading: isRoutesLoading, error: routesError } = useDataFetching( clientId ?
    `${BACKEND_URL}api/route-administrationsbyclient/${clientId}`:null,
    clientId,
    config
  );

  const { data: suppliers, setData: setSuppliers, refetch: refetchSuppliers, isLoading: isSuppliersLoading } = useDataFetching(
    clientId ? `${BACKEND_URL}api/manufacturersbyclient/${clientId}` : null,
    clientId,
    config
  );

  const { data: units, setData: setUnits, refetch: refetchUnits, isLoading: isUnitsLoading } = useDataFetching(
    clientId ? `${BACKEND_URL}api/unitsbyclient/${clientId}` : null,
    clientId,
    config
  );
  const fetchUsers = async () => {
    try { if (client) {
      console.log('start fetch users');
      
        const response = await axios.get(`${BACKEND_URL}api/getGroupUsers/1`, config);
        console.log('users:', response);
        setUsers(response); }

    } catch (error) {
      console.error('Error fetching user:', error);
    }
  };

  const formatDate = (dateString) => {
    // Split the date and time
    const [datePart, timePart] = dateString.split(' ');
    const [day, month, year] = datePart.split('-');
    
    // Create a new Date object
    const date = new Date(year, month - 1, day, ...timePart.split(':'));
  
    // Format the date to a more readable format
    return date.toLocaleString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      timeZoneName: 'short'
    });
  };
  useEffect(() => {
  console.log('fetch users logs');
  fetchUsers();
  }, [client]);
  useEffect(() => {
    const client = JSON.parse(localStorage.getItem("currentOrg"));
    const logoOrg = localStorage.getItem("logoOrg");
    
    if (logoOrg) {
      setLogo(`${BACKEND_URL}${logoOrg}`);
    }
    if (client) {
      setClientId(client);
    }
  }, [client]);

  const uploadImageCallBack = (file) => {
    return new Promise(
      (resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          resolve({ data: { link: reader.result } });
        };
        reader.onerror = reject;
        reader.readAsDataURL(file);
      }
    );
  };
  const fetchFormulaDetail = async () => {
    setLoading(true); // Set loading to true before the request starts
    try {
      const response = await axios.get(`${BACKEND_URL}api/template/${id}`, config);
      const data = response; // Extract data from the response object
      //console.log('data Formula:',data);
      setFormulaDetail(data);
      setFormulaRows(data.ingredients || []); 
      setEquipmentRows(data.equipements) ;
      setFormulaBatch(data.formulaBatchSize);
      setDesiredBatch(data.desiredBatchSize);
      setUnitFormula(data.formulaBtachSizeUnit);
      setUnitDesired(data.desiredBtachSizeUnit);
      setComments(data.historical);
      setOtherRows(data.others);
      setIsChecked(data.scal);
      const updatedAuthorizeds = data.approvedBy.map((user, index) => ({
        id: index + 1, // Assuming you want id to start from 1
        userId: user.id, // Setting userId to the id from approvedBy
      }));
      setAuthorizeds(updatedAuthorizeds);// Safely handle ingredients if available
     // console.log('Formula Detail:', data);
      updateDescriptionsFromData(data);
    } catch (error) {
      console.error('Error fetching sheet detail:', error);
    } finally {
      setLoading(false); // Turn off the loading state after the request completes
    }
  };
  const updateDescriptionsFromData = (data) => {
    setActivite((prevActivite) =>
      prevActivite.map((act) => {
        let newDescription = '';
        if (act.name === 'training' && data.training) {
          newDescription = data.training;
        } else if (act.name === 'packaging' && data.packaging) {
          newDescription = data.packaging;
        } else if (act.name === 'ingredientsInfo' && data.ingredientsInfo) {
          newDescription = data.ingredientsInfo;
        } else if (act.name === 'calculations' && data.calculations) {
          newDescription = data.calculations;
        } else if (act.name === 'compoundingMethod' && data.compoundingMethod) {
          newDescription = data.compoundingMethod;
        } else if (act.name === 'qualityControl' && data.qualityControl) {
          newDescription = data.qualityControl;
        } else if (act.name === 'stabilityAndStorage' && data.stabilityAndStorage) {
          newDescription = data.stabilityAndStorage;
        } else if (act.name === 'labelling' && data.labelling) {
          newDescription = data.labelling;
        } else if (act.name === 'refs' && data.refs) {
          newDescription = data.refs;
        }
  
        return {
          ...act,
          description: newDescription || '', // Met à jour la description
        };
      })
    );
  };
  useEffect(() => {
    if (id) {
      fetchFormulaDetail();  // Only fetch details if an ID is present
    }
  }, [id]);
  const [activite, setActivite] = useState([
    { id: 1, title: "Additional information on ingredients", name: "ingredientsInfo", description: "" },
    { id: 2, title: "Calculations and Measurements", name: "calculations", description: "" },
    { id: 3, title: "Compounding Method", name: "compoundingMethod", description: "" },
    { id: 4, title: "Quality Control", name: "qualityControl", description: "" },
    { id: 5, title: "Packaging", name: "packaging", description: "" },
    { id: 6, title: "Stability and Storage", name: "stabilityAndStorage", description: "" },
    { id: 7, title: "Labelling", name: "labelling", description: "" },
    { id: 8, title: "Training", name: "training", description: "" },
    { id: 9, title: "References Consulted", name: "refs", description: "" },
  ]);

  useEffect(() => {
    if (formulaDetail) {
      const formattedEffectiveDate = formulaDetail.effectiveDate 
        ? formulaDetail.effectiveDate.split(' ')[0] 
        : '';

      setFormData({
        routeOfAdministration: formulaDetail.routeOfAdministration || '',
        pharmaForm: formulaDetail.pharmaForm || '',
        effectiveDate: formattedEffectiveDate,
        version: formulaDetail.version || '',
        productName:formulaDetail.productName || '',
        protocolNumber:formulaDetail.protocolNumber||'',
        scal:formulaDetail.scal|| 0

      });
    }
  }, [formulaDetail]);
  const handleFormulaBatchChange = (e) => {
    setFormulaBatch(e.target.value);
    setDesiredBatch(e.target.value); // Update desiredBatch in real-time
  };

  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
  };

  const handleUnitFormulaChange = (e) => {
    setUnitFormula(e.target.value);
    setUnitDesired(e.target.value); // Update unitDesired in real-time
  };

  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);

  };

  const handleUserChange = (e, id) => {
    const selectedUserId = e.target.value; // Get the selected user ID
    setAuthorizeds(authorizeds.map(authorized =>
      authorized.id === id ? { ...authorized, userId: selectedUserId } : authorized
    ));
  };
  const getUserIdsString = () => {
    return authorizeds.map(authorized => authorized.userId).join(', ');
  };
//console.log('authorized:',authorizeds)
  // Get the comma-separated string of user IDs
  const userIds = getUserIdsString();
 // console.log('authorized:',userIds)
  // Add a new row
  const addAuthorizedRow = () => {
    setAuthorizeds([...authorizeds, { id: authorizeds.length + 1, userId: '' }]);
  };

  // Remove a row
  const removeAuthorizedRow = (id) => {
    setAuthorizeds(authorizeds.filter(authorized => authorized.id !== id));
  };
  const addFormulaRow = () => {
    setFormulaRows([
      ...formulaRows,
      {
        id: formulaRows.length + 1,
        name: '',
        manufacture: '',
        quantity: 0,
        qs:false,
        unit: '',
        qteRequired: 0,
        lotNumber: '',
        expDate: '',
        check: isChecked // Utiliser l'état actuel de isChecked
      }
    ]);
    const addEquipmentRow = () => {
  setEquipmentRows([
    ...equipmentRows,
    {
      id: equipmentRows.length + 1,
      name: '',
      quantity: 0,
      check: isChecked // Utiliser l'état actuel de isChecked
    }
  ]);
};
  };
  const removeFormulaRow = (id) => {
    setFormulaRows(formulaRows.filter(formula => formula.id !== id));
  };
  const handleFormulaChange = (id, field, value) => {
    const updatedFormulaRows = formulaRows.map((formula) => {
      if (formula.id === id) {
        return { ...formula, [field]: value };  // Mise à jour du champ modifié
      }
      return formula;
    });
    setFormulaRows(updatedFormulaRows); 
    console.log('updated formula:',updatedFormulaRows);
     // Mise à jour de l'état avec la nouvelle valeur
  };
  const addOtherRow = () => {
    setOtherRows([...otherRows, { id: otherRows.length + 1, title: '', description: '' }]);
  };
  const removeOtherRow = (id) => {
    setOtherRows(otherRows.filter(other => other.id !== id));
  };
  const removeActivite = (id) => {
    setActivite(activite.filter((activite) => activite.id !== id));
  };



  const addColumn = () => {
    setColumns([...columns, <input className="form-control" type="text" placeholder="new column" />]);
    setIsColumnAdded(true);
  };

  const removeColumn = () => {
    setColumns(columns.slice(0, -1));
    setIsColumnAdded(false);
  };
  const handleColumnNameChange = (index, value) => {
    setColumns(columns.map((col, i) =>
      i === index ? { ...col, name: value } : col
    ));
  };
  const handleEquipementChange = (id, field, value) => {
    const updatedRows = equipmentRows.map(equip => {
      if (equip.id === id) {
        return {
          ...equip,
          [field]: value 
        };
      }
      return equip;
    });
    setEquipmentRows(updatedRows); 
  };
  
  const addEquipmentRow = () => {
    setEquipmentRows([
      ...equipmentRows,
      {
        id: equipmentRows.length + 1,
        name: '',
        quantity: 0,
        check: isChecked // Utiliser l'état actuel de isChecked
      }
    ]);
  };
  
  // Function to remove a row by id
  const removeEquipmentRow = (id) => {
    setEquipmentRows(equipmentRows.filter(row => row.id !== id));
  };
  const selectedEquipments = equipmentRows.map(row => row.name).filter(Boolean);
  const [formData, setFormData] = useState({
    productName: '',
    routeOfAdministration: '',
    pharmaForm: '',
    effectiveDate: new Date().toISOString().split('T')[0], 
    category: 1, 
    protocolNumber:''

  });
  const [existingProductNames, setExistingProductNames] = useState([]);
  const [productNameExists, setProductNameExists] = useState(false);
  const [existingProtocolNumber, setExistingProtocolNumber] = useState([]);
  const [protocolNumberExists, setProtocolNumberExists] = useState(false);
  // Récupérer les noms de produits existants au chargement
  useEffect(() => {
    const fetchProductNames = async () => {
      try {
        const response = await axios.get(`${BACKEND_URL}api/templates/${clientId}/1`, config);
       // console.log('productNames:', response );
        const productNames = response.map(item => item.productName.toLowerCase()); // Convertir en minuscules
        setExistingProductNames(productNames);
        const protocolNumbers = response.map(item => item.protocolNumber.toLowerCase()); // Convertir en minuscules
        setExistingProtocolNumber(protocolNumbers);
        //console.log('productNames:', productNames);
      } catch (error) {
        console.error('Error fetching product names:', error);
      }
    };

    if (clientId) {
      fetchProductNames();
    }
  }, [clientId]);

  // Modifier handleFormChange pour vérifier en temps réel
  const handleFormChange = (event) => {
    const { name, value } = event.target;
    
    if (name === 'productName') {
      // Vérifier si le nom existe (ignorer la casse)
      const exists = existingProductNames.some(
        existingName => existingName === value.toLowerCase()
      );
      setProductNameExists(exists);
    }
    if (name === 'protocolNumber') {
      // Vérifier si le nom existe (ignorer la casse)
      const exists = existingProtocolNumber.some(
        existingName => existingName === value.toLowerCase()
      );
      setProtocolNumberExists(exists);
    }

    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };


  const handleDescriptionChange = (id, newContent) => {
    setActivite((prevActivite) =>
      prevActivite.map((act) =>
        act.id === id
          ? {
              ...act,
              description: newContent.trim() === "" ? "" : newContent,
            }
          : act
      )
    );
  };


  const activiteData = activite.reduce((acc, act) => {
    acc[act.name] = act.description;
    return acc;
  }, {});
  //console.log('activite', activiteData);
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsChecked(isChecked);
    
    // Mettre à jour formulaRows
    setFormulaRows(prevRows => 
      prevRows.map(row => ({
        ...row,
        check: isChecked ? true : false // Garder l'état actuel si on décoche
      }))
    );
  
    // Mettre à jour equipmentRows
    setEquipmentRows(prevRows => 
      prevRows.map(row => ({
        ...row,
        check: isChecked ? true : false // Garder l'état actuel si on décoche
      }))
    );

  };
  useEffect(() => {
    if (isChecked && formulaBatch > 0 && desiredBatch > 0) {
      const scale = formulaBatch / desiredBatch;

      const updatedFormulaRows = formulaRows.map((formula) => ({
        ...formula,
        qteRequired: formula.check ? formula.quantity * scale : formula.qteRequired
      }));

      setFormulaRows(updatedFormulaRows);
    }
  }, [isChecked, formulaBatch, desiredBatch, formulaRows]);

  useEffect(() => {
    setFormulaRows((prevRows) =>
      prevRows.map((row) => ({ ...row, check: isChecked }))
    );
  }, [isChecked]);

  useEffect(() => {
    if (ingredients&&ingredients.length > 0) {
        setFormulaRows((prev) => {
            // Assurez-vous que le premier ingrédient est utilisé comme valeur par défaut
            return prev.map((formula) => ({
                ...formula,
                name: formula.name || ingredients[0].drugName // Utiliser le premier ingrédient si name est vide
            }));
        });
    }
}, [ingredients]); // Dépendance sur ingredients

  const handleSubmit = (e) => {
    e.preventDefault();

    // Vérification des conditions
    if (equipmentRows.length === 0) {
        setAlert('danger');
        setMessage('Error: Please add at least one equipment.');
        return; // Empêche la soumission
    }

    if (formulaRows.length === 0) {
        setAlert('danger');
        setMessage('Error: Please add at least one formula row.');
        return; // Empêche la soumission
    }

    // Vérification des lignes de formule pour s'assurer qu'elles sont complètes
    const isAnyFormIncomplete = formulaRows.some(formula => {
        return !formula.name || !formula.manufacture || !formula.quantity || !formula.unit || !formula.qteRequired;
    });

    if (isAnyFormIncomplete) {

        setAlert('danger');
        setMessage('Error: Please complete all formula rows before submitting.');
        return; // Empêche la soumission
    }

    // Vérification des lignes d'équipement pour s'assurer qu'elles sont complètes
    const isAnyRowIncomplete = equipmentRows.some(equip => {
        return !equip.name || !equip.quantity; // Ajoutez d'autres champs si nécessaire
    });

    if (isAnyRowIncomplete) {
        setAlert('danger');
        setMessage('Error: Please complete all equipment rows before submitting.');
        return; // Empêche la soumission
    }

    if (productNameExists) {
        setAlert('danger');
        setMessage('Error: Product name already exists.');
        return; // Empêche la soumission
    }

    const templateData = {
        id:formulaDetail.id,
        category: formulaDetail.category,
        pharmaForm: formData.pharmaForm,
        routeOfAdministration: formData.routeOfAdministration,
        effectiveDate: formData.effectiveDate,
        formulaBatchSize: parseInt(formulaBatch, 10), // 10 pour base décimale
        desiredBatchSize: parseInt(desiredBatch, 10),
        formulaBtachSizeUnit: unitFormula,
        desiredBtachSizeUnit: unitDesired,
        productName: formData.productName,
        preparedBy: user.id,
        ingredients: formulaRows,
        equipements: equipmentRows,
        otherRows: otherRows,
        others:otherRows,
        clientId: clientId,
        columns: columns,
        approvedBy: userIds,
        status: formulaDetail.status==="Rejected" ? "Staging+": "Staging",
        scal: isChecked ? 1 : 0,
        protocolNumber: formData.protocolNumber,
        lotNumber:formulaDetail.lotNumber||'',
        parentId: '',
        version:formData.version,
        checkedBy:  (Array.isArray(formulaDetail.checkedBy) && formulaDetail.checkedBy.length > 0)   ? formulaDetail.checkedBy : "",
        activite: activiteData,
        ...activiteData,
    };

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

     axios.put(`${BACKEND_URL}api/templates/update`, templateData, config)
        .then((response) => {
            console.log(response);
            setMessage('Master Formula updated  successfully...');
            setAlert('success');
            setLoading(true);
            localStorage.setItem("Isnotif",true)
            // Afficher le message pendant 3 secondes avant de naviguer
            setTimeout(() => {
                setLoading(false);
                
                navigate(formulaDetail.status==="Rejected" ?'/List-rejection-queue':'/List-formula-template'); // Naviguer après 3 secondes
            }, 3000);
        })
        .catch((error) => {
            if (error === "Request failed with status code 400") {
                setLoading(true);
                setAlert('danger');
                setMessage('Error updated Formula.');
            }
            // Log the error for debugging
            console.error('Error updated Formula:', error);
            setMessage('Error updated Formula.');
            setTimeout(() => {
                setLoading(false);
            }, 2000);
        });
};
  const goBack = () => {
    navigate("/List-formula-template"); // Redirect after successful creation
  }
  const addRoute = () => {
    console.log('addRoute');
      };
  const addPharmaForm = async (nameEn) => {
    try {// Assurez-vous que clientId est récupéré correctement
  
      const pharmaceuticalToCreate = {
        nameEn: nameEn,
        clientId: clientId
      };
  
      const response = await axios.post(
        `${BACKEND_URL}api/pharmaceutical-forms`,
        pharmaceuticalToCreate,
        config
      );
  
      console.log('Pharmaceutical form created:', response.data);
  
      // Mettre à jour la liste des formes pharmaceutiques après la création
      setPharmaceuticals((prev) => [ response.data, ...prev]);
      setFormData((prev) => ({
        ...prev,
        pharmaForm: response.data.nameEn // Assurez-vous que le nom du champ correspond à ce que vous attendez
    }));
     
  
    } catch (error) {
      console.error('Error creating pharmaceutical form:', error);
    }
  };

  // Ajouter la fonction pour créer une nouvelle route
  const addRouteOfAdministration = async (name) => {
    try {
      const routeToCreate = {
        name: name,
        clientId: clientId
      };

      const response = await axios.post(
        `${BACKEND_URL}api/route-administrations`,
        routeToCreate,
        config
      );

      console.log('Route created:', response.data);
      
      // Mettre à jour la liste des routes après la création
      setRoutes((prev) => [...prev, response.data]);
      setFormData((prev) => ({
        ...prev,
        routeOfAdministration: response.data.name // Assurez-vous que le nom du champ correspond à ce que vous attendez
    }));
      
    } catch (error) {
      console.error('Error creating route:', error);
    }
  };

  const renderEquipmentTableHeader = () => (
    <thead style={{ background: "black" }}>
      <tr id="Equipement">
        <td scope="col" className="th-blue" hidden={!isChecked}>#</td>
        <td className="th-blue">Equipment/Materials <small className="red-required">*</small></td>
        {columns.map((column, index) => (
          <td className="th-blue" key={index} style={{ textTransform: "capitalize" }}>
            <Row>
              <Col lg={8}>
                {column.name === 'quantity' ? (
                  column.name 
                ) : (
                  <input 
                    className="form-control"
                    type="text"
                    placeholder="New Column"
                    value={column.name}
                    onChange={(e) => handleColumnNameChange(index, e.target.value)}
                  />
                )}  <small className="red-required">*</small>
              </Col>
              <Col lg={4}>
                {column.name === 'quantity' ? (
                  <button 
                    className="btn btn-white btn-add waves-effect waves-light"
                    type="button"
                    onClick={addColumn}
                  ><i className="fa fa-plus" /></button>
                ) : (
                  <button
                    className="btn btn-danger btn-remove"
                    type="button"
                    onClick={removeColumn}
                  ><i className="fa fa-minus" /></button>
                )}
              </Col>
            </Row>
          </td>
        ))}
        <td className="th-blue">Action</td>
      </tr>
    </thead>
  );

  const renderEquipmentTableBody = () => (
    <tbody>
      {equipmentRows.map((equipement) => {
        const isRowIncomplete = !equipement.name || !equipement.quantity;
        const isCurrentRow = equipement.id === equipmentRows[equipmentRows.length - 1].id;

        return (
          <tr key={equipement.id}>
            <td 
              scope="row" 
              style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }} 
              hidden={!isChecked}
            >
              <input
                className="form-check-input"
                type="checkbox"
                name="check"
                onChange={(e) => handleEquipementChange(equipement.id, 'check', e.target.checked)}
                defaultChecked
                disabled={!isChecked}
              />
            </td>
            <td style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }}>
            <div className="d-flex align-items-center">
              <select
                className="form-select"
                value={equipement.name}
                onChange={(e) => handleEquipementChange(equipement.id, 'name', e.target.value)}
                required
              >
                <option value="">Select equipment</option>
                {equipements
                  .filter(item => !selectedEquipments.includes(item.nameEn) || item.nameEn === equipement.name)
                  .map((item) => (
                    <option key={item.id} value={`${item.itemName} ${item.size} ${item.unit}`}>
                      {item.itemName} {item.size}  {item.unit}
                    </option>
                  ))}
              </select>
              <button 
                className="btn btn-outline-primary waves-effect waves-light btn-plus" 
                type="button" 
                onClick={() => setmodal_addEquipment(true)}
                disabled={!isCurrentRow}
              >
                <i className="fa fa-plus" />
              </button>
              </div>
            </td>
            {columns.map((column, index) => (
              <td 
                key={index}
                style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }}
              >
                <input
                  className="form-control"
                  type={column.name === 'quantity' ? 'number' : 'text'}
                  value={equipement[column.name] || ''}
                  min={column.name === 'quantity' ? '1' : undefined}
                  onChange={(e) => {
                      const value = column.name === 'quantity' ? parseInt(e.target.value, 10) : e.target.value;
                      if (column.name === 'quantity') {
                          if (!isNaN(value) && value >= 1) {
                              handleEquipementChange(equipement.id, column.name, value);
                          }
                      } else {
                          handleEquipementChange(equipement.id, column.name, value);
                      }
                  }}
                  required  
                />
              </td>
            ))}
            <td 
              className="text-end"
              style={{ backgroundColor: isRowIncomplete ? '#e70c0c17' : 'transparent' }}
            >
              <button
                className={`btn ${equipement.id === 1 ? 'btn-primary' : 'btn-danger'} waves-effect waves-light`}
                type="button"
                onClick={equipement.id === 1 ? addEquipmentRow : () => removeEquipmentRow(equipement.id)}
                disabled={isRowIncomplete}
              >
                <i className={`fa fa-${equipement.id === 1 ? 'plus' : 'minus'}`} />
              </button>
            </td>
          </tr>
        );
      })}
    </tbody>
  );
  const handleAddIngredient = async (e) => {
    e.preventDefault();
    try {
        const ingredientToCreate = { ...newIngredient, clientId: clientId };
        const response = await axios.post(`${BACKEND_URL}api/ingredients`, ingredientToCreate, config);

        if (response.data.code === 409) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error: Ingredient with the same name already exists.');
        } else {
            setmodal_addIngredient(false);
            setNewIngredient({ drugName: '', drugForm: '', concentration: '', size: '' });

            // Mettre à jour la ligne de formule active avec le nouvel ingrédient
            const activeFormulaId = formulaRows[formulaRows.length - 1].id;
            const formattedValue = `${response.data.drugName} ${response.data.concentration} ${response.data.drugForm} ${response.data.size}`;
      
            setFormulaRows(prev => prev.map(formula => {
                if (formula.id === activeFormulaId) {
                    return {
                        ...formula,
                        name: formattedValue
                    };
                }
                return formula;
            }));

            // Refetch les données
            await refetchIngredients();

            setMessage('Ingredient added successfully...');
            setLoading(true);
            setAlert('success');
        }
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    } catch (error) {
        setLoading(true);
        setAlert('danger');
        setMessage('Error adding existing new ingredient.');
        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }
};

  const handleAddManufacturer = async (e) => {
    e.preventDefault();
    try {
        const manufacturerToCreate = { ...newManufacturer, clientId: clientId };
        const response = await axios.post(`${BACKEND_URL}api/manufacturers`, manufacturerToCreate, config);

        if (response.data.code === 409) {
            setLoading(true);
            setAlert('danger');
            setMessage('Error: Manufacturer with the same name already exists.');
        } else {
            setmodal_addManufacturer(false);
            setNewManufacturer({ name: '', shortName: '' });

            // Mettre à jour la ligne active
            const activeFormulaId = formulaRows[formulaRows.length - 1].id;
            const formattedValue = `${response.data.name} (${response.data.shortName})`;
            setFormulaRows(prev => prev.map(formula => {
                if (formula.id === activeFormulaId) {
                    return {
                        ...formula,
                        manufacture: formattedValue
                    };
                }
                return formula;
            }));

            // Refetch les données
            await refetchSuppliers();

            setMessage('Manufacturer added successfully...');
            setAlert('success');
        }
        setTimeout(() => {
            setLoading(false);
        }, 3000);
    } catch (error) {
        setLoading(true);
        setAlert('danger');
        setMessage('Error adding existing new manufacturer.');
        setTimeout(() => {
            setLoading(false);
        }, 2000);
    }
};
  const handleAddEquipment = async (e) => {
    e.preventDefault();
    try {
        const equipmentToCreate = { ...newEquipment, clientId: clientId };
        const response = await axios.post(`${BACKEND_URL}api/equipements`, equipmentToCreate, config);
        if (response.data.code === 409) {
            setAlert('danger');
            setMessage('Error: Equipement with the same name already exists.');
        } else {
            setmodal_addEquipment(false);
            setNewEquipment({ itemName: '', unit: '', size: '', clientId: '' });

            // Mettre à jour la ligne active
            const activeEquipmentId = equipmentRows[equipmentRows.length - 1].id;
            const formattedValue = `${response.data.itemName} ${response.data.size} ${response.data.unit}`;
            setEquipmentRows(prev => prev.map(equip => {
                if (equip.id === activeEquipmentId) {
                    return {
                        ...equip,
                        name: formattedValue
                    };
                }
                return equip;
            }));

            // Refetch les données
            await refetchEquipments();

            setMessage('Equipment added successfully...');
            setAlert('success');
        }
    } catch (error) {
        setAlert('danger');
        setMessage('Error adding existing equipment.');
        setTimeout(() => {
            setLoading(false);
        }, 2500);
    }
};
    // Add new unit
  const handleAddUnit = async (e) => {
      e.preventDefault();
      try {
          const unitToCreate = { ...newUnit, clientId: clientId };
          const response = await axios.post(`${BACKEND_URL}api/units`, unitToCreate, config);

          if (response.data.code === 409) {
              setLoading(true);
              setAlert('danger');
              setMessage('Error: Unit with the same name already exists.');
          } else {
              setmodal_addUnit(false);
              setNewUnit({ name: '', shorthand: '' });

              // Mettre à jour la ligne active
              const activeFormulaId = formulaRows[formulaRows.length - 1].id;
              const formattedValue = `${response.data.name} (${response.data.shorthand})`;
              setFormulaRows(prev => prev.map(formula => {
                  if (formula.id === activeFormulaId) {
                      return {
                          ...formula,
                          unit: formattedValue
                      };
                  }
                  return formula;
              }));

              // Refetch les données
              await refetchUnits();

              setMessage('Unit added successfully...');
              setAlert('success');
          }
          setTimeout(() => {
              setLoading(false);
          }, 3000);
      } catch (error) {
          setLoading(true);
          setAlert('danger');
          setMessage('Error adding existing new unit.');
          setTimeout(() => {
              setLoading(false);
          }, 2000);
      }
  };
  const toggleModalIngredient= () => {
    setmodal_addIngredient(!modal_addIngredient);
    if (modal_addIngredient) {
        // Réinitialiser le formulaire lorsque le modal est fermé
        setNewIngredient({ drugName: '', drugForm: '', concentration: '', size:'' });
        setAlert(null);
        setMessage(null);
    }
};


const toggleModalUnit= () => {
  setmodal_addUnit(!modal_addUnit);
  if (modal_addUnit) {
      // Réinitialiser le formulaire lorsque le modal est fermé
      setNewUnit({ name: '', shorthand: '' });
      setAlert(null);
      setMessage(null);
  }
};
const toggleModalManufaturer= () => {
  setmodal_addManufacturer(!modal_addManufacturer);
  if (modal_addManufacturer) {
      // Réinitialiser le formulaire lorsque le modal est fermé
      setNewManufacturer({ name: '', shortName: '' });
      setAlert(null);
      setMessage(null);
  }
};
const toggleModalEquipment= () => {
  setmodal_addEquipment(!modal_addEquipment);
  if (modal_addEquipment) {
      // Réinitialiser le formulaire lorsque le modal est fermé
      setNewEquipment({ itemName: '', unit: '', size: '', clientId: '' });
      setAlert(null);
      setMessage(null);
  }
};
const isFormIncomplete = () => {
  // Vérification des lignes d'équipement
  const isAnyRowIncomplete = equipmentRows.some(equip => {
      return !equip.name || !equip.quantity; // Ajoutez d'autres champs si nécessaire
  });

  // Vérification des lignes de formule
  const isAnyFormIncomplete = formulaRows.some(formula => {
      return !formula.name || !formula.manufacture || !formula.quantity || !formula.unit || !formula.qteRequired;
  });

  // Vérification du nom du produit
  const isProductNameExists = productNameExists;

  return isAnyRowIncomplete || isAnyFormIncomplete || isProductNameExists;
};

const handleOtherRowChange = (id, field, value) => {
    setOtherRows(prev => 
        prev.map(row => 
            row.id === id ? { ...row, [field]: value } : row
        )
    );
};

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
        <Breadcrumbs title="Master Formula" breadcrumbItem="Update Master Formula" />
          <div className="row-form mb-4">
            <Form onSubmit={handleSubmit}>
              <Row className="mb-3 mt-2">
                <Col md={2}>
                  <img src={logobce} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                </Col>
                <Col md={8} className="row">
                  <label className="col-md-2 col-form-label">Compounded</label>
                  <div className="col-md-6">
                    <select className="form-select" name="category" value={formData.category}
                      onChange={handleFormChange} required>
                  
                      <option value={1} defaultChecked>Template Sterile</option>
                      <option value={2}>Template Non Sterile</option>
                    </select>
                    {/* <p> <span className="float-start"></span> <span className="float-end"> Version: 1</span></p> */}
                  </div>
                  <label className="col-md-3 col-form-label">Preparation protocol</label>
                </Col>
                <Col md={2}>
                <img  src={logo?logo:logoorganization} alt="Logo Organization"  className="logo-organization" />
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                {comments&&comments.length > 0 && (
            <Card className="card-bordred">
                      <h5 className="card-header">Comments</h5>
                      <CardBody>
                     
                        {comments.map((item) => (
                             <div className="comment-card" key={item.id}> {/* Added key for each comment */}
                               <div className="comment-header">
                                 <strong>{item.username}</strong> - <em>{formatDate(item.createdAt)}</em> {/* Format the date */}
                               </div>
                               <div className="comment-body">
                                 <p>{item.commentaire}</p>
                               </div>
                             </div>
                           ))}
                       
                      </CardBody>
                    </Card>
            )}
                  <Card className="card-bordred">
                    <h5 className="card-header">Header</h5>
                    <div className="text-center"> </div>
                    <CardBody>
                    <Row className="mt-3">
                
                   
                    <Col lg={8}>
                      <div>
                        <label htmlFor="nameFr" className="form-label">Name of Compounded Product<small className="red-required">*</small></label>
                        <input 
                          className={`form-control ${productNameExists ? 'is-invalid' : ''}`}
                          placeholder="Name of Compounded Product"   type="text"   name="productName" value={formData.productName}  
                          onChange={handleFormChange}  required   />
                        {productNameExists && (
                          <div className="invalid-feedback">
                            This product name already exists.
                          </div>
                        )}
                      </div>
                      </Col>
                      <Col lg={4}> 
                      <div>
                        <label htmlFor="nameFr" className="form-label">Name  of Formula Mnemonic<small className="red-required">*</small></label>
                        <input 
                          className={`form-control ${protocolNumberExists ? 'is-invalid' : ''}`}
                          placeholder="Name  of Formula Mnemonic"   type="text" 
                          name="protocolNumber"   value={formData.protocolNumber}
                          onChange={handleFormChange}   required 
                        />
                        {protocolNumberExists && (
                          <div className="invalid-feedback">
                            This Formula Mnemonic Name already exists.
                          </div>
                        )}
                      </div>
                      </Col>
                      </Row>
                      <Row className="mt-3">
                        <Col lg={4}>
                          <div>
                            <label htmlFor="nameFr" className="form-label">Pharmaceutical form<small className="red-required">*</small> 
                             </label>
                            <CreatableSelect
                              isClearable  name="pharmaForm"
                              value={formData.pharmaForm ? { value: formData.pharmaForm, label: formData.pharmaForm } : null}
                              onChange={(selectedOption) => {
                                handleFormChange({  target: {  name: 'pharmaForm',  value: selectedOption ? selectedOption.value : ''   }  });
                              }}
                              onCreateOption={addPharmaForm}
                              options={pharmaceuticals&&pharmaceuticals.map((pha) => ({  value: pha.nameEn,  label: pha.nameEn   }))}
                              placeholder="Select a pharmaceutical form"
                              required  />
                           

                          </div>
                        </Col>
                        <Col lg={4}>

                          <div>
                            <label htmlFor="nameFr" className="form-label">Route Of Administration<small className="red-required">*</small>  
                             
                             </label>
                            <CreatableSelect
                              isClearable
                              name="routeOfAdministration"
                              value={formData.routeOfAdministration ? 
                                { value: formData.routeOfAdministration, label: formData.routeOfAdministration } : 
                                null
                              }
                              onChange={(selectedOption) => {
                                handleFormChange({
                                  target: {
                                    name: 'routeOfAdministration',
                                    value: selectedOption ? selectedOption.value : ''
                                  }
                                });
                              }}
                              onCreateOption={addRouteOfAdministration}
                              options={routes&&routes.map((route) => ({
                                value: route.name,
                                label: route.name
                              }))}
                              placeholder="Select a route of administration"
                              required  />
                          </div>
                        </Col>
                        <Col lg={4}>
                          <div className="row">
                            <label htmlFor="example-date-input" className="form-label">Effective Date:</label>
                            <input className="form-control" type="date" name="effectiveDate" value={formData.effectiveDate}
                              onChange={handleFormChange} required disabled /> 

                          </div>
                        </Col>
                      </Row>
                      <div className="mt-3" >
                        <label className="form-label">Created By:</label>
                        {authorizeds.map((authorized) => (
                          <Row id="row-authorized" key={authorized.id} className="mb-3">
                            <Col lg={12} style={{paddingRight:"3px"}}>
                              <div>
                              <input className="form-control" type="text" name="preparedBy"  value={username}
                            required disabled />
                            
                              </div>
                            </Col>
                            {/* <Col lg={1}>
                              <div>
                                {authorized.id === 1 ? (
                                  <button className="btn btn-primary waves-effect waves-light" type="button" onClick={addAuthorizedRow}>
                                    <i className="fa fa-plus" />  </button>
                                ) : (
                                  <button className="btn btn-danger waves-effect waves-light" type="button" onClick={() => removeAuthorizedRow(authorized.id)}>
                                    <i className="fa fa-minus" />   </button>
                                )}
                              </div>
                            </Col> */}
                          </Row>
                        ))}
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Formula</h5>
                    <CardBody>
                      <div className="form-check form-check-end">
                        <input  className="form-check-input"  type="checkbox"  id="checked"   name="isChecked"   onChange={handleCheckboxChange}   // Corrected onChange handler
                        />
                        <label className="form-check-label" htmlFor="defaultCheck2">Batch quantity auto calculate</label>
                      </div>
                      <Row className="mt-3">
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="formula_batch" className="col-md-8 col-form-label">Formula Batch Size<small className="red-required">*</small></label>
                            <div className="col-md-4">
                              <input id="formula_batch" className="form-control" type="text" value={formulaBatch}
                                onChange={handleFormulaBatchChange} name="formula_batch" required />
                            </div>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <select className="form-select" name="unit_formula" value={unitFormula} onChange={handleUnitFormulaChange} >
                              {units.map((item) => (<option value={item.shorthand} key={item.id}>{item.name}({item.shorthand})</option>))}
                            </select>
                          </div>
                        </Col>
                        <Col lg={2}></Col>
                        <Col lg={3}>
                          <div className="row">
                            <label htmlFor="desired_batch" className="col-md-8 col-form-label">Desired Batch Size:</label>
                            <div className="col-md-4">
                              <input id="desired_batch" className="form-control" type="text" value={desiredBatch}
                                onChange={handleDesiredBatchChange} name="desired_batch"  disabled/>
                            </div>
                          </div>
                        </Col>
                        <Col lg={2}>
                          <div>
                            <select className="form-select" name="unit_desired" value={unitDesired} onChange={handleUnitDesiredChange} disabled>
                              {units.map((item) => (<option value={item.shorthand} key={item.id}>{item.name}({item.shorthand})</option>))}
                            </select>
                          </div>
                        </Col>
                      </Row>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            <thead style={{ background: "black" }}>
                              <tr id="formula">
                                <td scope="col" className="th-blue" hidden={!isChecked}>  #  </td> <td className="th-blue">Ingredients <small className="red-required">*</small> </td> 
                                 <td className="th-blue">Manufacturer <small className="red-required">*</small></td>  <td className="th-blue">Lot Number </td>
                                <td className="th-blue">Exp Date </td>    <td className="th-blue">QS </td> <td className="th-blue">Quantity <small className="red-required">*</small></td>
                                <td className="th-blue">Units <small className="red-required">*</small></td>  <td className="th-blue">Quantity Required <small className="red-required">*</small></td> 
                                <td className="th-blue">Action</td>
                              </tr>
                            </thead>
                            <tbody>
                            {formulaRows.map((formula) => {
                              const isFormIncomplete = !formula.name || !formula.manufacture || !formula.quantity || !formula.unit || !formula.qteRequired;
                              const isCurrentRow = formula.id === formulaRows[formulaRows.length - 1].id;

                              return (
                                <tr  key={formula.id} >
                                    <td scope="row" hidden={!isChecked} style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <input
                                      className="form-check-input"  type="checkbox"  name="check"
                                      onChange={(e) => handleFormulaChange(formula.id, 'check', e.target.checked)}
                                      defaultChecked disabled={!isChecked} 
                                    />
                                  </td>
                               
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <div className="d-flex align-items-center">
                                    <select    className="form-select" 
                                        name="ingredientName"    value={formula.name}    onChange={(e) => handleFormulaChange(formula.id, 'name', e.target.value)} 
                                        required
                                    >
                                        <option value="">Select ingredient</option>
                                        {ingredients && ingredients.map((item) => (
                                            <option key={item.id} value={`${item.drugName} ${item.concentration} ${item.drugForm} ${item.size}`}>
                                                {item.drugName} {item.concentration} {item.drugForm} {item.size}
                                            </option>
                                        ))}
                                    </select>
                                    <button 
                                        className="btn btn-outline-primary waves-effect waves-light btn-plus" 
                                        type="button" 
                                        onClick={() => setmodal_addIngredient(true)}
                                        disabled={!isCurrentRow}
                                    >
                                        <i className="fa fa-plus" />
                                    </button>
                                     </div>
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                  <div className="d-flex align-items-center">
                                    <select   className="form-select"  name="manufacture"  
                                      value={formula.manufacture}  
                                      onChange={(e) => handleFormulaChange(formula.id, 'manufacture', e.target.value)}  
                                      required  
                                    >
                                      <option value="">Select manufacturer</option>
                                      {suppliers && suppliers.map((item) => (
                                          <option   key={item.id} 
                                              value={`${item.name} (${item.shortName})`}
                                          >
                                              {item.name} ({item.shortName})
                                          </option>
                                      ))}
                                    </select>
                                    <button 
                                        className="btn btn-outline-primary waves-effect waves-light btn-plus" 
                                        type="button" 
                                        onClick={() => setmodal_addManufacturer(true)}
                                        disabled={!isCurrentRow}
                                    >
                                        <i className="fa fa-plus" />
                                    </button>
                                    </div>
                                  </td>
                                  <td className="td-disabled"  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>{formula.lotNumber}</td>
                                  <td className="td-disabled"  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>{formula.expDate}</td>
                                  <td scope="row" style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                   {/* {formula.qs ? "Y" :"N"} */}
                                 
                                    <input  className="form-check-input"
                                      type="checkbox"
                                      name="qs"
                                      defaultChecked={formula.qs}
                                      onChange={(e) => {
                                          const newValue = e.target.checked;
                                          handleFormulaChange(formula.id, 'qs', newValue);
                                      }}
                                    />
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <input
                                      className="form-control"   type="number" name="quantityIngredient"
                                      value={formula.quantity}
                                      placeholder="1"  min="1"  onChange={(e) => {
                                          const value = parseInt(e.target.value, 10);
                                          if (!isNaN(value) && value >= 1) {
                                              handleFormulaChange(formula.id, 'quantity', value);
                                          }
                                      }}
                                      required
                                    />
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                  <div className="d-flex align-items-center">
                                    <select
                                      className="form-select"
                                      name="unitIngredient"
                                      value={formula.unit}
                                      onChange={(e) => handleFormulaChange(formula.id, 'unit', e.target.value)}
                                      required
                                    >
                                      <option value="">Select unit</option>
                                      {units.map((item) => (
                                        <option 
                                            key={item.id} 
                                            value={`${item.name} (${item.shorthand})`}
                                        >
                                            {item.name} ({item.shorthand})
                                        </option>
                                      ))}
                                    </select>

                                    <button 
                                        className="btn btn-outline-primary waves-effect waves-light btn-plus" 
                                        type="button" 
                                        onClick={() => setmodal_addUnit(true)}
                                        disabled={!isCurrentRow}
                                    >
                                        <i className="fa fa-plus" />
                                    </button>
                                     </div>
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}>
                                    <input
                                      className="form-control"
                                      type="number"
                                      name="qteRequired"
                                      value={parseFloat(formula.qteRequired).toFixed(0)}
                                      min="1"
                                      onChange={(e) => {
                                          const value = parseFloat(e.target.value);
                                          if (!isNaN(value) && value >= 1) {
                                              handleFormulaChange(formula.id, 'qteRequired', value.toFixed(0));
                                          }
                                      }}
                                      required
                                      disabled={formula.check ? true : false}
                                    />
                                  </td>
                                  <td  style={{ backgroundColor: isFormIncomplete ? '#e70c0c17' : 'transparent' }}> 
                                    {/* checkbox pour check si l'ingrédient est obligatoire bien designer */}
                                   {formula.id === 1 ? (
                                      <button
                                        className="btn btn-primary waves-effect waves-light"
                                        type="button"
                                        onClick={addFormulaRow} disabled={isFormIncomplete}
                                      >
                                        <i className="fa fa-plus" />
                                      </button>
                                    ) : (
                                      <button
                                        className="btn btn-danger waves-effect waves-light"
                                        type="button"
                                        onClick={() => removeFormulaRow(formula.id)}
                                      >
                                        <i className="fa fa-minus" />
                                      </button>
                                    )}
                                  </td>
                                </tr>
                              );
                            })}

                            </tbody>
                          </table>
                        </div>
                      </Row>

                    </CardBody>
                  </Card>
                </Col>

                <Col lg={12}>
                  <Card className="card-bordred">
                    <h5 className="card-header">Required equipment/materials</h5>
                    <CardBody>
                      <p className="card-title-desc">Indicate all materials and equipment that will be required to compound the sterile preparations.</p>
                      <Row className="mt-3">
                        <div className="table-responsive">
                          <table className="table table-bordered mb-0">
                            {renderEquipmentTableHeader()}
                            {renderEquipmentTableBody()}
                          </table>
                        </div>
                      </Row>
                    </CardBody>
                  </Card>
                </Col>

                {activite && activite.length > 0 ? (
                    activite.map((item) => (
                      <Col lg={12} key={item.id}>
                      <Card className="card-bordred">
                          <h5 className="card-header">
                              <Row>
                                  <Col md={11}>{item.title}</Col>
                                  <Col md={1}>
                                      <button  className="btn btn-danger waves-effect waves-light float-end"  type="button"  onClick={() => removeActivite(item.id)} >
                                          <i className="fa fa-minus" /> </button>
                                  </Col>
                              </Row>
                          </h5>
                          <CardBody style={{ background: "#fafafa" }}>
                              <Row>
                                  <Col md={12}>
                                  
                                      <RichTextEditor
                                        initialData={item.description}
                                        onChange={(newContent) => handleDescriptionChange(item.id, newContent)}
                                      />
                                  </Col>
                              </Row>
                          </CardBody>
                      </Card>
                  </Col>
                    ))
                ) : (
                    <p>No activities available.</p>
                )}

                {otherRows && otherRows.map((other) => (
                  <Col lg={12} key={other.id}>
                    <Card className="card-bordred">
                      <h5 className="card-header">
                        <Row>
                          <Col md={11}>
                            <input 
                                className="form-control" 
                                type="text" 
                                name="title" 
                                placeholder="Title" 
                                defaultValue={other.title}
                                onChange={(e) => handleOtherRowChange(other.id, 'title', e.target.value)}
                            />
                          </Col>
                          <Col md={1}>
                            <button 
                                className={`btn ${other.id === 1 ? 'btn-primary' : 'btn-danger'} waves-effect waves-light float-end`} 
                                type="button" 
                                onClick={other.id === 1 ? addOtherRow : () => removeOtherRow(other.id)}
                            >
                                <i className={`fa fa-${other.id === 1 ? 'plus' : 'minus'}`} />
                            </button>
                          </Col>
                        </Row>
                      </h5>
                      <CardBody style={{ background: "#fafafa" }}>
                        <Row>
                          <Col md={12}>
                            <RichTextEditor
                              initialData={other.description}
                              onChange={(newContent) => {
                                handleOtherRowChange(other.id, 'description', newContent);
                              }}
                            />
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>
                  </Col>
                ))}
                <div>
           
                  </div>

                <Col lg={12} className="fixed-bottom-buttons">
                <Row>
                  <Col md={3}></Col>
                    <Col md={5}>
                      <div className="text-center">
                      {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}
                      </div>
                    </Col>
                    <Col md={4}>
                    <div className="float-end">
                        <button 
                            type="submit" 
                            className="ms-1 btn btn-success me-3" 
                            disabled={isFormIncomplete()} // Désactiver le bouton si le formulaire est incomplet
                        >
                      {formulaDetail&&formulaDetail.status==="Rejected"?"Save Correction":"Save Updated"} 
                        </button>
                        <button 
                            type="reset" 
                            className="btn btn-info" 
                            onClick={() => goBack()}
                        >
                            Cancel
                        </button>
                    </div>
        </Col>
                  </Row>
                </Col>


              </Row>
            </Form>
          </div>
        </Container>
        
      </div>
      <AddUnitModal
        isOpen={modal_addUnit}
        toggle={toggleModalUnit}
        handleAddUnit={handleAddUnit}
        newUnit={newUnit}
        setNewUnit={setNewUnit}
        alert={alert}
        message={message}
            />
      <AddIngredientModal
        isOpen={modal_addIngredient}
        toggle={toggleModalIngredient}
        handleAddIngredient={handleAddIngredient}
        newIngredient={newIngredient}
        setNewIngredient={setNewIngredient}
        alert={alert}
        message={message}
            />

      <AddManufacturerModal
        isOpen={modal_addManufacturer}
        toggle={toggleModalManufaturer}
        handleAddManufacturer={handleAddManufacturer}
        newManufacturer={newManufacturer}
        setNewManufacturer={setNewManufacturer}
        alert={alert}
        message={message}
            />

  <AddEquipmentModal
    isOpen={modal_addEquipment}
    toggle={toggleModalEquipment}
    handleAddEquipment={handleAddEquipment}
    newEquipment={newEquipment} // Assurez-vous que c'est newEquipment et non newEquipement
    setNewEquipment={setNewEquipment}
    alert={alert}
    message={message}
/>
    </React.Fragment>
  );
};

export default UpdateTemplate;
