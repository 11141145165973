import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Input } from 'reactstrap';
import { useNavigate } from 'react-router-dom';
 // Assurez-vous de bien inclure votre fichier CSS


const BasicTable = ({ searchTerm,initialData }) => {
    const navigate = useNavigate();
    const goViewSheet = (id) => {
        console.log('id send', id);
        navigate(`/show-sheet/${id}`); // Include id as a URL parameter
      };

    const columns = [
    
        {
            name: <span className='font-weight-bold fs-15'>Ref Number #</span>,
            selector: row => row.srNo,
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Name</span>,
            selector: row => (<span className='fontTable' >{row.title}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Date Creation</span>,
            selector: row => {
                const date = new Date(row.createDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return <span className='fontTable'>{`${day}-${month}-${year}`}</span>;
            },
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Authorized by</span>,
            selector: row => (<span className='fontTable'>{row.createdBy}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Checked by</span>,
            selector: row => (<span className='fontTable'>{row.checkedBy}</span>),
            sortable: false,
            className: 'table-header'
        },
      
     
        {
            name: <span className='font-weight-bold fs-15'>Status</span>,
            sortable: false,
            selector: (cell) => {
                switch (cell.priority) {
                    case "Refused":
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                    case "Active":
                        return <span className="badge bg-success"> Active </span>;
                    case "Created":
                        return <span className="badge bg-info"> {cell.priority} </span>;
                    case "Approved":
                    return <span className="badge bg-primary"> {cell.priority} </span>;
                    
                    default:
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                }
            },
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: row => (
                <div className="d-flex gap-2">
                    <div className="edit">
                        <button className="btn btn-outline-success waves-effect waves-light" onClick={() => goViewSheet(row.id)} >
                        <i className="mdi mdi-eye"></i>
                        </button>
                    </div>
                    <div className="edit">
                        <button className="btn btn-outline-primary waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#showModal" disabled>
                            <i className="ri-pencil-fill align-bottom  "></i>
                        </button>
                    </div>
                    <div className="remove">
                        <button className="btn btn-outline-danger waves-effect waves-light" data-bs-toggle="modal" data-bs-target="#deleteRecordModal" disabled>
                            <i className="ri-delete-bin-fill align-bottom  "></i>
                        </button>
                    </div>
                </div>
            ),
            className: 'table-header'
        }
    ];

 

    const [filteredData, setFilteredData] = useState(initialData);

    useEffect(() => {
        setFilteredData(
            initialData.filter(item =>
                item.id.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createdBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.preparedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.checkedBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createDate.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.priority.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, initialData]);
    

    return (
        <DataTable
            columns={columns}
            data={filteredData}
            pagination
        />
    );
};

export { BasicTable };
