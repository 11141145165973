import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import logolight from "../../assets/images/logo-light.png";
import logodark from "../../assets/images/logo-dark.png";
import logoEnDark from "../../assets/images/QUESS_COMPOUNDING.png"
import { Row, Col, CardBody, Card, Alert, Container, Form, Input, FormFeedback, Spinner} from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import withRouter from "../../components/Common/withRouter";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser } from "../../store/actions";
import { useTranslation } from 'react-i18next';
import { useNavigate ,useParams} from 'react-router-dom';

const Login = (props) => {

  const {t} = useTranslation();
  document.title = t('siteTitle');
  const { language } = useParams();
  const history = useNavigate ();

  const dispatch = useDispatch();
  const [lang,setLang]= useState(localStorage.getItem('lang'))
  const [showAlert, setShowAlert] = useState(false);
  const { error, loading, dialog } = useSelector(state => {
    if (!state || !state.login) {
      return {
        error: null,
        loading: false,
        dialog: {
          visible: false,
          message: '',
          isError: false
        }
      };
    }
    
    return {
      error: state.login.error || null,
      loading: state.login.loading || false,
      dialog: state.login.dialog || {
        visible: false,
        message: '',
        isError: false
      }
    };
  });
  useEffect(() => {
    if (dialog.visible) {
      setShowAlert(true);
      const timer = setTimeout(() => {
        setShowAlert(false);
        dispatch({ type: 'HIDE_DIALOG' }); // Pour réinitialiser le dialog dans le store
      }, 3000);

      return () => clearTimeout(timer);
    }
  }, [dialog.visible, dispatch]);
  const validation = useFormik({
  
    enableReinitialize: true,
    initialValues: {
      username: "",
      password: "",
    },
    validationSchema: Yup.object({
      username: Yup.string().required(t('userNameRequired')),
      password: Yup.string().required(t('passwordRequired')),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.router.navigate));
    }
  });

  useEffect(() => {
    document.body.className = "bg-pattern";
    return () => {
      document.body.className = "";
    };
  }, []);
  const changeLanguage=(val)=> { 
    const langUrl = localStorage.getItem("lang");
    if(language=="fr"||language=="en"){
      if (val !== langUrl) {
        localStorage.setItem("lang", val);
        setLang(val);
        history.push("/"+val)
        window.location.reload();
      }else{
        window.location.reload();
      }
    } else{
      localStorage.setItem("lang",val)
      setLang(val)
      window.location.reload()
    } 
  }
  return (
    <React.Fragment>
      <div className="bg-overlay"></div>
      <div className="account-pages my-5">
        <Container>
          <Row className="justify-content-center">
            <Col lg={8} md={8} xl={6}>
              <Card>
                <CardBody className="p-6">
                  <div>
                    <div className="text-center">
                      <Link to="/">
                        <img
                          src={lang=='fr'?logodark: logoEnDark}
                          alt=""
                          height="24"
                          className="auth-logo logo-dark mx-auto"
                        />
                        <img
                          src={logolight}
                          alt=""
                          height="24"
                          className="auth-logo logo-light mx-auto"
                        />
                      </Link>
                    </div>
                    <br />
                    <section className="jss109">
                      <hr className="jss150" data-content={t('signInMsg')} style={{ margin: "19px 4px 10px" }} />
                    </section>
                    <Form
                      className="form-horizontal"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      {dialog && dialog.visible && (
                        <Alert color={dialog.isError ? "danger" : "success"}>
                          <div>{dialog.message || ''}</div>
                        </Alert>
                      )}
                      <Row>
                        <Col md={12}>
                          <div className="mb-4">
                            <Input
                              name="username"
                              className="form-control input"
                              placeholder="Username"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.username || ""}
                              invalid={
                                validation.touched.username && validation.errors.username ? true : false
                              }
                            />
                            {validation.touched.username && validation.errors.username ? (
                              <FormFeedback type="invalid"><div>{validation.errors.username}</div></FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-4">
                            <Input
                              name="password"
                              className="form-control input"
                              value={validation.values.password || ""}
                              type="password"
                              placeholder="Password"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              invalid={
                                validation.touched.password && validation.errors.password ? true : false
                              }
                            />
                            {validation.touched.password && validation.errors.password ? (
                              <FormFeedback type="invalid"><div>{validation.errors.password}</div></FormFeedback>
                            ) : null}
                          </div>
                          <Row className="text-center">
                            <Col lg={4} md={4} xs={12} className="text-start">
                              <div className="form-check text-start" style={{ marginLeft: '-20px' }}>
                                <button
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                  disabled={loading === true}
                                >
                                  {loading === true && (
                                    <Spinner size="sm" className="me-2" />
                                  )}
                                  {t('logIn')} <i className="mdi mdi-arrow-right" />
                                </button>
                              </div>
                            </Col>
                            <Col lg={8} md={8} xs={12} className="text-end">
                              <div className="text-md-end mt-3 mt-md-0">
                                <Link to="/forgot-password"
                                  className="btn btn-primary waves-effect waves-light"
                                  type="submit"
                                >
                                  {t('forgetPassword')}
                                </Link>
                              </div>
                            </Col>
                          </Row>
                          <div  className="lngBtn" style={{display:'flex',alignItems:'center', justifyContent:'center', marginTop:"5%"}}> 
                          <div  style={{  color: '#039be5', textAlign:'center'}}  >
                            <button id="fr" onClick={()=>changeLanguage("fr")}        
                            style={{  borderBottom: localStorage.getItem('lang') === 'fr' ? 'solid #32a1ce' : 'initial', borderRadius:'0'
                            ,color: '#039be5'}}
                              className="btn  waves-effect waves-light"
                            >{t("francais")}
                            </button>
                            /
                            <button  id="en"
                            style={{ borderBottom: localStorage.getItem('lang') === 'en' ? 'solid #32a1ce' : 'initial', borderRadius:'0'
                            ,color: '#039be5'}}
                            onClick={()=>changeLanguage("en")}   className="btn  waves-effect waves-light">{t("anglais")}
                            
                            </button>
                          </div>
                          <i className="bx bx-globe" style={{ fontSize: '25px' }} />
                        </div>
                        </Col>
                      </Row>
                    </Form>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p className="text-white-50">© {new Date().getFullYear()} QUESS 360. {t("reservedRights")}.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
