import PropTypes from "prop-types";

import React, { useEffect, useState } from "react";
import {
  Card,
  CardBody,
  Form,
  Col,
  Row,
  CardSubtitle,
  Alert,
  Container,
  Progress,
} from "reactstrap";
import axios from "axios";
import { BACKEND_URL } from "../../config/const.js";
import logolight from '../../assets/images/logo-light.png';
import logodark from '../../assets/images/logo-dark.png';
import withRouter from "../../components/Common/withRouter";


import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate ,useParams} from 'react-router-dom';
import logoEnDark from "../../assets/images/QUESS_COMPOUNDING.png"
const RecoverPassword = () => {
 
  const {t} = useTranslation();
  document.title = t('recoverPassword');
  const { language } = useParams();
  const history = useNavigate ();
  const [userName,setUserName]= useState("")
  const [emailSentResult, setEmailSentResult] = useState(null);
const navigate= useNavigate();
  const [lang,setLang]= useState(localStorage.getItem('lang'))

  const [userNameError, setUserNameError] = useState(null);
  const checkUserName = () => {
    if (userName == "") {
      setUserNameError(true);
      return false;
    } else {
      setUserNameError(null);
      return true;
    }
  };
  const changeLanguage=(val)=> { 
    const langUrl = localStorage.getItem("lang");
    if(language=="fr"||language=="en"){
     
      if (val !== langUrl) {
        localStorage.setItem("lang", val);
        setLang(val);
        history.push("/"+val)
        window.location.reload();
      }else{
        window.location.reload();
      }
    } else{
      
      localStorage.setItem("lang",val);
      setLang(val);
      window.location.reload();
    } 
  }
const handleSendEmail= async (e)=> {
  e.preventDefault();
const userData={
  username:userName,
  lang:lang
}
if(!checkUserName())
{
  setEmailSentResult({ text: t("emailSentFailure"), type: "error" });
  setTimeout(() => {
    setEmailSentResult(null);
   
  }, 2500);
}
else {
  const response = await axios.post(
    `${BACKEND_URL}user/sendForgetPasswordEmail`  ,userData, {
      headers: {
        Authorization: "", // Ensure no token is passed for this request
      }
    }
  );
  if(response.message==true)
  {
  
    setEmailSentResult({ text: t("emailSentSuccess"), type: "success" });
          setTimeout(() => {
            setEmailSentResult(null);
          
            navigate("/");
          }, 2500);
        } else {
          setEmailSentResult({ text: t("emailSentFailure"), type: "error" });
          setTimeout(() => {
            setEmailSentResult(null);
           
          }, 2500);
        }
}


}
  return (
    <React.Fragment>
      <div className="bg-pattern" style={{ height: "100vh" }}>
        <div className="bg-overlay "></div>
        <div className="account-pages pt-5">
          <Container>
            <Row className="justify-content-center">
              <Col lg={6} md={8} xl={6}>
                <Card className='mt-5'>
                  <CardBody className="p-4">
                    <div className="">
                      <div className="text-center">
                        <Link to="/" className="">
                          <img src={lang=="fr"?logodark:logoEnDark} alt="" height="24" className="auth-logo logo-dark mx-auto" />
                          <img src={logolight} alt="" height="24" className="auth-logo logo-light mx-auto" />
                        </Link>
                      </div>
                      <h4 className="font-size-18 text-muted mt-3 text-center">{t('resetPassword')}</h4>
                      <section className="jss109">
                        <hr className="jss150" data-content={t('resetPassWithMail')} style={{ margin: "19px 4px 10px" }}>
                        </hr>
                      </section>
                      {/* <p className="mb-5 text-center"></p> */}
                      <Form onSubmit={handleSendEmail}>
                        <Row>
                          <Col md={12}>
                            <div className="alert alert-warning alert-dismissible">
                                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                              {t('enterYour')} <b> {t('username')} </b> {t('insructions')} 
                            </div>


                            <div className="mt-4">
                              <label className="form-label" htmlFor="username">{t('username')}</label>
                              <input type="text" className="form-control" id="username" placeholder={t('username')} onChange={(e)=>setUserName(e.target.value)} />
                              {userNameError && (
                            <code className="text-danger">
                              {" "}
                              {t("userNameRequiredMessage")}
                            </code>
                          )}
                            </div>
                            <div className="d-grid mt-4">
                              <button className="btn btn-primary waves-effect waves-light" type="submit">{t('sendEmail')}</button>
                            </div>
                          
                          </Col>
                        </Row>
                      </Form>
                    </div>
                    <Row>
            <div  className="lngBtn" style={{display:'flex',alignItems:'center', justifyContent:'center', marginTop:"5%"}}> 
              <div  style={{  color: '#039be5', textAlign:'center'}}  >
                <button id="fr" onClick={()=>changeLanguage("fr")} 
                 type="button"       
                style={{  borderBottom: localStorage.getItem('lang') === 'fr' ? 'solid #32a1ce' : 'initial', borderRadius:'0'
                ,color: '#039be5'}}
                  className="btn  waves-effect waves-light"
                >{t("francais")}
                </button>
                /
                <button  id="en"
                type="button"
                style={{ borderBottom: localStorage.getItem('lang') === 'en' ? 'solid #32a1ce' : 'initial', borderRadius:'0'
                ,color: '#039be5'}}
                onClick={()=>changeLanguage("en")}   className="btn  waves-effect waves-light">{t("anglais")}
                
                </button>
              </div>
              <i className="bx bx-globe" style={{ fontSize: '25px' }} />
              

            </div>
            </Row>
                  </CardBody>
                </Card>
                <div className="mt-5 text-center">
                       <p className="text-white-50">© {new Date().getFullYear()} BCE PHARMA.   {t("reservedRights")}.</p>
                </div>
              </Col>
            </Row>
           
           
          </Container>
          {emailSentResult && (
              <div
                style={{
                  position: "fixed",
                  bottom: "10px",
                  left: "50%",
                  transform: "translateX(-50%)",
                  padding: "10px 20px",
                  backgroundColor:
                  emailSentResult.type === "success" ? "green" : "#ff3d60",
                  color: "white",
                  borderRadius: "5px",
                  zIndex: 1000,
                  minWidth: "25%",
                  textAlign: "center",
                  transition: "opacity 0.5s ease-in-out",
                }}
              >
                {emailSentResult.text}
              </div>
            )}
        </div>
      </div>

    </React.Fragment>
  );
}
export default withRouter(RecoverPassword);

RecoverPassword.propTypes = {
  history: PropTypes.object,
};
