import React, { useState,useEffect } from "react";
import { Card, CardBody, Form, Col, Row, Container,Alert, CardText, Input, Label } from "reactstrap";
import logobce from "../../assets/images/logo_bce.png";
import logoorganization from "../../assets/images/organization_logo.png";
import './style.css';
import Breadcrumbs from "../../components/Common/Breadcrumb";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BACKEND_URL } from "../../config/const.js";
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
const Loader = () => {
  return (
    <div className="loader-container text-center">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );
};
const TextWithInputFields = ({ htmlContent, onInputChange }) => {
  const [inputs, setInputs] = useState({});

  // Fonction pour insérer des champs input à la place des soulignés
  const convertHtmlWithInputs = (html) => {
    const regex = /_{2,}/g;
    let inputIndex = 0;

    // Remplacer les espaces soulignés par des champs de saisie
    const updatedHtml = html.replace(regex, (match) => {
      const id = `input_${inputIndex}`;
      inputIndex++;
      return `<input type="text" id="${id}" style="border-bottom:1px solid black; width: 100px;" />`;
    });

    return updatedHtml;
  };

  // Fonction pour gérer la modification des champs
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setInputs({ ...inputs, [id]: value });
    onInputChange(id, value);
  };

  // Utiliser useEffect pour lier les événements de changement d'entrée après le rendu
  React.useEffect(() => {
    Object.keys(inputs).forEach((id) => {
      const inputElement = document.getElementById(id);
      if (inputElement) {
        inputElement.value = inputs[id];
        inputElement.oninput = handleInputChange;
      }
    });
  }, [htmlContent, inputs]);

  return (
    <div dangerouslySetInnerHTML={{ __html: convertHtmlWithInputs(htmlContent) }} />
  );
};
const CreateNewSheet = () => {
  const navigate = useNavigate();
  document.title = "Create New Sheet | Quess Magistrale";
  const [displayNone, setDisplayNone] = useState(false);
  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState(null);
  const [message, setMessage] = useState(null);
  const [desiredBatch, setDesiredBatch] = useState('');
  const [formulaBatch, setFormulaBatch] = useState('');
  const [unitDesired, setUnitDesired] = useState('');
  const [clientId, setClientId] = useState(null);
  const [templatesData, setTemplatesData] = useState([]);
  const [templateDetail, setTemplateDetail] = useState(null);
  const [users, setUsers] = useState([]);
 const [ingredients, setIngredients] = useState([]);
 const [approvedBy, setApprovedBy] = useState("");
 const [logo, setLogo] = useState(null);
 const [equipmentRows, setEquipmentRows] = useState([]);
 const [equipmentSend, setEquipmentSend] = useState([]);
 const [isChecked, setIsChecked] = useState(true);
 const [scal, setScal] = useState(1);
 const [units, setUnits] = useState([]);
  const config = {
    headers: {
        Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
};
useEffect(() => {

  fetchUsers();
}, [clientId]);
const handleInputChange = (inputId, value) => {
  console.log(`Changed input ${inputId}: ${value}`);
  // Effectuez des actions avec la valeur changée
};


const handleIngredientChange = (index, field, value) => {
  const updatedIngredients = [...ingredients];
  updatedIngredients[index] = { 
    ...updatedIngredients[index], 
    [field]: value 
  }; // Update the specific field (lotNumber or expDate)
  setIngredients(updatedIngredients); // Update the state with the modified ingredients
};
useEffect(() => {
  fetchUnits();

}, [clientId]);
useEffect(() => {
  handleIngredientChange();
}, []);
// Récupération des informations de l'utilisateur connecté
useEffect(() => {
  const client = JSON.parse(localStorage.getItem("currentOrg"));
  const logoOrg = localStorage.getItem("logoOrg"); // Assuming logoOrg is just a URL string

  if (logoOrg) {
    setLogo(`${BACKEND_URL}${logoOrg}`);// Ensure this constructs a valid URL
  }
if (client) {
    setClientId(client);
}
}, [clientId, logo]);
//fetch users
const fetchUsers = async () => {
  try {
    if (clientId) {
      const response = await axios.get(`${BACKEND_URL}api/getGroupUsers/1`, config);
      console.log('users:', response);
      setUsers(response);
    }

  } catch (error) {
    console.error('Error fetching user:', error);
  }
};

const fetchUnits = async () => {
  try { if (clientId) {
      const response = await axios.get(`${BACKEND_URL}api/unitsbyclient/${clientId}`, config);
      console.log('units:', response);
      setUnits(response.data);
    }

  } catch (error) {
    console.error('Error fetching unit:', error);
  }
};



  const handleDisplay = () => {
    setLoading(true); // Show the loader

    setTimeout(() => {
      setLoading(false); // Hide the loader
      setDisplayNone(true); // Show the form after 3 seconds
    }, 3000);
  };



  const handleDesiredBatchChange = (e) => {
    setDesiredBatch(e.target.value);
// Update formulaBatch in real-time
  };


  const handleUnitDesiredChange = (e) => {
    setUnitDesired(e.target.value);
// Update unitFormula in real-time
  };


const fetchTemplatesData = async (category) => {
  try {
    const response = await axios.get(`${BACKEND_URL}api/templates/${clientId}/${category}`, config);
    console.log('template:', response);
   
      const data = response.map(item => ({
        id: item.id,
        title: item.productName, // Assuming you have a productName field
      }));
      setTemplatesData(data); // Set the templates data based on the API response
    
    console.log('template data:', templatesData);
  } catch (error) {
    console.error('Error fetching template:', error);
  }
};
const fetchTemplateDetail = async (product) => {
  try {
    const response = await axios.get(`${BACKEND_URL}api/template/${product}`, config);
    console.log('template:', response);
   
      setTemplateDetail(response); // Set the templates data based on the API response
      setIngredients(response.ingredients)
      setEquipmentRows(response.equipements)
      setFormulaBatch(response.formulaBatchSize)
      setUnitDesired(response.desiredBtachSizeUnit)
      setIsChecked(response.scal==1?true:false)
  if (response){
    response.approvedBy&&response.approvedBy.map((item) => (
       setApprovedBy(item.id)))
  }
    console.log('template detail:', templateDetail);
    console.log('ingredient:', ingredients);
  } catch (error) {
    console.error('Error fetching template:', error);
  }
};

const [formData, setFormData] = useState({
  checkedBy:'',
  preparedBy:''
});

const handleFormChange = (e) => {
  const { name, value } = e.target;
  setFormData({
    ...formData,
    [name]: value, // Dynamically update form data
  });
};
useEffect(() => {
  if (formData.category) {
    fetchTemplatesData(formData.category);
  }
}, [formData.category]); // Re-fetch templates when category changes

useEffect(() => {
  if (formData.product) {
    fetchTemplateDetail(formData.product);
  }
}, [formData.product]); 


const handleCheckboxChange = (e) => {
  setIsChecked(e.target.checked);
  if (e.target.checked) {
    const scal = formulaBatch / desiredBatch; // Calculate the scale once checkbox is checked

    const updatedFormulaRows = ingredients.map((formula) => ({
      ...formula,
      qteRequired: formula.quantity * scal, // Update the Quantity Required for each row
    }));

    setIngredients(updatedFormulaRows); // Update state with the new values
  }
};

useEffect(() => {
  if (isChecked && formulaBatch > 0 && desiredBatch > 0) {
    const scale = formulaBatch / desiredBatch;
    setScal(scale);
    const updatedFormulaRows = ingredients.map((formula) => ({
      ...formula,
      qteRequired: formula.quantity * scale // Mise à jour de Quantity Required avec le scale
    }));

    setIngredients(updatedFormulaRows);

  }
}, [isChecked, desiredBatch,ingredients]);

useEffect(() => {
  
  const updatedEquipmentRows = equipmentRows.map(item => ({
    ...item ,
    quantity: item.quantity * scal// Multiply by scal
  }));
  //setEquipmentRows(updatedEquipmentRows);
  setEquipmentSend(updatedEquipmentRows);
}, [scal]);

const handleSubmit = (e) => {
  e.preventDefault();

  const templateData = {
    category:templateDetail.category,
    pharmaForm:templateDetail.pharmaForm ,
    routeOfAdministration:templateDetail.routeOfAdministration,
    effectiveDate:templateDetail.effectiveDate,
    formulaBatchSize: parseInt(templateDetail.formulaBatchSize, 10), // 10 pour base décimale
    desiredBatchSize: parseInt(desiredBatch, 10),
    formulaBtachSizeUnit: templateDetail.formulaBtachSizeUnit,
    desiredBtachSizeUnit: unitDesired,
    productName: templateDetail.productName,
    preparedBy:formData.preparedBy,
    ingredients: ingredients,
    equipements: equipmentSend,
    clientId:clientId,
    approvedBy:parseInt(approvedBy, 10),
    status: templateDetail.status,
    //lotNumber: templateDetail.lotNumber,
    protocolNumber: templateDetail.protocolNumber,
    parentId:templateDetail.id,
    checkedBy:formData.checkedBy,
    calculations:templateDetail.calculations,
    compoundingMethod:templateDetail.compoundingMethod,
    ingredientsInfo:templateDetail.ingredientsInfo,
    labelling:templateDetail.labelling,
    packaging:templateDetail.packaging,
    qualityControl:templateDetail.qualityControl,refs:templateDetail.refs,
    training:templateDetail.training,stabilityAndStorage:templateDetail.stabilityAndStorage,
    version: templateDetail.version,
  };
  

  const config = {
    headers: {
      Authorization: `Bearer ${localStorage.getItem("token")}`,
    },
  };
  // axios.get(`${BACKEND_URL}api/equipements/${clientId}`, config);
  axios.post(`${BACKEND_URL}api/formulas/new`, templateData, config)
    .then((response) => {
      console.log(response);
      setMessage('Master Formula Added successfully...');
      setAlert('success');
      setLoading(true);
  
      // Afficher le message pendant 3 secondes avant de naviguer
      setTimeout(() => {
        setLoading(false);
        navigate('/List-formula-sheet'); // Naviguer après 3 secondes
      }, 3000); 

    })
    .catch((error) => {
      if (error === "Request failed with status code 400")  {
      
        // Handle other types of errors
        setLoading(true);
        setAlert('danger');
        setMessage('Error added Formula.');
    }            
    // Log the error for debugging
    console.error('Error  added Formula:', error);
    setMessage('Error added Formula.');
    setTimeout(() => {
        setLoading(false);
    }, 2000);
    });
};

const goBack=()=>{
  navigate("/List-formula-sheet"); // Redirect after successful creation
}
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title="Preparation Sheet" breadcrumbItem="Select New Preparation" />

          <div className="row-form mb-4">
            <Row className="mb-3 mt-2">
              <Col md={4} className="pe-4">
              <select className="form-select" name="category" onChange={handleFormChange} required>
                <option>Select category</option>
                <option value={1}>Template Sterile</option>
                <option value={2}>Template Non Sterile</option>
              </select>
              </Col>
              <Col md={4} className="pe-4" >
                <select className="form-select" name="product"   onChange={handleFormChange}>
                <option key={0}>Select template Name</option>
                {templatesData && templatesData.map((pha) => (<option value={pha.id} key={pha.id}>{pha.title}</option>))}
                </select>
              </Col>
              <Col md={3} >
              {templateDetail
              ?
                <button className="ms-1 btn btn-info me-3" style={{ width: "100%" }} onClick={handleDisplay}>
                  Generate <i className=" bx bxs-file-txt me-1"></i>
                </button>:null}
              </Col>
            </Row>
            {loading ? (
              <Loader />
            ) : displayNone ? (

              <Form onSubmit={handleSubmit}>

                <Row>
                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Header</h5>
                      <div className="text-center"> </div>
                      <CardBody>
                        <Row className="mb-3 mt-2">
                          <Col md={2}>
                            <img
                              src={logobce}
                              alt=""
                              height="24"
                              className="auth-logo logo-dark mx-auto"
                            />
                          </Col>
                          <Col md={8} className="text-center">

                            <Label className="col-form-label">Compounded  {templateDetail.category=== 1?"Sterile":  "Non Sterile" }  Preparation Protocol</Label>
                            <p> <span className="">Number : {templateDetail.lotNumber}  </span> <span className="" > Version: {templateDetail.version}</span></p>

                            <div className="col-md-12 text-center mb-2">
                              <p className="p-card">
                                <span>{templateDetail.productName}</span><br />
                         
                              </p>
                              <br />
                              <span className=" bg-success p-3 text-white">STATUS: {templateDetail.status} </span>
                              <br /><br />
                            </div>


                          </Col>
                          <Col md={2}>
                          <img  src={logo?logo:logoorganization} alt="Logo Organization" className="logo-organization" />
                          </Col>
                        </Row>

                        <Row  >
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="route_of-administration " className=" col-form-label ms-3"  > Route of administration: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              < Input className="form-control" type="text" value={templateDetail.routeOfAdministration} disabled />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="effective_date " className="col-form-label ms-3"  > Effective Date: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Input className="form-control" type="date" value={templateDetail.effectiveDate}  disabled />
                            </div>
                          </Col>
                        </Row>

                        <Row className="mt-2" >
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="pharmaceutical_form" className=" col-form-label ms-3"  > Pharmaceutical form: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              < Input className="form-control" type="text" value={templateDetail.pharmaForm} disabled />
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="authorized_by" className="col-form-label ms-3"  > Authorized by: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                            {/* <Input className="form-control" type="text"   value={ templateDetail.approvedBy}  disabled /> */}
                            <Input className="form-control" type="text"
                               value={ templateDetail.approvedBy&&templateDetail.approvedBy.map((item) => ( item.username))}  disabled />
                            </div>
                          </Col>

                        </Row>

                        <Row className="mt-3">
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="prepared_by" className="col-form-label ms-3"  > Prepared by: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                            <select  className="form-select"  name="preparedBy"    onChange={handleFormChange} required>
                                  <option value={formData.preparedBy}>Select User</option>
                                  {users.map((user) => (
                                    <option value={user.idUser}>
                                      {user.firstName} {user.lastName}
                                    </option>
                                  ))}
                                </select>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                              <Label htmlFor="checked_by" className="col-form-label ms-3"  > Checked by: </Label>
                            </div>
                          </Col>
                          <Col lg={3}>
                            <div>
                            <select  className="form-select"  name="checkedBy"
                            onChange={handleFormChange}  required>
                                  <option value="">Select User</option>
                                  {users.map((user) => (
                                    <option value={user.idUser}>
                                      {user.firstName} {user.lastName}
                                    </option>
                                  ))}
                                </select>
                            </div>
                          </Col>

                        </Row >


                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Formula </h5>
                      <CardBody>

                      <div className="form-check form-check-end">
                        <input  className="form-check-input"  type="checkbox"  id="checked"   name="isChecked"  onChange={handleCheckboxChange}   defaultChecked={isChecked}// Corrected onChange handler
                        />
                        <label className="form-check-label" htmlFor="defaultCheck2">Batch quantity auto calculate</label>
                      </div>

                        <Row className="mt-3">
                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="formula_batch" className="col-md-8 col-form-label ">Formula Batch Size:</label>
                              <div className="col-md-4">
                                <input  id="formula_batch"   className="form-control"  type="text"  
                                 value={templateDetail.formulaBatchSize}   name="formula_batch" disabled />
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>
                            <select className="form-select" name="unit_formula"  value={templateDetail.formulaBtachSizeUnit} disabled >
                              {units.map((item) => (<option value={item.nameFr} key={item.id}>{item.nameEn}</option>))}
                            </select>
                           
                            </div>
                          </Col>

                          <Col lg={2}></Col>

                          <Col lg={3}>
                            <div className="row">
                              <label htmlFor="desired_batch" className="col-md-8 col-form-label ">Desired Batch Size:</label>
                              <div className="col-md-4">
                                <input  id="desired_batch"  className="form-control"  type="text"
                                 defaultValue={templateDetail.desiredBatchSize}  onChange={handleDesiredBatchChange}  name="desired_batch"
                                />
                              </div>
                            </div>
                          </Col>

                          <Col lg={2}>
                            <div>
                            <select className="form-select" name="unit_desired"   defaultValue={unitDesired} onChange={handleUnitDesiredChange} >
                              {units.map((item) => (<option value={item.nameFr}  key={item.id}>{item.nameEn}</option>))}
                            </select>

                            </div>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <div className="table-responsive">
                            <table className="table table-bordered mb-0">
                              <thead style={{ background: "black" }}>
                                <tr id="formula">
                                <td className="th-blue">Ingredients</td>
                                  <td className="th-blue">Manufacture</td>
                                  <td className="th-blue">Lot Number</td>
                                  <td className="th-blue">Exp Date</td>
                                  <td className="th-blue">Quantity</td>
                                  <td className="th-blue">Units</td>
                                  <td className="th-blue">Quantity Required</td>

                                </tr>
                              </thead>
                              <tbody>
                              {ingredients&&ingredients.map((ing, index) => (
                                <tr key={index}>
                                  <td>
                                    <Input className="form-control" type="text" value={ing.name} disabled />
                                  </td>
                                  <td>
                                    <Input className="form-control" type="text" value={ing.manufacture} disabled />
                                  </td>
                                  <td>
                                    <Input  className="form-control"   type="text"  defaultValue={ing.lotNumber} 
                                      onChange={(e) => handleIngredientChange(index, 'lotNumber', e.target.value)} required
                                    />
                                  </td>
                                  <td>
                                    <Input   className="form-control"   type="date"   defaultValue={ing.expDate} 
                                      onChange={(e) => handleIngredientChange(index, 'expDate', e.target.value)} required
                                    />
                                  </td>
                                  <td>
                                    <Input className="form-control" type="text" value={ing.quantity} disabled />
                                  </td>
                                  <td>
                                    <Input className="form-control" type="text" value={ing.unit} disabled />
                                  </td>
                                  <td>
                                    <Input className="form-control" type="text" value={ing.qteRequired} disabled />
                                  </td>
                                </tr>
                              ))}



                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>

                  <Col lg={12}>
                    <Card className="card-bordred">
                      <h5 className="card-header">Required equipement/materiels </h5>
                      <CardBody>

                        <Row className="mt-3">
                          <div className="table-responsive">
                         
                            <table className="table table-bordered mb-0">
                              <thead style={{ background: "black" }}>
                                <tr id="Equipement">

                                <td className="th-blue">Equipment/Materials</td>
                                <td className="th-blue">Quantity</td>
                                  {/* <th>Other</th> */}
                                </tr>
                              </thead>
                              <tbody>
                              {scal>1 ? (
                                  equipmentSend.map((equip, index) => (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={equip.name}
                                          disabled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-control"
                                          type="number"
                                          value={equip.quantity}
                                          disabled
                                        />
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  equipmentRows.map((equip, index) => (
                                    <tr key={index}>
                                      <td>
                                        <input
                                          className="form-control"
                                          type="text"
                                          value={equip.name}
                                          disabled
                                        />
                                      </td>
                                      <td>
                                        <input
                                          className="form-control"
                                          type="number"
                                          value={equip.quantity}
                                          disabled
                                        />
                                      </td>
                                    </tr>
                                  ))
                                )}


                              </tbody>
                            </table>
                          </div>
                        </Row>

                      </CardBody>
                    </Card>
                  </Col>


                  <Col lg={12}>
                  {templateDetail.ingredientsInfo?
                    <Card className="card-bordred">
                      <h5 className="card-header">Additional information on ingredients</h5>
                      <CardBody>

                        <CardText>
                          
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.ingredientsInfo }}  />
                        </CardText>

                      </CardBody>
                    </Card>:<></>
                    }
                     {templateDetail.calculations?
                    <Card className="card-bordred">
                      <h5 className="card-header">Calculations and Measurements</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.calculations }}  />
             
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {templateDetail.compoundingMethod?
                    <Card className="card-bordred">
                      <h5 className="card-header">Compounding Method</h5>
                      <CardBody>
                        <CardText>
                        {/* <TextWithInputFields  htmlContent={templateDetail.compoundingMethod}  onInputChange={handleInputChange}   /> */}
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.compoundingMethod }}  />
                
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {templateDetail.qualityControl?
                    <Card className="card-bordred">
                      <h5 className="card-header">Quality Control</h5>
                      <CardBody>

                        <CardText>
                        {/* <TextWithInputFields  htmlContent={templateDetail.qualityControl}  onInputChange={handleInputChange}  /> */}
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.qualityControl }}  />
                     
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {templateDetail.packaging?
                    <Card className="card-bordred">
                      <h5 className="card-header">Packaging</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.packaging }}  />
              
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {templateDetail.stabilityAndStorage?
                    <Card className="card-bordred">
                      <h5 className="card-header">Stability and Storage</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.stabilityAndStorage }}  />
                  
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {templateDetail.training?
                    <Card className="card-bordred">
                      <h5 className="card-header">Training</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.training }}  />
                      
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
                     {templateDetail.refs?
                    <Card className="card-bordred">
                      <h5 className="card-header">References Consulted</h5>
                      <CardBody>

                        <CardText>
                        <div  dangerouslySetInnerHTML={{ __html: templateDetail.refs }}  />
                        </CardText>

                      </CardBody>
                      </Card>:<></>
                    }
           
                  </Col>
                  <Col lg={12} className="fixed-bottom-buttons">
                  <Row>
                  <Col md={3}></Col>
                    <Col md={5}>
                      <div className="text-center">
                      {loading && alert === "success" ? ( <Alert color={alert}>{message}</Alert> ) : null}
                      </div>
                    </Col>
                    <Col md={4}>
                      <div className="float-end">
                        <button type="submit" className="ms-1 btn btn-success me-3">Save</button>
                        <button type="reset" className="btn btn-info" onClick={() => goBack()}>Cancel</button>
                      </div>
                    </Col>
                  </Row>
                  </Col>
                </Row>
              </Form>
            ) : null}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CreateNewSheet;
