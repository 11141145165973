import React, { useState, useEffect } from 'react';
import DataTable from 'react-data-table-component';
import { Button, CardBody, Modal } from 'reactstrap';
import axios from 'axios';
import { BACKEND_URL } from "../../../config/const";
import { useNavigate } from 'react-router-dom';
const BasicTable = ({ searchTerm, initialData,isDeleted, setIsDeleted }) => {
    const navigate = useNavigate();
    const goViewSheet = (id) => {
        console.log('id send', id);
        navigate(`/show-formula/${id}`); // Include id as a URL parameter
      };
    const [itemToDelete, setItemToDelete] = useState(null);
    const [modal_standard, setModalStandard] = useState(false);
    const [loading, setLoading] = useState(false);
    const [items, setItems] = useState(initialData);
    const [alert, setAlert] = useState(null);
    const [message, setMessage] = useState(null);

    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    const toggleModal = () => {
        setModalStandard(!modal_standard);
    };
   // console.log("idd ::::",itemToDelete);
    // Delete item
    const handleRemove = async (id) => {
        try {
            await axios.delete(`${BACKEND_URL}api/templates/${id}/delete`, config);
            const updatedItems = items.filter(item => item.id !== id);
            setItems(updatedItems);
            setModalStandard(false); 
            setMessage('Item removed successfully...');
            setLoading(true);
            setAlert('warning');
            setIsDeleted(!isDeleted);
          
            
        } catch (error) {
            console.error('Error deleting item:', error);
        }
    };
    
    const goUpdateFormula = (id) => {
        console.log('id send', id);
        navigate(`/update-template/${id}`); // Include id as a URL parameter
      };
    const columns = [
        {
            name: <span className='font-weight-bold fs-15'>Ref Number #</span>,
            selector: row => row.srNo,
            sortable: true,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Name</span>,
            selector: row => (<span className='fontTable' >{row.title}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-14'>Date Creation</span>,
            selector: row => {
                const date = new Date(row.createDate);
                const day = String(date.getDate()).padStart(2, '0');
                const month = String(date.getMonth() + 1).padStart(2, '0');
                const year = date.getFullYear();
                return <span className='fontTable'>{`${day}-${month}-${year}`}</span>;
            },
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Created by</span>,
            selector: row => (<span className='fontTable'>{row.createdBy}</span>),
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-15'>Status</span>,
            selector: cell => {
                switch (cell.priority) {
                    case "Refused":
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                    case "Active":
                        return <span className="badge bg-success"> Active </span>;
                    case "Created":
                        return <span className="badge bg-success"> {cell.priority} </span>;
                    case "Approved":
                        return <span className="badge bg-primary"> {cell.priority} </span>;
                    default:
                        return <span className="badge bg-danger"> {cell.priority} </span>;
                }
            },
            sortable: false,
            className: 'table-header'
        },
        {
            name: <span className='font-weight-bold fs-13'>Action</span>,
            sortable: true,
            cell: row => (
                <div className="d-flex gap-2">
                       <div className="edit">
                        <button className="btn btn-outline-success waves-effect waves-light" onClick={() => goViewSheet(row.id)} >
                        <i className="mdi mdi-eye"></i>
                        </button>
                    </div>
                    <div className="edit">
                        <button className="btn btn-outline-info waves-effect waves-light" onClick={() => goUpdateFormula(row.id)} disabled={row.isUsed}>
                            <i className="ri-pencil-fill align-bottom"></i>
                        </button>
                    </div>
                    <div className="remove">
                    <button  className="btn btn-outline-danger waves-effect waves-light"
                        onClick={() => { setItemToDelete(row.id); setModalStandard(true); }} disabled={ row.priority === "Approved"||row.isUsed } >
                        <i className="ri-delete-bin-fill align-bottom"></i> 
                    </button>
                    </div>
                    {/* start model delete */}
                
                        {modal_standard && (
                            <Modal isOpen={modal_standard} toggle={toggleModal} className="modal-dialog-centered">
                                <CardBody>
                                <div className="text-center">
                                <div className="mb-4">
                                        <i className="mdi mdi-alert-circle-outline display-1 text-danger fs-30"></i>
                                    </div>
                                    <h4 className="alert-heading">  Are you sure?
                                    </h4>
                                    <p className="mb-0">
                                        you wont be able to revert this !
                                        <br />   <br />
                                    </p>
                                </div>
                                </CardBody>
                                <div  className="modal-footer text-center " style={{ justifyContent: "center" }}>
                                    <Button color="success" onClick={() => handleRemove(itemToDelete)} >Yes, delete it!</Button>
                                    <Button color="danger" onClick={toggleModal}>Cancel</Button>
                                </div>
                            </Modal>
                        )}

                    {/* end model delete */}
                </div>
            ),
            className: 'table-header'
        }
    ];

    const [filteredData, setFilteredData] = useState(initialData);

    useEffect(() => {
        setFilteredData(
            initialData.filter(item =>
                item.srNo.toString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.createdBy.toLowerCase().includes(searchTerm.toLowerCase()) ||
                new Date(item.createDate).toLocaleDateString().toLowerCase().includes(searchTerm.toLowerCase()) ||
                item.priority.toLowerCase().includes(searchTerm.toLowerCase())
            )
        );
    }, [searchTerm, initialData]);

    return (
        <DataTable
            columns={columns}
            data={filteredData}
            pagination
        />
    );
};

export { BasicTable };
