import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";
import Breadcrumbs from "../../../components/Common/Breadcrumb.js";
import { BasicTable } from './datatableCom.js';
import { Link } from 'react-router-dom';
import classnames from "classnames";
import { BACKEND_URL } from "../../../config/const.js";
import axios from 'axios';

const ListCompounding = () => {
    const [customActiveTab, setCustomActiveTab] = useState("1");
    const [searchTerm, setSearchTerm] = useState('');
    const [clientId, setClientId] = useState(null);
    const [sterileData, setSterileData] = useState([]);
    const [nonSterileData, setNonSterileData] = useState([]);
    const [isDeleted, setIsDeleted] = useState(false);
    const [loading, setLoading] =useState(false);
    const config = {
        headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
        },
    };

    // Récupération des informations de l'utilisateur connecté
    useEffect(() => {
          const client = JSON.parse(localStorage.getItem("currentOrg"));
        if (client) {
            setClientId(client);
        }
    }, []);
    useEffect(() => {
        const client = JSON.parse(localStorage.getItem("currentOrg"));
        if (client) {
            setClientId(client);
        }
    }, []);
    // Récupération des templates lorsque clientId est défini
    useEffect(() => {
        if (clientId) {
            fetchSterileData();
        }
    }, [clientId,isDeleted]);



    
    const fetchSterileData = async () => {
        try {
            const status = "Staging";
            const response = await axios.get(`${BACKEND_URL}api/formulasBystatus/${clientId}/${status}`, config);
            console.log('template:', response);
    
            if (response && response.length > 0) { 
                const sterileData = response.map(item => ({
                    id: item.id,
                    srNo: item.protocolNumber,
                    title: item.productName,
                    createdID:item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                    ? item.preparedBy[0].id : null,
                    createdBy: item.preparedBy && Array.isArray(item.preparedBy) && item.preparedBy.length > 0
                    ? item.preparedBy[0].username : "Unknown", 
                    createDate: item.effectiveDate,
                    priority: item.status,
                    isUsed:item.isUsed
                }));
                setSterileData(sterileData);
              
            }
        } catch (error) {
          
            console.error('Error fetching template:', error);
        }
    };
    
    console.log("sterile:",sterileData );
    console.log("sterile:",nonSterileData );
    const toggleCustom = (tab) => {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Breadcrumbs title="Preparation Sheet" breadcrumbItem="Compounding Queue" />

                    <Row>
                        <Col lg={12}>
                            <Card>
                                <CardBody>
                                    <div id="customerList">
                                     

                                        <Col lg={12}>
                                        
                                           
                                                    <Row>
                                                        <Col className="col-sm m-2" >
                                                            <div className="d-flex justify-content-sm-end">
                                                                <div className="search-box">
                                                                    <input
                                                                        type="text"
                                                                        className="form-control search"
                                                                        placeholder="Search..."
                                                                        value={searchTerm}
                                                                        onChange={e => setSearchTerm(e.target.value)}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </Col>
                                                        <Col sm="12">
                                                      
                                                            <BasicTable searchTerm={searchTerm} initialData={sterileData} isDeleted={isDeleted} setIsDeleted={setIsDeleted} />
                                                        
                                                            </Col>
                                                    </Row>
                                             

                                        </Col>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default ListCompounding;
